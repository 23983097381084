import { Skeleton } from "@/components/ui/skeleton";

export const ProgramPageSkeleton = () => {
  return (
    <div className="relative">
      <Skeleton className="flex h-[60vh] flex-col justify-end rounded-none bg-[#E6E6E6] p-12 pt-36 md:h-[calc(100vh-5rem)] md:justify-between">
        <Skeleton className="hidden h-10 w-full bg-[#D4D4D4] md:block" />
        <div className="flex flex-col gap-8">
          <Skeleton className="h-10 w-2/6 min-w-80 bg-[#D4D4D4]" />
          <Skeleton className="h-10 w-2/6 min-w-80 bg-[#D4D4D4]" />
          <Skeleton className="h-10 w-2/6 min-w-80 bg-[#D4D4D4]" />
        </div>
      </Skeleton>

      <div className="flex flex-col gap-16 p-12">
        <div className="flex flex-col gap-8">
          <Skeleton className="h-10 w-2/6 min-w-80 bg-[#D4D4D4]" />
          <Skeleton className="h-10 w-2/6 min-w-80 bg-[#D4D4D4]" />
          <Skeleton className="h-10 w-2/6 min-w-80 bg-[#D4D4D4]" />
        </div>
        <div className="flex gap-x-4 overflow-x-auto">
          <Skeleton className="h-96 min-w-[800px] bg-[#D4D4D4]" />
          <Skeleton className="h-96 min-w-[300px] bg-[#D4D4D4]" />
          <Skeleton className="h-96 min-w-[400px] bg-[#D4D4D4]" />
          <Skeleton className="h-96 min-w-[400px] bg-[#D4D4D4]" />
        </div>
        <div className="flex flex-col gap-8">
          <Skeleton className="h-10 w-2/6 min-w-80 bg-[#D4D4D4]" />
        </div>
      </div>
    </div>
  );
};
