import { AppShell } from "@/components/Navbar";
import { useTranslation } from "react-i18next";
import { LayoutErrorPages } from "../sections/layout";

export const NotFound = () => {
    const { t } = useTranslation();

    return (
        <>
            <AppShell theme="white" navbarClass="top-0 absolute w-full" />
            <LayoutErrorPages
                title={t("notFound.title")}
                message={t("notFound.message")}
                description={t("notFound.description")}
                backHomeText={t("notFound.backHome")}
            />
        </>
    );
};