import { ChangeEvent } from "react";

export const formatCreditCard = (
  e: ChangeEvent<HTMLInputElement>,
  set: (value: any) => void,
) => {
  const value = e.target.value;
  if (value.length < 20 && value.match(/^(\d{1,4} ?){0,4}$/)) {
    set(
      value
        .split("")
        .reduce((acc: string[], curr) => {
          if (acc.length && acc.at(-1)?.length === 4) {
            return [...acc, curr];
          }
          let last = acc.pop() ?? "";
          last += curr;
          return [...acc, last.trim()];
        }, [])
        .join(" ")
        .trim(),
    );
  }
};

export const cleanCreditCard = (e: string) => {
  return e.replaceAll(" ", "");
};

export const formatExpiryDate = (
  e: ChangeEvent<HTMLInputElement>,
  set: (value: string) => void,
) => {
  let value = e.target.value.replace(/\D/g, "");

  const isDeletingSlash =
    e.target.value.length < e.target.defaultValue.length &&
    e.target.defaultValue[e.target.defaultValue.length - 1] === "/";

  if (isDeletingSlash) {
    value = value.substring(0, value.length - 1);
  }

  if (value.length >= 2) {
    value = value.substring(0, 2) + "/" + value.substring(2, 4);
  }

  if (value.length > 5) {
    value = value.substring(0, 5);
  }

  set(value);
};

export const cleanExpiryDate = (e: string) => {
  return e.split("/").reverse().join("");
};

export const luhnCheck = (cardNumber: string) => {
  const cleaned = cleanCreditCard(cardNumber);
  let sum = 0;
  let shouldDouble = false;
  for (let i = cleaned.length - 1; i >= 0; i--) {
    let digit = parseInt(cleaned.charAt(i));

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  return sum % 10 === 0;
};
