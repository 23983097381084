import api from "./api-client";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import { paramsToKey } from "@/utils/helpers";
import { ReasonFormType, ReasonOption } from "@/types/reason";
import { Pagination } from "@/types/pagination";

export const reasonOptionKeys = {
  all: (params?: Record<string, string>) =>
    ["reasonOptions", ...paramsToKey(params)] as const,
};

const getReasonOptions = async (params?: Record<string, string>) => {
  const queryParams = { ...params };
  const res = await api.get<Pagination<ReasonOption>>("/reasonoption/", {
    params: queryParams,
  });
  return res.data.results as ReasonOption[];
};

const postReasons = async (data: ReasonFormType) => {
  const res = await api.post("/reason/", data);
  return res.data;
};

export const useGetReasonOptions = (params?: Record<string, string>) => {
  return useQuery({
    queryKey: reasonOptionKeys.all(params),
    queryFn: () => getReasonOptions(params),
  });
};

export const usepostReasons = (
  options?: UseMutationOptions<any, Error, { data: ReasonFormType }>,
) =>
  useMutation({
    mutationFn: ({ data }) => postReasons(data),
    ...options,
  });
