export const ShareIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2C14.8954 2 14 2.89543 14 4C14 4.3473 14.0885 4.67393 14.2442 4.95855C14.2544 4.97399 14.2642 4.9898 14.2737 5.00597C14.2831 5.02203 14.2919 5.03823 14.3003 5.05457C14.6532 5.62215 15.2825 6 16 6C17.1046 6 18 5.10457 18 4C18 2.89543 17.1046 2 16 2ZM13.1581 6.81485C13.883 7.54668 14.8885 8 16 8C18.2091 8 20 6.20914 20 4C20 1.79086 18.2091 0 16 0C13.7909 0 12 1.79086 12 4C12 4.37702 12.0522 4.74185 12.1497 5.08768L6.84194 8.18515C6.11704 7.45332 5.11146 7 4 7C1.79086 7 0 8.79086 0 11C0 13.2091 1.79086 15 4 15C5.11167 15 6.11741 14.5465 6.84234 13.8144L12.1509 16.9079C12.0526 17.255 12 17.6214 12 18C12 20.2091 13.7909 22 16 22C18.2091 22 20 20.2091 20 18C20 15.7909 18.2091 14 16 14C14.8902 14 13.886 14.452 13.1613 15.1819L7.8505 12.0871C7.9479 11.7415 8 11.3768 8 11C8 10.623 7.94784 10.2581 7.85034 9.91232L13.1581 6.81485ZM5.6997 9.94543C5.70808 9.96177 5.71695 9.97798 5.72631 9.99403C5.73575 10.0102 5.74557 10.026 5.75576 10.0414C5.91147 10.3261 6 10.6527 6 11C6 11.3473 5.91148 11.6739 5.75576 11.9585C5.74546 11.9742 5.73553 11.9902 5.72599 12.0065C5.71674 12.0224 5.70798 12.0384 5.6997 12.0546C5.3468 12.6221 4.71753 13 4 13C2.89543 13 2 12.1046 2 11C2 9.89543 2.89543 9 4 9C4.71753 9 5.3468 9.37785 5.6997 9.94543ZM14.2153 17.0964C14.24 17.064 14.2629 17.0296 14.284 16.9935C14.3042 16.9588 14.3221 16.9235 14.3377 16.8875C14.6966 16.3523 15.3072 16 16 16C17.1046 16 18 16.8954 18 18C18 19.1046 17.1046 20 16 20C14.8954 20 14 19.1046 14 18C14 17.6749 14.0776 17.3678 14.2153 17.0964Z"
      />
    </svg>
  );
};
