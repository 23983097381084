import { useEffect, useState } from "react";

const useActiveSection = (sections: string[]) => {
  const [activeSection, setActiveSection] = useState<string>("");

  const handleScroll = () => {
    let currentSection = "";
    for (const section of sections) {
      const element = document.getElementById(section);
      if (
        element &&
        element.getBoundingClientRect().top < window.innerHeight / 2
      ) {
        currentSection = section;
      }
    }
    setActiveSection(currentSection);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const exactPosition = element.offsetTop;

      const offsetPosition = window.innerWidth < 768
        ? exactPosition - 68
        : exactPosition - 78;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });

    }
  };

  return { activeSection, scrollToSection };
};

export default useActiveSection;
