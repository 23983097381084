import React from "react";
import { useTranslation } from "react-i18next";

interface CardProps {
  title: string;
  description: string;
}

const Card: React.FC<CardProps> = ({ title, description }) => {
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "ar";

  return (
    <div
      className={`border-b border-stone-300 p-6 ${isRTL ? "border-r border-stone-300" : "border-l border-stone-300"}`}
    >
      <h3 className="mb-4 text-xl font-bold md:mb-6 md:text-[32px]">{title}</h3>
      <p className="text-base md:text-lg">{description}</p>
    </div>
  );
};

export default Card;
