import { ProfileIcon } from "@/assets/icons/Profile";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import AmountInput from "@/components/Inputs/AmountInput";
import { DatePickerWithRange } from "@/components/Inputs/RangeDatePicler";
import { useCloseChallenge, useUpdateChallenge } from "@/api/challenge";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Form, Formik, FormikErrors } from "formik";
import { ChallengeType } from "@/types/challenge";
import useChallengeFormStore from "@/store/challengeStore";
import currencyStore from "@/store/currencyStore";
import CloseChallengeModal from "./components/CloseChallengeModal";
import ChallengeClosedModal from "./components/ChallengeClosedModal";
import { t } from "i18next";
import { Trans } from "react-i18next";

type InnerFormProps = {
  readonly values: any;
  readonly setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<any>>;
  resetForm: () => void;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  challenge: ChallengeType;
};
const InnerForm = ({
  values,
  setFieldValue,
  resetForm,
  isEditing,
  setIsEditing,
  challenge,
}: InnerFormProps) => {
  const { currency } = currencyStore();
  const { setDate } = useChallengeFormStore();
  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (value === "") {
        setFieldValue(currency === "EGP" ? "goal_egp" : "goal_usd", 0);
      } else {
        const numberValue = parseInt(value);
        if (!isNaN(numberValue)) {
          setFieldValue(
            currency === "EGP" ? "goal_egp" : "goal_usd",
            numberValue,
          );
        } else {
          setFieldValue(currency === "EGP" ? "goal_egp" : "goal_usd", 0);
        }
      }
      setIsEditing(true);
    },
    [values.goal_usd, values.goal_egp, currency],
  );

  return (
    <Form className="bg-background mb-8 flex h-full w-full flex-col gap-4 md:mt-0 md:gap-8">
      <h1 className="w-full py-2 text-xl font-[650] leading-[120%] sm:text-[32px] md:py-0">
        {t("challenge.challengeDetails")}
      </h1>

      <div className="flex flex-col gap-2">
        <label htmlFor="challengeName" className="text-sm font-[650]">
          {t("challenge.challengeName")}
        </label>
        <Input
          type="text"
          name="name"
          value={values.name}
          onChange={(e) => {
            setFieldValue("name", e.target.value);
            setIsEditing(true);
          }}
          placeholder={t("challenge.namePlaceholder")}
          className="!placeholder:text-[#4F4754] px-4 font-[650] placeholder:font-[350]"
        />
      </div>
      <div className="flex flex-col gap-2">
        <label htmlFor="challengeDescription" className="text-sm font-[650]">
          {t("challenge.challengeDescription")}
        </label>
        <div className="bg-background flex w-full flex-col gap-4 rounded-lg border border-[#CCC2BF] px-4 py-2 text-base text-[#232329] md:gap-8">
          <textarea
            id="challengeDescription"
            value={values.description}
            onChange={(e) => {
              setFieldValue("description", e.target.value);
              setIsEditing(true);
            }}
            placeholder={t("challenge.describePlaceholder")}
            className="scrollbar-hide ring-offset-background placeholder:text-muted-foreground flex min-h-[60px] w-full resize-none bg-transparent font-[650] placeholder:font-[350] placeholder:text-[#4F4754] focus:text-[#232329] focus-visible:outline-none"
            rows={values.description.length / 50}
          />
          <div className="text-right text-sm font-[350] text-[#AC9CA0]">
            <Trans
              i18nKey={t("challenge.characters")}
              values={{ top: values.description.length, bottom: 600 }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <h4 className="text-sm font-[650]">{t("challenge.currentGoal")}</h4>
        <AmountInput
          value={currency === "EGP" ? values.goal_egp : values.goal_usd}
          onChange={handleInputChange}
          placeholder={"Enter a custom amount"}
        />
      </div>
      <div className="flex flex-col gap-2">
        <h4 className="text-sm font-[650]">{t("challenge.goalTimeRange")}</h4>
        <DatePickerWithRange
          className="w-full"
          start={
            challenge.state === "Ongoing"
              ? (challenge.start_date as string)
              : undefined
          }
        />
      </div>
      {isEditing && (
        <div className="bg-background fixed bottom-0 left-0 z-50 flex min-w-full flex-col justify-center gap-4 bg-[#E4E1D8] p-8 md:flex-row">
          <Button
            size="lg"
            variant="ghost"
            type="reset"
            className="h-14 min-w-[180px] rounded-lg px-6 py-2.5 font-[650] hover:bg-[#22222A] hover:text-white"
            onClick={() => {
              resetForm();
              setDate({
                from: challenge.start_date
                  ? (challenge.start_date as Date)
                  : undefined,
                to: challenge.end_date
                  ? (challenge.end_date as Date)
                  : undefined,
              });
              setIsEditing(false);
            }}
          >
            {t("challenge.dismiss")}
          </Button>
          <Button
            type="submit"
            className="h-14 min-w-[180px] rounded-lg px-6 py-2.5 font-[650] hover:bg-[#22222A] hover:text-white"
          >
            {t("challenge.saveChanges")}
          </Button>
        </div>
      )}
    </Form>
  );
};

export const DetailsComponent = ({
  challenge,
}: {
  challenge: ChallengeType;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [closedModal, setClosedModal] = useState(false);
  const [openCloseModal, setOpenCloseModal] = useState(false);
  const { preview, date, setDate } = useChallengeFormStore();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const updateChallenge = useUpdateChallenge({
    onSuccess: () => {
      setIsEditing(false);
      setUpdateSuccess(true);
      setTimeout(() => {
        setUpdateSuccess(false);
      }, 1500);
    },
  });

  useEffect(() => {
    setDate({
      from: challenge.start_date ? (challenge.start_date as Date) : undefined,
      to: challenge.end_date ? (challenge.end_date as Date) : undefined,
    });
  }, []);

  useEffect(() => {
    if (date) {
      if (
        date.from !== challenge.start_date ||
        date.to !== challenge.end_date
      ) {
        setIsEditing(true);
      } else {
        setIsEditing(false);
      }
    }
  }, [date]);

  const { mutate } = useCloseChallenge({
    onSuccess: () => {
      setOpenCloseModal(false);
      setClosedModal(true);
    },
  });

  return (
    <>
      <section id="Details">
        {challenge.is_owner && !preview && challenge.state !== "Closed" ? (
          <div className="scrollbar-hide mx-auto w-full  max-w-2xl overflow-y-auto">
            <div className="flex items-center gap-4 pb-4 md:pb-6">
              <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[#B4B4B4]">
                <ProfileIcon height={20} width={19} />
              </div>
              <p className="text-sm font-[650]">
                <Trans
                  i18nKey={"challenge.createdBy"}
                  values={{ person: t("challenge.me") }}
                />
              </p>
            </div>
            <Formik
              initialValues={{
                name: challenge.name,
                description: challenge.description,
                start_date: challenge.start_date,
                end_date: challenge.end_date,
                goal_usd: challenge.goal_usd ?? 0,
                goal_egp: challenge.goal_egp ?? 0,
              }}
              onSubmit={(values) => {
                if (date?.from && date?.to) {
                  const start = new Date(date.from);
                  const end = new Date(date.to);
                  values.start_date = new Date(
                    start.getTime() - start.getTimezoneOffset() * 60000,
                  )
                    .toISOString()
                    .split("T")[0];
                  values.end_date = new Date(
                    end.getTime() - end.getTimezoneOffset() * 60000,
                  )
                    .toISOString()
                    .split("T")[0];

                  updateChallenge.mutate({
                    data: values,
                    challengeId: challenge.id,
                  });
                }
              }}
            >
              {({ values, setFieldValue, resetForm }) => (
                <InnerForm
                  values={values}
                  setFieldValue={setFieldValue}
                  resetForm={resetForm}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  challenge={challenge}
                />
              )}
            </Formik>
            <CloseChallengeModal
              challenge={challenge}
              mutate={mutate}
              open={openCloseModal}
              setOpen={setOpenCloseModal}
            />
            <ChallengeClosedModal
              challenge={challenge}
              open={closedModal}
              setOpen={setClosedModal}
            />
          </div>
        ) : (
          <div className="scrollbar-hide mx-auto flex w-full max-w-2xl flex-col gap-6 overflow-y-auto">
            <div className="flex items-center gap-4">
              <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[#B4B4B4]">
                <ProfileIcon height={20} width={19} />
              </div>
              <p className="text-sm font-[650]">
                <Trans
                  i18nKey={"challenge.createdBy"}
                  values={{
                    person: `${challenge.owner.profile.firstName}
                      ${challenge.owner.profile.lastName}`,
                  }}
                />
              </p>
            </div>
            <h1 className="text-xl font-[650] md:text-[32px]">
              <Trans
                i18nKey={"challenge.endingOn"}
                values={{ date: challenge.end_date?.toString() }}
              />
            </h1>
            <p>{challenge.description}</p>
          </div>
        )}
      </section>
      {updateSuccess && (
        <>
          <div className="fixed inset-0 z-20 flex h-full w-full items-center justify-center">
            <h3 className="w-[270px] text-center text-[32px] font-bold">
              {t("challenge.saved")}
            </h3>
          </div>
          <div className="bg-background fixed inset-0 z-10 flex h-full w-full items-center justify-center opacity-40"></div>
        </>
      )}
    </>
  );
};
