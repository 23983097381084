export default function FoldedHands() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
    >
      <path
        d="M14.166 15.25V12.525L13.041 10.5C12.9243 10.5833 12.8327 10.6917 12.766 10.825C12.6993 10.9583 12.666 11.1 12.666 11.25V16.975L15.241 21.25H12.916L10.666 17.55V11.25C10.666 10.7333 10.791 10.2583 11.041 9.825C11.291 9.39167 11.6327 9.03333 12.066 8.75L10.666 6.275C10.3327 5.64167 10.1868 4.97083 10.2285 4.2625C10.2702 3.55417 10.541 2.95 11.041 2.45L12.741 0.75L19.641 8.85L20.666 21.25H18.666L17.691 9.65L12.616 3.7L12.466 3.85C12.2993 4.01667 12.2035 4.20833 12.1785 4.425C12.1535 4.64167 12.191 4.85 12.291 5.05L16.166 12V15.25H14.166ZM5.16602 15.25V12L9.04102 5.05C9.14102 4.85 9.17852 4.64167 9.15352 4.425C9.12852 4.20833 9.03268 4.01667 8.86602 3.85L8.71602 3.7L3.64102 9.65L2.66602 21.25H0.666016L1.69102 8.85L8.59102 0.75L10.291 2.45C10.791 2.95 11.0618 3.55417 11.1035 4.2625C11.1452 4.97083 10.9993 5.64167 10.666 6.275L9.26602 8.75C9.69935 9.03333 10.041 9.39167 10.291 9.825C10.541 10.2583 10.666 10.7333 10.666 11.25V17.55L8.41602 21.25H6.09102L8.66602 16.975V11.25C8.66602 11.1 8.63268 10.9583 8.56602 10.825C8.49935 10.6917 8.40768 10.5833 8.29102 10.5L7.16602 12.525V15.25H5.16602Z"
        fill="#22222A"
      />
    </svg>
  );
}
