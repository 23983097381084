import api from "./api-client";
import { useQuery } from "@tanstack/react-query";

type BinInfo = {
  id: number;
  country: string;
  brand: string;
};

export const creditCardBinKeys = {
  bin: (bin: string) => ["card-bin", bin],
};

const getCreditCardInfo = async (bin: string) => {
  const res = await api.get<BinInfo>("/payment/bin/" + bin);
  return res.data;
};

export const useGetBinInfo = (bin: string | null) => {
  return useQuery({
    queryKey: creditCardBinKeys.bin(bin!),
    queryFn: () => getCreditCardInfo(bin!),
    enabled: !!bin,
  });
};
