import { Button } from "@/components/ui/button";
import { VolunteerIcon } from "@/assets/icons/VolunteerIcon";
import { Progress } from "@/components/ui/progress";
import { cn } from "@/lib/utils";
import { ProgramType } from "@/types/programs";
import {
  formatCurrency,
  getProgramGoal,
  getProgramRaisedAmount,
  getProgramRaisedPercentage,
} from "@/utils/currency";
import { bgOverlayStyle } from "@/utils/helpers";
import { useLocation, useRouter } from "@tanstack/react-router";
import { t } from "i18next";
import { useFormStore } from "@/contexts/paymentFlowContext";

type DonateCardProps = {
  program: ProgramType;
  light?: boolean;
};

export const ProgramProfileCard = ({ program, light }: DonateCardProps) => {
  const { reset } = useFormStore();

  const { navigate } = useRouter();
  const { search } = useLocation();

  const handleDonateNow = () => {
    reset();
    if (program) {
      navigate({
        to: `/paymentFlow/${program.id}`,
        search: { backUrl: `${location.pathname}?page=${search.page}` },
      });
    }
  };
  return (
    <div className="updates">
      <div
        id="program-card"
        style={{
          backgroundImage: light
            ? undefined
            : bgOverlayStyle(program.main_image.minio_url),
          backgroundColor: light ? "#D4F376" : undefined,
        }}
        className="aspect-9/16 flex w-[250px] flex-col justify-end rounded-lg bg-cover bg-center p-6 md:w-[350px] md:p-8"
      >
        <div
          className={cn("flex flex-col gap-8", {
            "justify-between h-full": light,
          })}
        >
          {light && <VolunteerIcon />}
          <div
            className={cn("flex flex-col gap-2 pt-6 [&_span]:text-light", {
              "[&_span]:text-foreground": light,
            })}
          >
            <h3
              className={cn(
                "scroll-m-20 text-base font-bold tracking-tight text-light",
                {
                  "text-foreground": light,
                },
              )}
            >
              {program.title}
            </h3>
            <Progress
              value={getProgramRaisedPercentage(program)}
              className={cn(
                "h-[4px] rounded-[2px] rtl:scale-x-[-1] rtl:transform",
                { "bg-black": light },
              )}
              aria-label="Donation amount progress bar"
            />
            <div className="flex justify-between">
              <span className="text-base font-bold">
                {formatCurrency(getProgramRaisedAmount(program))}
              </span>
              <span className="text-base font-bold">
                {formatCurrency(getProgramGoal(program))}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-bold text-sm font-normal">
                {t("ProgramCard.raised_label")} -{" "}
                {getProgramRaisedPercentage(program).toFixed(1)}%
              </span>
              <span className="text-bold text-sm font-normal">
                {t("ProgramCard.goal_label")}
              </span>
            </div>
          </div>
          <Button
            className={cn(
              "h-[54px] w-full px-5 py-4 font-bold md:min-w-[170px]",
              { "bg-foreground text-light": light },
            )}
            onClick={handleDonateNow}
          >
            {t("ProgramCard.donate_now")}
          </Button>
        </div>
      </div>
    </div>
  );
};
