import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Calendar } from "lucide-react";
import * as React from "react";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import DayPicker from "./DayPicker";
import { FormikErrors } from "formik";
import { useState, useEffect } from "react";
import { DeliverySlotType, DeliveryType } from "@/types/delivery";

interface DatePickerProps {
  readonly selected: Date | null;
  readonly name: string;
  readonly onChange: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<any>>;
  readonly placeholderText: string;
  readonly className?: string;
  readonly error?: string;
  readonly delivery?: DeliveryType;
  readonly deliverySlots?: DeliverySlotType[];
}

const DatePicker: React.FC<DatePickerProps> = ({
  selected,
  name,
  onChange,
  placeholderText,
  className,
  error,
  delivery,
  deliverySlots,
}: DatePickerProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [popoverWidth, setPopoverWidth] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    if (buttonRef.current) {
      setPopoverWidth(buttonRef.current.offsetWidth);
    }
  }, [buttonRef.current]);

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger asChild>
        <Button
          ref={buttonRef}
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal",
            "md:text-base text-sm text-[#4F4754]",
            "border",
            "border-[#CCC2BF] h-[58px]",
            "py-[9px]",
            "px-[13px]",
            "hover:bg-transparent",
            className,
            error ? "border-[#F04438]" : "",
          )}
          aria-label="Choose final donation month"
        >
          <div
            className={cn(!selected && "text-[#4F4754]", "flex items-center")}
          >
            <div
              className={cn(
                "flex items-center justify-center bg-[#E2DDD7] py-2 px-6 rounded-md -ml-1 mr-2 rtl:ml-2 rtl:-mr-1",
                "stroke-foreground",
              )}
            >
              <Calendar className="h-5 w-5 stroke-[#232329]" />
            </div>
            {selected ? (
              <span className="font-bold">
                {format(selected, "dd MMMM yyyy")}
              </span>
            ) : (
              <span className="text-[#4F4754]">{placeholderText}</span>
            )}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="bg-background mt-2 w-auto p-0"
        style={{ width: popoverWidth }}
      >
        <DayPicker
          name={name}
          setIsPopoverOpen={setIsPopoverOpen}
          currentDay={selected || new Date()}
          onDayChange={onChange}
          delivery={delivery}
          deliverySlots={deliverySlots}
        />
      </PopoverContent>
      {error ? (
        <div className="mt-2 text-start text-sm text-[#F04438]">{error}</div>
      ) : null}
    </Popover>
  );
};

export default DatePicker;
