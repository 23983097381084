import { DeliveryType } from "./delivery";
import { DocumentType } from "./document";

export type Testimonial = {
  name: string;
  description: string;
  location: string;
  testimonial: string;
  testifier_type: string;
  picture: DocumentType[];
};
export type CategoryType = {
  title: string;
  description: string;
  image: DocumentType;
};

export type ProgramDonationType = {
  id: string;
  title: string;
  type: string;
  enabled: boolean;
};

export enum SpecialProgramType {
  SACRIFICE_SHARE = "Sacrifice Share",
  ZAKAT_FITR = "Zakat Al-Fitr",
  VOWS_REDEMPTION_BIRTH = "Vows, Redemption, & Birth Sacrifice",
  NOT_APPLIABLE = "Not Applicable",
}

export type ProgramType = {
  id: string;
  main_image: DocumentType;
  images: DocumentType[];
  created_at: string;
  updated_at: string;
  deleted_at: null | string;
  title: string;
  description_title: string;
  description_details: string;
  rank: number;
  goal_amount_usd: number;
  goal_amount_egp: number;
  raised_amount_usd: number;
  raised_amount_egp: number;
  min_amount_usd: number;
  min_amount_egp: number;
  color_primary: string;
  color_secondary: string;
  color_third: string;
  created_by: string;
  updated_by: string;
  progress_percentage_usd: number;
  progress_percentage_egp: number;
  meal_price_usd: number;
  meal_price_egp: number;
  message: string;
  is_zakat_eligible: boolean;
  updates: UpdateType[];
  testimonials: Testimonial[];
  currency_exchange_rate: number;
  similar_programs: any;
  category: CategoryType[];
  donation_types: ProgramDonationType[];
  is_special: boolean;
  published: boolean;
  user_donated?: number;
  share_text?: string;
  faqs?: any;
  delivery?: DeliveryType;
  special_type?: SpecialProgramType;
  families_helped?: number;
  meals_distributed?: number;
  number_donors?: number;
};

export enum StatisticContentType {
  BOX = "Box Provision",
  MEAL = "Meal Provision",
}

export type StatisticType = {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: null | string;
  object_id: string;
  created_by: string;
  updated_by: string;
  label: StatisticContentType;
  value: number;
};

export enum UpdateViewType {
  PORTRAIT = "Portrait",
  STACKED = "Stacked",
}

export enum UpdateContentType {
  STATISTIC = "Statistics News",
  MEDIA = "Media News",
  TEXT = "Text News",
}

export type UpdateType = {
  is_aboutus: boolean;
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: null | string;
  created_by: string;
  updated_by: string;
  date: string;
  content: string;
  statistics: StatisticType[] | null;
  media: DocumentType[] | null;
  update_type: UpdateContentType;
  view: UpdateViewType;
};

export enum LivestockBreed {
  IMPORTED = "Imported",
  BALADY = "Balady",
  NOT_APPLICABLE = "Not Applicable",
}

export type LivestockType = {
  id: string;
  name: string;
  type: LivestockBreed;
  amount_usd: number;
  amount_egp: number;
  is_share_applicable: boolean;
  share_amount: number;
  month_estimation_period: number;
  delivery?: DeliveryType;
};

export type SuggestedAmountType = {
  id: string;
  type: string;
  amount_usd: number;
  amount_egp: number;
};

export interface UpdateResponse {
  links: {
    next: string | null;
    previous: string | null;
  };
  count: number;
  total_pages: number;
  results: UpdateType[];
}
