export const CATEGORY_BASE_COLOR = [75, 85, 42];

const DONATION_TYPE_PALETTE = [
  "#D6C2C2",
  "#D6D5C2",
  "#C2CFD6",
  "#C5D6C2",
  "#D6C2D6",
  "#D6C9C2",
];
const CASHED_PALLETTE = new Map<string, string>();
let counter = 0;

export const generateCategoryColor = (title?: string) => {
  if (title && CASHED_PALLETTE.has(title)) {
    return CASHED_PALLETTE.get(title);
  }

  const color = DONATION_TYPE_PALETTE.at(
    counter % DONATION_TYPE_PALETTE.length,
  );
  counter += 1;
  if (title && color) {
    CASHED_PALLETTE.set(title, color);
    return color;
  } else {
    return undefined;
  }
};

type CategoryBadgeProps = {
  categoryTitle: string;
};

export const CategoryBadge = ({ categoryTitle }: CategoryBadgeProps) => {
  return (
    <div className="flex items-center gap-2">
      <div
        className="h-[18px] w-[18px] rounded-sm"
        style={{ backgroundColor: generateCategoryColor(categoryTitle) }}
      ></div>
      <p>{categoryTitle}</p>
    </div>
  );
};
