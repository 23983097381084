// scrollHelpers.ts

import { useRef, useEffect, useState } from "react";

export const useInfiniteScroll = (
  scrollSpeed: number = 0.5,
  direction: "left" | "right" = "left",
) => {
  const scrollContainerRef = useRef<HTMLElement | undefined>(undefined);
  const [isPaused, setIsPaused] = useState(false);
  const [touchStartX, setTouchStartX] = useState(0);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) {
      return;
    }

    let animationId: number;

    const animate = () => {
      if (scrollContainer && !isPaused) {
        if (direction === "left") {
          scrollContainer.scrollLeft += scrollSpeed;
          if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
            scrollContainer.scrollLeft = 0;
          }
        } else {
          scrollContainer.scrollLeft -= scrollSpeed;
          if (scrollContainer.scrollLeft <= 0) {
            scrollContainer.scrollLeft = scrollContainer.scrollWidth / 2;
          }
        }
      }
      animationId = requestAnimationFrame(animate);
    };

    animationId = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationId);
  }, [isPaused, scrollSpeed, direction]);

  const handleMouseEnter = () => setIsPaused(true);
  const handleMouseLeave = () => setIsPaused(false);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
    setIsPaused(true);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (scrollContainerRef.current) {
      const touchDiff = touchStartX - e.touches[0].clientX;
      scrollContainerRef.current.scrollLeft += touchDiff;
      setTouchStartX(e.touches[0].clientX);
    }
  };

  const handleTouchEnd = () => {
    setIsPaused(false);
  };

  return {
    scrollContainerRef,
    handleMouseEnter,
    handleMouseLeave,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};

export const getLanguageSpecificClasses = (language: string) => {
  return language === "en"
    ? "md:first:pl-[24px] first:pl-4 last:pr-4 md:last:pr-[24px]"
    : "first:pr-4 md:first:pr-[24px] last:pl-4 md:last:pl-[24px]";
};
