import { createFileRoute } from "@tanstack/react-router";
import { PaymentFlow } from "@/pages/paymentFlow/";
import { z } from "zod";

const paymentFlowSearchSchema = z.object({ backUrl: z.string().optional() });

export const Route = createFileRoute("/paymentFlow/$programId")({
  component: PaymentFlow,
  validateSearch: (search) => paymentFlowSearchSchema.parse(search),
});
