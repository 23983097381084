import { useTranslation } from "react-i18next";

export const HeaderPolicy = ({ title }: { title?: string }) => {
  const { t } = useTranslation();
  return (
    <section className="inline-flex h-full w-[1440px] flex-col items-start justify-end gap-[84px] pb-4 pl-4 pr-4 md:px-4 md:py-0">
      <h1 className="text-[40px] font-bold md:text-[96px] ">
        {t(`policies.${title}.title`)}
      </h1>
    </section>
  );
};
