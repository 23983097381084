import { lazy, useState } from "react";
import { useGetDonationTypesOptions } from "@/api/donation_type";
import { Skeleton } from "@/components/ui/skeleton";
import dayjs from "dayjs";
import { CategoryBadge } from "@/pages/donor-profile/components/category-badge";
const DonationChart = lazy(
  () => import("@/pages/donor-profile/components/donation-chart"),
);
import { useGetDonationStatistics, useGetDonations } from "@/api/donations";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Link } from "@tanstack/react-router";
import { ArrowLeft, ArrowRight } from "lucide-react";

export const DonationStatistics = () => {
  const { t, i18n } = useTranslation();
  const donationTypes = useGetDonationTypesOptions({ lang: i18n.language });
  const { data: donations } = useGetDonations();
  const [year, setYear] = useState<number>(dayjs().year());
  const { data: statistics, isLoading: statisticLoading } =
    useGetDonationStatistics({
      year: year.toString(),
    });

  if (donations?.results.length === 0) {
    return (
      <div className="flex min-h-[80vh] flex-col items-start gap-8 pt-6 rtl:items-end">
        <h3 className="text-xl font-bold md:text-3xl">
          {t("myUpdates.makeFirstDonation")}{" "}
        </h3>
        <p className="text-xl md:text-2xl">
          {t("myUpdates.emptyStateMessage")}
        </p>
        <Link to="/programs">
          <Button className="px-10 py-6">
            {t("myDonations.seeAllCauses")}
          </Button>
        </Link>
      </div>
    );
  }
  return (
    <section className="flex flex-col gap-8">
      <h2 className="mt-4 text-xl font-bold md:mt-6 md:text-3xl rtl:text-end">
        {t("myDonations.donationBreakdown")}
      </h2>
      <div className="flex flex-col-reverse items-center justify-between gap-y-8 md:flex-row md:rtl:flex-row-reverse">
        <h1 className="text-xl font-semibold text-[#22222a] opacity-50 md:text-3xl">
          {t("myDonations.yourYear", { year })}
        </h1>
        <div className="flex flex-row-reverse flex-wrap justify-end gap-x-8 gap-y-1">
          {donationTypes.map((type) => (
            <CategoryBadge key={type.value} categoryTitle={type.label} />
          ))}
        </div>
      </div>
      {statistics && !statisticLoading && (
        <div className="relative mb-4 md:mb-6">
          <Button
            className="center absolute flex h-12 w-12 items-center justify-center rounded-sm bg-white p-0"
            onClick={() => setYear(year - 1)}
          >
            <ArrowLeft />
          </Button>
          {year < new Date().getFullYear() && (
            <Button
              className="center absolute right-0 flex h-12 w-12 items-center justify-center rounded-sm bg-white p-0"
              onClick={() => setYear(year + 1)}
            >
              <ArrowRight />
            </Button>
          )}
          <DonationChart data={statistics} />
        </div>
      )}
      {statisticLoading && <Skeleton className="h-44 w-full" />}
    </section>
  );
};
