export const VolunteerIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 12.75L12.35 8.7C11.8333 8.2 11.3958 7.64583 11.0375 7.0375C10.6792 6.42917 10.5 5.76667 10.5 5.05C10.5 4.13333 10.8208 3.35417 11.4625 2.7125C12.1042 2.07083 12.8833 1.75 13.8 1.75C14.3333 1.75 14.8333 1.8625 15.3 2.0875C15.7667 2.3125 16.1667 2.61667 16.5 3C16.8333 2.61667 17.2333 2.3125 17.7 2.0875C18.1667 1.8625 18.6667 1.75 19.2 1.75C20.1167 1.75 20.8958 2.07083 21.5375 2.7125C22.1792 3.35417 22.5 4.13333 22.5 5.05C22.5 5.76667 22.325 6.42917 21.975 7.0375C21.625 7.64583 21.1917 8.2 20.675 8.7L16.5 12.75ZM16.5 9.95L19.225 7.275C19.5417 6.95833 19.8333 6.62083 20.1 6.2625C20.3667 5.90417 20.5 5.5 20.5 5.05C20.5 4.68333 20.375 4.375 20.125 4.125C19.875 3.875 19.5667 3.75 19.2 3.75C18.9667 3.75 18.7458 3.79583 18.5375 3.8875C18.3292 3.97917 18.15 4.11667 18 4.3L16.5 6.1L15 4.3C14.85 4.11667 14.6708 3.97917 14.4625 3.8875C14.2542 3.79583 14.0333 3.75 13.8 3.75C13.4333 3.75 13.125 3.875 12.875 4.125C12.625 4.375 12.5 4.68333 12.5 5.05C12.5 5.5 12.6333 5.90417 12.9 6.2625C13.1667 6.62083 13.4583 6.95833 13.775 7.275L16.5 9.95ZM7.5 18.25L14.45 20.15L20.4 18.3C20.3167 18.15 20.1958 18.0208 20.0375 17.9125C19.8792 17.8042 19.7 17.75 19.5 17.75H14.45C14 17.75 13.6417 17.7333 13.375 17.7C13.1083 17.6667 12.8333 17.6 12.55 17.5L10.225 16.725L10.775 14.775L12.8 15.45C13.0833 15.5333 13.4167 15.6 13.8 15.65C14.1833 15.7 14.75 15.7333 15.5 15.75C15.5 15.5667 15.4458 15.3917 15.3375 15.225C15.2292 15.0583 15.1 14.95 14.95 14.9L9.1 12.75H7.5V18.25ZM1.5 21.75V10.75H9.1C9.21667 10.75 9.33333 10.7625 9.45 10.7875C9.56667 10.8125 9.675 10.8417 9.775 10.875L15.65 13.05C16.2 13.25 16.6458 13.6 16.9875 14.1C17.3292 14.6 17.5 15.15 17.5 15.75H19.5C20.3333 15.75 21.0417 16.025 21.625 16.575C22.2083 17.125 22.5 17.85 22.5 18.75V19.75L14.5 22.25L7.5 20.3V21.75H1.5ZM3.5 19.75H5.5V12.75H3.5V19.75Z"
        fill="#22222A"
      />
    </svg>
  );
};
