import { HomePage } from "@/pages/home";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const homePageSchema = z.object({
  response_code: z
    .string()
    .or(z.number())
    .transform((v) => Number(v))
    .optional(),
  agreement_id: z.string().optional(),
});

export const Route = createFileRoute("/")({
  component: HomePage,
  validateSearch: (search) => homePageSchema.parse(search),
});
