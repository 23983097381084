import { useGetPrograms } from "@/api/program";
import ProgramCard from "@/components/Program/ProgramCard";
import { ProgramCardSkeleton } from "@/components/Program/ProgramCardSkeleton";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";
import CustomLink from "./CustomLink";

export default function ProgramListing() {
  const { t, i18n } = useTranslation();
  const { data: programs, isLoading } = useGetPrograms({
    ordering: "rank",
    page_size: "3",
    lang: i18n.language,
  });

  return (
    <section className={cn("flex flex-col gap-6 mb-6 w-full")} id="causes">
      {isLoading || programs?.length ? (
        <div className="flex items-center justify-between ">
          <h2 className="text-2xl font-bold md:text-5xl ">
            {t("ProgramListing.title")}
          </h2>
          <div className="flex flex-row justify-end md:h-10">
            <CustomLink
              to="/programs"
              mobileText={t("highlightedLink.all_causes")}
              desktopText={t("highlightedLink.all_causes")}
            />
          </div>
        </div>
      ) : null}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-[24px] lg:grid-cols-4 ">
        {isLoading &&
          Array.from({ length: 3 }).map((_, i) => {
            const key = `skeleton-${i}`;
            return (
              <div
                key={key}
                className="sm:first:col-span-2 first:md:row-span-2"
              >
                <ProgramCardSkeleton />
              </div>
            );
          })}
        {!isLoading &&
          programs?.map((program) => (
            <ProgramCard
              className="sm:first:col-span-2 first:md:row-span-2"
              key={program.id}
              program={program}
            />
          ))}
      </div>
    </section>
  );
}
