import { isToday, isYesterday } from "date-fns";
import { t } from "i18next";
import { DateRange } from "react-day-picker";
import { Trans } from "react-i18next";

export function getDateRangeStatus(startDate: string, endDate: string) {
  const currentDate = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Function to calculate the difference in days between two dates
  const getDayDifference = (date1: Date, date2: Date): number => {
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  if (currentDate < start) {
    const daysUntilStart = getDayDifference(currentDate, start);
    return (
      <Trans i18nKey={"challenge.startIn"} values={{ date: daysUntilStart }} />
    );
  } else if (currentDate > end) {
    const daysSinceEnd = getDayDifference(currentDate, end);
    return (
      <Trans i18nKey={"challenge.closedAgo"} values={{ date: daysSinceEnd }} />
    );
  } else {
    const daysLeft = getDayDifference(currentDate, end);
    return <Trans i18nKey={"challenge.left"} values={{ date: daysLeft }} />;
  }
}

export function calculateDeadline(startDate: string, endDate: string) {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  const msPerDay = 24 * 60 * 60 * 1000;

  if (start > now) {
    const daysUntilStart = Math.ceil(
      (start.getTime() - now.getTime()) / msPerDay,
    );
    return (
      <Trans
        i18nKey={"challenge.untilStart"}
        values={{ day: daysUntilStart }}
      />
    );
  } else if (end < now) {
    const daysSinceEnd = Math.ceil((now.getTime() - end.getTime()) / msPerDay);
    return (
      <Trans i18nKey={"challenge.closedAgo"} values={{ date: daysSinceEnd }} />
    );
  } else {
    const daysLeft = Math.ceil((end.getTime() - now.getTime()) / msPerDay);
    return <Trans i18nKey={"challenge.left"} values={{ date: daysLeft }} />;
  }
}

export function convertDjangoDateToReadable(djangoDateString: string): string {
  const date = new Date(djangoDateString);
  if (isToday(date)) {
    return t("challenge.today");
  } else if (isYesterday(date)) {
    return t("challenge.yesterday");
  }
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleString("en-US", options);
}

export function formatDateRange(dateRange: DateRange | undefined): string {
  if (!dateRange?.from) {
    return t("challenge.noDateRange");
  }

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const formatDate = (date: Date | string | number) => {
    const dateObject = date instanceof Date ? date : new Date(date);
    return dateObject.toLocaleDateString("en-US", options);
  };

  const startFormatted = formatDate(dateRange.from);
  const endFormatted = dateRange.to ? formatDate(dateRange.to) : startFormatted;

  if (startFormatted === endFormatted) {
    return startFormatted;
  }

  const [startMonth, startDay, startYear] = startFormatted.split(" ");
  const [endMonth, endDay, endYear] = endFormatted.split(" ");

  if (startYear === endYear) {
    if (startMonth === endMonth) {
      return `${startMonth} ${startDay.replace(",", "")}-${endDay.replace(",", "")}, ${startYear}`;
    }
    return `${startMonth} ${startDay.replace(",", "")} - ${endMonth} ${endDay.replace(",", "")}, ${startYear}`;
  }

  return `${startFormatted} - ${endFormatted}`;
}
