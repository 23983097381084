import api from "./api-client";
import { useQuery } from "@tanstack/react-query";
import { paramsToKey } from "@/utils/helpers";
import { Pagination } from "@/types/pagination";
import { ProgramDonationType } from "@/types/programs";

export const donationTypeKeys = {
  all: (params?: Record<string, string>) =>
    ["donationTypes", ...paramsToKey(params)] as const,
};

const getDonationTypes = async (params?: Record<string, string>) => {
  const queryParams = { ...params };
  const res = await api.get<Pagination<ProgramDonationType>>("/donationtype/", {
    params: queryParams,
  });
  return res.data.results;
};

export const useGetDonationTypes = (params?: Record<string, string>) => {
  return useQuery({
    queryKey: donationTypeKeys.all(params),
    queryFn: () => getDonationTypes(params),
  });
};

export const useGetDonationTypesOptions = (params?: Record<string, string>) => {
  const { data: types } = useGetDonationTypes(params);
  return (
    types?.map((t: { title: string }) => ({
      value: t.title,
      label: t.title,
    })) ?? []
  );
};
