import { Button } from "@/components/ui/button";
import { Link } from "@tanstack/react-router";
import React from "react";
import { Helmet } from "react-helmet-async";

interface MaintenanceMessageProps {
  title: string;
  message: string;
  description: string;
  backHomeText?: string;
}

export const LayoutErrorPages: React.FC<MaintenanceMessageProps> = ({
  title,
  message,
  description,
  backHomeText,
}) => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="flex flex-col items-start justify-start gap-16 px-4 py-40 text-black md:px-96 md:py-32 min-h-[80vh]">
        <div className="flex flex-col items-start justify-start gap-4">
          <h1 className="text-40px md:text-96px pt-4 font-bold">{title}</h1>
          <h2 className="text-24px md:text-48px font-bold">{message}</h2>
          <h3 className="md:text-32px py-16">{description}</h3>
          {backHomeText && (
            <Link to="/">
              <Button>{backHomeText}</Button>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};
