import { createFileRoute } from "@tanstack/react-router";
import { CommunityChallenge } from "@/pages/community-challenge";
import { z } from "zod";

const challengeSchema = z.object({
  tab: z.string(),
});

export const Route = createFileRoute("/community-challenge")({
  component: CommunityChallenge,
  validateSearch: (search) => challengeSchema.parse(search),
});
