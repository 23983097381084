import { ProgramType } from "@/types/programs";
import api from "./api-client";
import {
  queryOptions,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";
import { getPageNumberFromURL, paramsToKey } from "@/utils/helpers";
import { Pagination } from "@/types/pagination";

export const programKeys = {
  all: (params?: Record<string, string>) =>
    ["programs", ...paramsToKey(params)] as const,
  program: (programId: string, params?: Record<string, string>) =>
    [...programKeys.all(), programId, ...paramsToKey(params)] as const,
};

const getProgram = async (
  programId: string,
  params?: Record<string, string>,
) => {
  const queryParams = { ...params };
  const res = await api.get<ProgramType>(`/program/${programId}/`, {
    params: queryParams,
  });
  return res.data;
};

const getPrograms = async (params?: Record<string, string>) => {
  const queryParams = { ...params };
  const res = await api.get<Pagination<ProgramType>>("/program/", {
    params: queryParams,
  });
  return res.data.results;
};

const getPaginatedPrograms = async (params?: Record<string, string>) => {
  const queryParams = { ...params };
  const res = await api.get<Pagination<ProgramType>>("/program/", {
    params: queryParams,
  });
  return res.data;
};

export const useGetPrograms = (
  params?: Record<string, string>,
  select?: (_: ProgramType[]) => ProgramType[],
) => {
  return useQuery({
    queryKey: programKeys.all(params),
    queryFn: () => getPrograms(params),
    select: select,
  });
};

export const programQueryOptions = (
  programId: string,
  params?: Record<string, string>,
) =>
  queryOptions({
    queryKey: programKeys.program(programId, params),
    queryFn: () => getProgram(programId, params),
  });

export const useInfinitePrograms = (params?: Record<string, string>) => {
  return useInfiniteQuery({
    queryKey: programKeys.all(params),
    queryFn: ({ pageParam = 1 }) =>
      getPaginatedPrograms({ ...params, page: pageParam.toString() }),
    getNextPageParam: (page) => getPageNumberFromURL(page.links?.next),
    getPreviousPageParam: (page) => getPageNumberFromURL(page.links?.previous),
    initialPageParam: "1",
  });
};
