import { useQuery } from "@tanstack/react-query";
import api from "./api-client";
import { paramsToKey } from "@/utils/helpers";
import { Pagination } from "@/types/pagination";
import { SocialMedia } from "@/types/information";

const fetchContactInfo = async () => {
  try {
    const response = await api.get("/information/contact/");
    const contact = response.data.results[0];
    return {
      email: contact.email,
      number: contact.number,
    };
  } catch (error) {
    console.error("Failed to fetch contact info:", error);
    throw error;
  }
};

const fetchAboutInfo = async (params?: Record<string, string>) => {
  try {
    const response = await api.get("/information/about/", { params });
    return { about: response.data.results };
  } catch (error) {
    console.error("Failed to fetch about info:", error);
    throw error;
  }
};

const fetchFAQsInfo = async (params?: Record<string, string>) => {
  try {
    let allResults: any[] = [];
    let page = 1;
    let response;

    do {
      response = await api.get("/information/faqs/", {
        params: { ...params, page },
      });
      allResults = allResults.concat(response.data.results);
      page += 1;
    } while (response.data.links.next);

    return { faqs: allResults };
  } catch (error) {
    console.error("Failed to fetch FAQs info:", error);
    throw error;
  }
};

const fetchSocialMediaInfo = async (params?: Record<string, string>) => {
  const queryParams = { ...params };
  const response = await api.get<Pagination<SocialMedia>>(
    "/information/socialmedia/",
    {
      params: queryParams,
    },
  );
  return response.data.results;
};

const informationKeys = {
  contactInfo: () => ["contactInfo"],
  aboutInfo: () => ["aboutInfo"],
  faqsInfo: (params?: Record<string, string>) => [
    "faqsInfo",
    paramsToKey(params),
  ],
  socialMediaInfo: () => ["socialMediaInfo"],
};

export const useContactInfo = () => {
  return useQuery({
    queryKey: informationKeys.contactInfo(),
    queryFn: fetchContactInfo,
  });
};

export const useAboutInfo = (params?: Record<string, string>) => {
  return useQuery({
    queryKey: [...informationKeys.aboutInfo(), params],
    queryFn: () => fetchAboutInfo(params),
  });
};

export const useFAQsInfo = (params?: Record<string, string>) => {
  return useQuery({
    queryKey: informationKeys.faqsInfo(params),
    queryFn: () => fetchFAQsInfo(params),
  });
};

export const useSocialMediaInfo = (params?: Record<string, string>) => {
  return useQuery({
    queryKey: informationKeys.socialMediaInfo(),
    queryFn: () => fetchSocialMediaInfo(params),
  });
};
