import { ChallengeType } from "@/types/challenge";
import { DeliverySlotType } from "@/types/delivery";
import { ProgramType, SpecialProgramType } from "@/types/programs";
import React from "react";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface FormState {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  selectedAmount: number;
  setSelectedAmount: (amount: number) => void;
  inputAmount: number | null;
  setInputAmount: (amount: number | null) => void;
  isZakat: boolean;
  setIsZakat: (checked: boolean) => void;
  fullName: string;
  setFullName: (name: string) => void;
  email: string;
  setEmail: (email: string) => void;
  phoneNumber: string;
  setPhoneNumber: (number: string) => void;
  isSubscribed: boolean;
  setIsSubscribed: (checked: boolean) => void;
  selectedOption: string | null;
  setSelectedOption: (option: string | null) => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  nextStep: () => void;
  prevStep: () => void;
  resetSteps: () => void;
  checkoutStep: () => void;
  hasCompletedAllSteps: boolean;
  setHasCompletedAllSteps: (completed: boolean) => void;
  isLastStep: boolean;
  setIsLastStep: (last: boolean) => void;
  isDisabledStep: boolean;
  setIsDisabledStep: (disabled: boolean) => void;
  reset(): void;
  stepHandlers: Record<string, () => boolean>;
  setStepHandler: (step: string, handler: () => boolean) => void;
  address: string;
  setAddress: (address: string) => void;
  city: string;
  setCity: (city: string) => void;
  totalDonations: number;
  setTotalDonations: (update: number | ((prev: number) => number)) => void;
  donationSocket: WebSocket | null;
  setDonationSocket: (socket: WebSocket | null) => void;
  signatureRef: React.RefObject<HTMLInputElement>;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  merchantId: string;
  selectedProgram: ProgramType | null;
  setSelectedProgram: (_: ProgramType | null) => void;
  finalMonth: Date | null;
  setFinalMonth: (_: Date | null) => void;
  cashSuccess: boolean;
  setCashSuccess: (_: boolean) => void;
  cardCountry: string;
  setCardCountry: (_: string) => void;
  donationType?: string;
  setDonationType: (_?: string) => void;
  livestock: string | null;
  setLivestock: (livestock: string) => void;
  quantity: number;
  setQuantity: (_: number) => void;
  deliveryDate: Date | null;
  setDeliveryDate: (_: Date | null) => void;
  deliveryPhoneNumber: string;
  setDeliveryPhoneNumber: (_: string) => void;
  deliveryCountryDial: string | null;
  setDeliveryCountryDial: (_: string | null) => void;
  deliveryAddress: string;
  setDeliveryAddress: (_: string) => void;
  deliveryCity: string;
  setDeliveryCity: (_: string) => void;
  isDelivery: boolean | null;
  setIsDelivery: (_: boolean) => void;
  isShare: boolean | null;
  setIsShare: (_: boolean) => void;
  slot: DeliverySlotType | null;
  setSlot: (_: DeliverySlotType | null) => void;
  isSpecial: boolean;
  setIsSpecial: (_: boolean) => void;
  specialType?: SpecialProgramType;
  setSpecialType: (_: SpecialProgramType) => void;
  formError: boolean;
  setFormError: (_: boolean) => void;
  isDedicated: boolean;
  setIsDedicated: (isDedicated: boolean) => void;
  honoreeName: string;
  setHonoreeName: (name: string) => void;
  honoreeEmail: string;
  setHonoreeEmail: (email: string) => void;
  dedicationMessage: string;
  setDedicationMessage: (message: string) => void;
  agreementId: string | null;
  setAgreementId: (_: string | null) => void;
  isChallenge: boolean;
  setIsChallenge: (_: boolean) => void;
  selectedChallenge: ChallengeType | null;
  setSelectedChallenge: (_: ChallengeType | null) => void;
  cashDonationId: string | null;
  setCashDonationId: (_: string | null) => void;
  preferenceSuccess: boolean;
  setPreferenceSuccess: (_: boolean) => void;
}

const defaultState = {
  activeTab: "once",
  selectedAmount: 0,
  inputAmount: null,
  isZakat: false,
  fullName: "",
  email: "",
  phoneNumber: "",
  isSubscribed: false,
  selectedOption: null,
  currentStep: 1,
  hasCompletedAllSteps: false,
  isLastStep: false,
  isDisabledStep: true,
  stepHandlers: {},
  address: "",
  city: "",
  totalDonations: 0,
  donationSocket: null,
  isModalOpen: false,
  signatureRef: React.createRef<HTMLInputElement>(),
  merchantId: Math.random().toString(36).slice(2),
  selectedProgram: null,
  finalMonth: null,
  cashSuccess: false,
  cardCountry: "",
  donationType: undefined,
  livestock: null,
  quantity: 0,
  deliveryDate: null,
  deliveryPhoneNumber: "",
  deliveryAddress: "",
  deliveryCity: "",
  isDelivery: null,
  isShare: null,
  slot: null,
  isSpecial: false,
  specialType: SpecialProgramType.NOT_APPLIABLE,
  deliveryCountryDial: null,
  formError: false,
  isDedicated: false,
  honoreeName: "",
  honoreeEmail: "",
  dedicationMessage: "",
  agreementId: null,
  isChallenge: false,
  selectedChallenge: null,
  cashDonationId: null,
  preferenceSuccess: false,
};

export const useFormStore = create<FormState>()(
  persist(
    (set) => ({
      ...defaultState,
      setActiveTab: (tab: string) => set({ activeTab: tab }),
      setSelectedAmount: (amount: number) => set({ selectedAmount: amount }),
      setInputAmount: (amount: number | null) => set({ inputAmount: amount }),
      setIsZakat: (checked: boolean) => set({ isZakat: checked }),
      setFullName: (name: string) => set({ fullName: name }),
      setEmail: (email: string) => set({ email: email }),
      setPhoneNumber: (number: string) => set({ phoneNumber: number }),
      setIsSubscribed: (checked: boolean) => set({ isSubscribed: checked }),
      setSelectedOption: (option: string | null) =>
        set({ selectedOption: option }),
      setCurrentStep: (step: number) => set({ currentStep: step }),
      checkoutStep: () => {
        set({
          isModalOpen: true,
          currentStep: 3,
          hasCompletedAllSteps: true,
          isLastStep: true,
          isDisabledStep: true,
        });
      },
      nextStep: () =>
        set((state) => {
          if (state.stepHandlers[state.currentStep.toString()]) {
            const value = state.stepHandlers[state.currentStep.toString()]();
            if (!value) {
              return {};
            }
          }
          const isLastStep = state.currentStep + 1 === 3;
          return {
            currentStep: state.currentStep + 1,
            hasCompletedAllSteps: state.currentStep + 1 === 3,
            isLastStep: isLastStep,
            isDisabledStep: state.currentStep + 1 === 1,
          };
        }),
      prevStep: () =>
        set((state) => ({
          currentStep: state.currentStep - 1,
          hasCompletedAllSteps: state.currentStep - 1 === 3,
          isLastStep: state.currentStep - 1 === 3,
          isDisabledStep: state.currentStep - 1 === 1,
        })),
      resetSteps: () =>
        set({
          currentStep: 1,
          hasCompletedAllSteps: false,
          isLastStep: false,
          isDisabledStep: true,
        }),
      setHasCompletedAllSteps: (completed: boolean) =>
        set({ hasCompletedAllSteps: completed }),
      setIsLastStep: (last: boolean) => set({ isLastStep: last }),
      setIsDisabledStep: (disabled: boolean) =>
        set({ isDisabledStep: disabled }),
      reset: () =>
        set({
          ...defaultState,
          merchantId: Math.random().toString(36).slice(2),
          finalMonth: null,
        }),
      setStepHandler: (step, handler) =>
        set((state) => ({
          stepHandlers: { ...state.stepHandlers, [step]: handler },
        })),
      setAddress: (address: string) => set({ address }),
      setCity: (city: string) => set({ city }),
      setTotalDonations: (update) =>
        set((state) => ({
          totalDonations:
            typeof update === "function"
              ? update(state.totalDonations)
              : update,
        })),
      setDonationSocket: (socket: WebSocket | null) =>
        set({ donationSocket: socket }),
      setIsModalOpen: (isOpen: boolean) => set({ isModalOpen: isOpen }),
      setSelectedProgram: (program: ProgramType | null) =>
        set({ selectedProgram: program }),
      setFinalMonth: (finalMonth: Date | null) => set({ finalMonth }),
      setCashSuccess: (success: boolean) => set({ cashSuccess: success }),
      setCardCountry(country: string) {
        set({ cardCountry: country });
      },
      setDonationType: (donationType?: string) => set({ donationType }),
      setLivestock: (livestock: string | null) => set({ livestock }),
      setQuantity: (quantity: number) => set({ quantity }),
      setDeliveryDate: (date: Date | null) => set({ deliveryDate: date }),
      setDeliveryPhoneNumber: (phoneNumber: string) =>
        set({ deliveryPhoneNumber: phoneNumber }),
      setDeliveryAddress: (address: string) =>
        set({ deliveryAddress: address }),
      setDeliveryCity: (city: string) => set({ deliveryCity: city }),
      setIsDelivery: (isDelivery: boolean) => set({ isDelivery: isDelivery }),
      setIsShare: (isShare: boolean) => set({ isShare: isShare }),
      setSlot: (slot: DeliverySlotType | null) => set({ slot }),
      setIsSpecial: (isSpecial: boolean) => set({ isSpecial }),
      setSpecialType: (specialType: SpecialProgramType) => set({ specialType }),
      setDeliveryCountryDial: (countryDial: string | null) =>
        set({ deliveryCountryDial: countryDial }),
      setFormError: (error: boolean) => set({ formError: error }),
      setIsDedicated: (isDedicated: boolean) => set({ isDedicated }),
      setHonoreeName: (hname: string) => set({ honoreeName: hname }),
      setHonoreeEmail: (hemail: string) => set({ honoreeEmail: hemail }),
      setDedicationMessage: (hmessage: string) =>
        set({ dedicationMessage: hmessage }),
      setAgreementId: (agreementId: string | null) => set({ agreementId }),
      setIsChallenge: (isChallenge: boolean) => set({ isChallenge }),
      setSelectedChallenge: (challenge: ChallengeType | null) =>
        set({ selectedChallenge: challenge }),
      setCashDonationId: (cashDonationId: string | null) =>
        set({ cashDonationId }),
      setPreferenceSuccess: (preferenceSuccess: boolean) =>
        set({ preferenceSuccess }),
    }),
    {
      name: "donation-form-store",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["signatureRef", "merchantId"].includes(key),
          ),
        ),
    },
  ),
);
