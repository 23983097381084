import { ProgramPage } from "@/pages/program";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const programSearchSchema = z.object({ shared: z.boolean().optional() });

export const Route = createFileRoute("/program/$programId")({
  component: ProgramPage,
  validateSearch: (search) => programSearchSchema.parse(search),
});
