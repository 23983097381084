import config from "@/config/config";
import { SpecialDonation } from "@/types/donation";
import { ProgramType } from "@/types/programs";
import CryptoJS from "crypto-js";

const excluded = [
  "signature",
  "card_security_code",
  "card_number",
  "expiry_date",
  "card_holder_name",
  "remember_me",
  "program_id",
  "client_ip",
  "card_country",
  "donation_type",
  "recurring_expiry_date",
  "special_donation",
  "isDedicated",
  "honoree_name",
  "honoree_email",
  "dedication_message",
];

export const generateSHAFromObject = (
  values: Record<
    string,
    string | number | boolean | Date | SpecialDonation | undefined | null
  >,
) => {
  let signature = config.passSHAPass;
  Object.keys(values)
    .filter((k) => !excluded.includes(k))
    .sort((a, b) => a.localeCompare(b))
    .forEach((k) => {
      signature += `${k}=${values[k]}`;
    });
  signature += config.passSHAPass;
  return CryptoJS.SHA256(signature).toString(CryptoJS.enc.Hex);
};

export const generateSHASignature = (form: FormData) => {
  const sortedKeys = [];
  for (const key of form.keys()) {
    if (!excluded.includes(key)) {
      sortedKeys.push(key);
    }
  }
  let signature = config.passSHAPass;
  sortedKeys.sort((a, b) => a.localeCompare(b));

  sortedKeys.forEach((k) => {
    signature += `${k}=${form.get(k)}`;
  });
  signature += config.passSHAPass;
  return CryptoJS.SHA256(signature).toString(CryptoJS.enc.Hex);
};

export const getProgramAmountOptions = (
  program: ProgramType,
  currency: string,
) => {
  const option = `suggested_amount_${currency.toLowerCase()}`;
  const result = Object.entries(program)
    .filter(([k]) => k.endsWith(option))
    .map(([, v]) => v);
  return result;
};

export const formatAmount = (val: number, currency: string) => {
  const formatted = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  }).format(val);
  if (currency === "EGP") {
    return `${currency} ${formatted}`;
  } else {
    return `${formatted} ${currency}`;
  }
};
