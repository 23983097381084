import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isEqual,
  isPast,
  startOfMonth,
  startOfToday,
} from "date-fns";
import { ChevronLeft, ChevronRight } from "lucide-react";
import * as React from "react";
import { buttonVariants } from "../ui/button";
import { FormikErrors } from "formik";
import { DeliverySlotType, DeliveryType } from "@/types/delivery";
import { useFormStore } from "@/contexts/paymentFlowContext";

interface DayPickerProps {
  readonly setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly currentDay: Date;
  readonly name: string;
  readonly onDayChange: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<any>>;
  readonly delivery?: DeliveryType;
  readonly deliverySlots?: DeliverySlotType[];
}

export default function DayPicker({
  setIsPopoverOpen,
  currentDay,
  name,
  onDayChange,
  delivery,
  deliverySlots,
}: DayPickerProps) {
  const { setSlot, setDeliveryDate } = useFormStore();
  const [currentMonth, setCurrentMonth] = React.useState(
    format(currentDay, "MMM"),
  );
  const [firstDayCurrentMonth, setFirstDayCurrentMonth] = useState(
    startOfMonth(currentDay),
  );

  const [days, setDays] = useState(
    eachDayOfInterval({
      start: delivery?.start_date ? delivery.start_date : firstDayCurrentMonth,
      end: endOfMonth(firstDayCurrentMonth),
    }),
  );

  const weekDays = ["S", "M", "T", "W", "T", "F", "S"];

  function previousMonth() {
    let firstDayPrevMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayPrevMonth, "MMM"));
    setFirstDayCurrentMonth(firstDayPrevMonth);
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM"));
    setFirstDayCurrentMonth(firstDayNextMonth);
  }

  const isNotAvailable = (day: Date) => {
    if (deliverySlots) {
      return deliverySlots.some(
        (slot) =>
          isEqual(slot.date, new Date(format(day, "yyyy-MM-dd"))) &&
          slot.available_slots === 0,
      );
    }
    return false;
  };

  const isOutOfRange = (day: Date) => {
    return (
      delivery &&
      (isAfter(new Date(format(day, "yyyy-MM-dd")), delivery?.end_date) ||
        isBefore(new Date(format(day, "yyyy-MM-dd")), delivery?.start_date))
    );
  };

  useEffect(() => {
    setDays(
      eachDayOfInterval({
        start: firstDayCurrentMonth,
        end: endOfMonth(firstDayCurrentMonth),
      }),
    );
  }, [currentMonth]);

  const handleSlotChange = (day: Date) => {
    setSlot(
      deliverySlots?.find((slot) =>
        isEqual(slot.date, new Date(format(day, "yyyy-MM-dd"))),
      ) || null,
    );
  };

  function renderDaysButtons(days: Date[]) {
    return (
      <tr>
        {days.map((day) => (
          <td key={day.toString()} className="p-0 text-center align-middle">
            <button
              name="day"
              className={cn(
                "inline-flex h-9 w-9 items-center justify-center rounded-full text-sm font-normal ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 aria-pressed:opacity-100 hover:ring-2 hover:ring-black hover:ring-offset-0",
                isEqual(day, currentDay) &&
                  "dark bg-background text-white hover:bg-background hover:text-white focus:bg-background focus:text-white",
                !isEqual(day, currentDay) &&
                  isEqual(day, startOfToday()) &&
                  " text-slate-900 dark:bg-slate-800 dark:text-slate-50",
                isNotAvailable(day) && "line-through",
              )}
              disabled={isPast(day) || isNotAvailable(day) || isOutOfRange(day)}
              aria-pressed={isEqual(day, currentDay)}
              type="button"
              onClick={() => {
                onDayChange(name, day);
                if (delivery) {
                  setDeliveryDate(day);
                  handleSlotChange(day);
                }
                setIsPopoverOpen(false);
              }}
              aria-label="Choose month"
            >
              <time
                dateTime={format(day, "yyyy-MM-dd")}
                className={isNotAvailable(day) ? "line-through" : ""}
              >
                {format(day, "d")}
              </time>
            </button>
          </td>
        ))}
      </tr>
    );
  }

  return (
    <div className="p-0" key={currentMonth}>
      <div className="flex flex-col space-y-0 sm:flex-row sm:space-x-0 sm:space-y-0">
        <div className="w-full p-2">
          <div className="relative flex items-center justify-center pb-2 pt-1">
            <div
              className="text-sm font-medium"
              aria-live="polite"
              id="month-picker"
            >
              {format(firstDayCurrentMonth, "MMMM yyyy")}
            </div>
            <div className="flex items-center space-x-1">
              <button
                name="previous-month"
                aria-label="Go to previous month"
                className={cn(
                  buttonVariants({ variant: "outline" }),
                  "h-7 w-7 bg-transparent p-0 hover:bg-transparent",
                  "absolute left-1",
                )}
                type="button"
                onClick={previousMonth}
              >
                <ChevronLeft className="h-4 w-4" />
              </button>
              <button
                name="next-month"
                aria-label="Go to next month"
                className={cn(
                  buttonVariants({ variant: "outline" }),
                  "h-7 w-7 bg-transparent p-0 hover:bg-transparent",
                  "absolute right-1 disabled:bg-slate-100",
                )}
                type="button"
                onClick={nextMonth}
              >
                <ChevronRight className="h-4 w-4" />
              </button>
            </div>
          </div>
          <table className="w-full">
            <thead>
              <tr>
                <th colSpan={7} className="sr-only">
                  Days
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {weekDays.map((day) => (
                  <th
                    key={day}
                    className="p-2 text-center align-middle text-xs font-light"
                  >
                    {day}
                  </th>
                ))}
              </tr>
              {renderDaysButtons(days.slice(0, 7))}
              {renderDaysButtons(days.slice(7, 14))}
              {renderDaysButtons(days.slice(14, 21))}
              {renderDaysButtons(days.slice(21, 28))}
              {renderDaysButtons(days.slice(28, 31))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
