export const ChipIcon = () => {
  return (
    <svg
      width="35"
      height="28"
      viewBox="0 0 35 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <g clipPath="url(#clip0_5161_4573)">
          <rect
            x="0.108643"
            y="0.917969"
            width="34.7828"
            height="26.2554"
            rx="4.95238"
          />
          <path
            d="M0.108887 8.77197H9.6461C10.8855 8.77197 11.8901 9.77667 11.8901 11.016V15.3639M11.8901 27.1732V21.9558M11.8901 21.9558V15.3639M11.8901 21.9558H0.108887M11.8901 15.3639H0.108887"
            stroke="#F7F4ED"
            strokeWidth="0.866667"
          />
          <path
            d="M34.8916 8.77214H23.6714M23.6714 8.77214V15.364M23.6714 8.77214V0.917969M23.6714 27.1733V21.9559M23.6714 21.9559V15.364M23.6714 21.9559H34.8916M23.6714 15.364H34.8916"
            stroke="#F7F4ED"
            strokeWidth="0.866667"
          />
        </g>
        <rect
          x="0.418167"
          y="1.22749"
          width="34.1637"
          height="25.6363"
          rx="4.64286"
          stroke="#F7F4ED"
          strokeWidth="0.619048"
        />
      </g>
      <defs>
        <clipPath id="clip0_5161_4573">
          <rect
            x="0.108643"
            y="0.917969"
            width="34.7828"
            height="26.2554"
            rx="4.95238"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
