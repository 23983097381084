import api from "./api-client";
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import { getPageNumberFromURL, paramsToKey } from "@/utils/helpers";
import {
  DonationFormType,
  DonationStatisticType,
  DonationType,
} from "@/types/donation";
import { Pagination } from "@/types/pagination";

export const donationKeys = {
  all: (params?: Record<string, string>) =>
    ["donations", ...paramsToKey(params)] as const,
};

const getDonations = async (params?: Record<string, string>) => {
  const res = await api.get<Pagination<DonationType>>("/payment/donations/", {
    params,
  });
  return res.data;
};

const postDonation = async (data: Record<string, any>) => {
  const res = await api.post("/payment/donations/", data);
  return res.data;
};

export const usePostDonation = (
  options?: UseMutationOptions<DonationType, Error, { data: DonationFormType }>,
) =>
  useMutation({
    mutationFn: ({ data }) => postDonation(data),
    ...options,
  });

export const useGetDonations = (params?: Record<string, string>) => {
  return useQuery({
    queryKey: donationKeys.all(params),
    queryFn: () => getDonations(params),
  });
};

export const getDonationStatistics = async (
  params?: Record<string, string>,
) => {
  const res = await api.get<DonationStatisticType[]>(
    "/payment/donation-stats/",
    { params },
  );
  return res.data;
};

export const useGetDonationStatistics = (params?: Record<string, string>) => {
  return useQuery({
    queryKey: ["statistics", paramsToKey(params)],
    queryFn: () => getDonationStatistics(params),
  });
};

export const useLoadInfiniteDonations = (params?: Record<string, string>) => {
  return useInfiniteQuery({
    queryKey: donationKeys.all(params),
    queryFn: ({ pageParam = 1 }) =>
      getDonations({ ...params, page: pageParam.toString() }),
    getNextPageParam: (page) => getPageNumberFromURL(page.links?.next),
    getPreviousPageParam: (page) => getPageNumberFromURL(page.links?.previous),
    initialPageParam: 1 as any,
  });
};
