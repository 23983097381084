import { ProfileIcon } from "@/assets/icons/Profile";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";

type UpdateCardProps = {
  date: string;
  updateText: string;
  setUpdateText?: (text: string) => void;
  handlePostUpdate?: () => void;
  isReadonly: boolean;
  author?: string;
  className?: string;
};
export const OwnerUpdateCard = ({
  date,
  updateText,
  setUpdateText,
  handlePostUpdate,
  isReadonly,
  author,
  className,
}: UpdateCardProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        "flex h-[550px] shrink-0 flex-col justify-between gap-8 rounded-2xl border border-[#CCC2BF] bg-[#F7F4ED] p-6 w-[310px]",
        className,
      )}
    >
      <div className="flex flex-col">
        <p className="text-sm font-[350]">{t("challenge.ownerUpdates")}</p>
        <h2 className="font-[650]">{date}</h2>
      </div>

      <div className="flex h-full flex-col items-start justify-start gap-4">
        <div className="flex max-h-10 min-h-10 min-w-10 max-w-10 items-center justify-center rounded-full bg-[#CCC2BF]">
          <ProfileIcon height={20} width={19} />
        </div>
        {isReadonly && <p className="text-sm font-[650]">{author}</p>}
        <textarea
          className="scrollbar-hide h-full w-full resize-none rounded-md bg-transparent font-[350] text-[#37343F] outline-none placeholder:font-[350] placeholder:text-[#37343F] focus:outline-none focus:ring-0 focus:ring-transparent"
          rows={updateText.length < 320 ? updateText.length / 25 : 10}
          placeholder={t("challenge.writeUpdate")}
          value={updateText}
          readOnly={isReadonly}
          onChange={(e) => setUpdateText && setUpdateText(e.target.value)}
        />
      </div>
      {handlePostUpdate && (
        <Button
          className="bg-foreground w-full px-10 py-4 text-white hover:bg-black"
          onClick={handlePostUpdate}
        >
          {t("challenge.postUpdate")}
        </Button>
      )}
    </div>
  );
};
