import { Skeleton } from "@/components/ui/skeleton";

export default function ProfileSkeleton() {
  return (
    <div className="w-full">
      <div className="grid w-full grid-cols-1 items-start justify-start gap-y-4 md:grid-cols-4 md:gap-x-16 rtl:justify-items-end md:rtl:justify-end">
        <div className="w-full xl:rtl:order-last">
          <div className="flex w-full items-center justify-between gap-8 py-8 md:flex-col md:items-start md:py-0 md:rtl:items-end">
            <Skeleton className="h-32 w-32 rounded-full" />
            <Skeleton className="h-10 w-32" />
          </div>
        </div>
        <Skeleton className="col-span-3 flex h-[90vh] w-full max-w-[672px] flex-col gap-16 place-self-center xl:col-span-2 md:rtl:order-first xl:rtl:order-2" />
        <div className="xl:rtl:order-1"></div>
      </div>
    </div>
  );
}
