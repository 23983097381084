import api from "./api-client";
import {
  UseMutationOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { getPageNumberFromURL, paramsToKey } from "@/utils/helpers";
import { SubscriptionType, SubscriptionUpdateType } from "@/types/donation";
import { Pagination } from "@/types/pagination";
import useUserStore from "@/store/userStore";

export const subscriptionKeys = {
  all: (params?: Record<string, string>) =>
    ["subscriptions", ...paramsToKey(params)] as const,
};

const getSubscription = async (params?: Record<string, string>) => {
  const queryParams = { ...params };
  const res = await api.get<Pagination<SubscriptionType>>(
    "/payment/subscriptions/",
    {
      params: queryParams,
    },
  );
  return res.data;
};

const updateSubscription = async (id: number, data: SubscriptionUpdateType) => {
  const res = await api.patch("/payment/subscription/" + id, data);
  return res.data;
};

export const useGetSubscriptions = (params?: Record<string, string>) => {
  const { token } = useUserStore();
  return useQuery({
    queryKey: subscriptionKeys.all(params),
    queryFn: () => getSubscription(params),
    enabled: !!token,
  });
};

export const useUpdateSubscription = (
  options?: UseMutationOptions<
    SubscriptionType,
    Error,
    { data: SubscriptionUpdateType }
  >,
) => {
  return useMutation({
    mutationFn: ({ data }) => updateSubscription(data.id, data),
    ...options,
  });
};

export const useLoadInfiniteSubscriptions = (
  params?: Record<string, string>,
) => {
  return useInfiniteQuery({
    queryKey: subscriptionKeys.all(params),
    queryFn: ({ pageParam = 1 }) =>
      getSubscription({ ...params, page: pageParam.toString() }),
    getNextPageParam: (page) => getPageNumberFromURL(page.links?.next),
    getPreviousPageParam: (page) => getPageNumberFromURL(page.links?.previous),
    initialPageParam: 1 as any,
  });
};
