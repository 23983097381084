import CustomInputField from "@/components/PaymentFlow/Steps/CustomInputFiled";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import ImageModal from "./ImageModal";
import { useEffect, useState } from "react";
import { User } from "@/types/auth";
import { useEditProfile } from "@/api/profile";
import DeleteModal from "./DeleteModal";
import { toast } from "react-toastify";
import { cityQueryOptions, useGetCountries } from "@/api/geography";
import FormikCustomSelectField from "@/components/ui/FormikCustomSelectField";
import { useQuery } from "@tanstack/react-query";
import LogoutModal from "./LogoutModal";
import { PhoneNumberField } from "@/components/PaymentFlow/Steps/PhoneNumberField";
import { useTranslation } from "react-i18next";
import { CreditCards } from "@/pages/donor-profile/sections/credit-cards";
import { isRTLLanguage } from "@/utils/helpers";
interface ProfileProps {
  user?: User;
}

export default function ProfileForm({ user }: Readonly<ProfileProps>) {
  const { t, i18n } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [profileImage, setProfileImage] = useState<File>();
  const [selectedCountry, setSelectedCountry] = useState(
    user?.profile?.country?.id ?? "",
  );
  const currencyOptions = [
    { label: t("profile.usd"), value: "usd" },
    { label: t("profile.egp"), value: "egp" },
  ];
  const languageOptions = [
    { label: t("profile.english"), value: "en" },
    { label: t("profile.arabic"), value: "ar" },
  ];
  const { data: countries } = useGetCountries({
    lang: i18n.language,
    page_size: "null",
  });
  const { data: cities } = useQuery(
    cityQueryOptions(selectedCountry, { lang: i18n.language }),
  );
  useEffect(() => {
    if (user?.profile?.image) {
      if (user.profile.image[0]) {
        fetch(user.profile.image[0].minio_url)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "profile-image", { type: blob.type });
            setProfileImage(file);
          });
      }
    }
  }, [user]);

  const validationSchema = Yup.object().shape({
    profile: Yup.object().shape({
      firstName: Yup.string().required(t("yup.firstNameRequired")),
      lastName: Yup.string().nullable(),
      country_id: Yup.string().nullable().optional(),
      city_id: Yup.string().nullable().optional(),
    }),
    email: Yup.string()
      .email(t("yup.emailInvalid"))
      .required(t("yup.emailRequired")),
    phonenumber: Yup.string()
      .matches(/\d{9,15}/, t("yup.phoneNumberInvalid"))
      .nullable()
      .optional(),
    preferred_language: Yup.string()
      .nullable()
      .max(2, t("yup.preferredLanguageMax")),
    currency: Yup.string().nullable().max(3, t("yup.currencyMax")),
  });

  const { mutate } = useEditProfile({
    onSuccess: (res) => {
      localStorage.setItem("lang", res.preferred_language.toLowerCase());
      i18n.changeLanguage(res.preferred_language.toLowerCase());
      setIsEditing(false);
      setUpdateSuccess(true);
      setTimeout(() => {
        setUpdateSuccess(false);
      }, 1500);
    },
    onError: () => {
      toast.error("Error updating profile", {
        position: "bottom-center",
      });
    },
  });

  return (
    <div className="relative w-full">
      <Formik
        initialValues={{
          profile: {
            firstName: user?.profile ? user.profile.firstName : "",
            lastName: user?.profile.lastName ?? null,
            country_id: user?.profile?.country
              ? user?.profile?.country.id
              : null,
            city_id: user?.profile?.city ? user?.profile?.city.id : null,
          },
          email: user ? user?.email : "",
          phonenumber: user?.phonenumber ?? null,
          country_dial: user?.country_dial ?? null,
          currency: user?.currency ? user?.currency.toLowerCase() : null,
          preferred_language: user?.preferred_language
            ? user?.preferred_language?.toLowerCase()
            : null,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (values.phonenumber === "") {
            values.phonenumber = null;
            values.country_dial = null;
          } else if (!values.phonenumber) {
            values.country_dial = null;
          }
          if (values.profile.lastName === "") {
            values.profile.lastName = null;
          }
          mutate({
            data: values,
          });
        }}
      >
        {({ resetForm, setFieldValue }) => (
          <Form
            dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
            className="grid w-full grid-cols-1 items-start justify-start gap-y-4 md:grid-cols-4 md:gap-x-16"
          >
            <div className="w-full">
              <ImageModal
                profileImage={profileImage}
                setProfileImage={setProfileImage}
              />
            </div>
            <div className="col-span-3 flex w-full max-w-[672px] flex-col gap-16 place-self-center xl:col-span-2">
              <div className="flex w-full flex-col gap-8">
                <h3 className="self-start text-xl font-bold md:text-3xl">
                  {t("profile.personalDetails")}
                </h3>
                <div className="flex w-full flex-col gap-4">
                  <CustomInputField
                    label={t("profile.firstName")}
                    name="profile.firstName"
                    placeholder={t("profile.firstNamePlaceholder")}
                    onChangeInput={(e) => {
                      setFieldValue("profile.firstName", e.target.value);
                      setIsEditing(true);
                    }}
                  />
                  <CustomInputField
                    label={t("profile.lastName")}
                    name="profile.lastName"
                    placeholder={t("profile.lastNamePlaceholder")}
                    onChangeInput={(e) => {
                      setFieldValue("profile.lastName", e.target.value);
                      setIsEditing(true);
                    }}
                  />
                  <FormikCustomSelectField
                    label={t("profile.country")}
                    name="profile.country_id"
                    options={
                      countries
                        ? countries.map((country) => ({
                            label: country.name,
                            value: country.id,
                          }))
                        : []
                    }
                    handleInputChange={(value) => {
                      setFieldValue("profile.country_id", value);
                      setFieldValue("profile.city_id", null);
                      setSelectedCountry(value as string);
                      setIsEditing(true);
                    }}
                    searchable={true}
                    placeholder={t("profile.countryPlaceholder")}
                  />
                  {selectedCountry && (
                    <FormikCustomSelectField
                      label={t("profile.city")}
                      name="profile.city_id"
                      options={
                        cities
                          ? cities.map((city) => ({
                              label: city.name,
                              value: city.id,
                            }))
                          : []
                      }
                      handleInputChange={(value) => {
                        setFieldValue("profile.city_id", value);
                        setIsEditing(true);
                      }}
                      searchable={true}
                      placeholder={t("profile.cityPlaceholder")}
                    />
                  )}
                  <PhoneNumberField
                    label={t("profile.phoneNumber")}
                    name="phonenumber"
                    placeholder={t("profile.phoneNumberPlaceholder")}
                    optional={true}
                    onChange={(e) => {
                      setFieldValue("phonenumber", e.target.value);
                      setIsEditing(true);
                    }}
                  />
                  <CustomInputField
                    label={t("profile.email")}
                    name="email"
                    type="email"
                    placeholder={t("profile.emailPlaceholder")}
                    onChangeInput={(e) => {
                      setFieldValue("email", e.target.value);
                      setIsEditing(true);
                    }}
                  />
                </div>
              </div>
              <div className="flex w-full flex-col gap-8">
                <h3 className="self-start text-xl font-bold md:text-3xl">
                  {t("profile.languageAndCurrency")}
                </h3>
                <FormikCustomSelectField
                  label={t("profile.preferredCurrency")}
                  name="currency"
                  options={currencyOptions}
                  handleInputChange={(value) => {
                    setFieldValue("currency", value);
                    setIsEditing(true);
                  }}
                  searchable={false}
                  placeholder={t("profile.preferredCurrencyPlaceholder")}
                />
                <FormikCustomSelectField
                  label={t("profile.preferredLanguage")}
                  name="preferred_language"
                  options={languageOptions}
                  handleInputChange={(value) => {
                    setFieldValue("preferred_language", value);
                    setIsEditing(true);
                  }}
                  searchable={false}
                  placeholder={t("profile.preferredLanguagePlaceholder")}
                />
              </div>
              <div className="flex w-full flex-col gap-8">
                <CreditCards />
              </div>
              <div className="flex w-full flex-col gap-8">
                <h3 className="self-start text-xl font-bold md:text-3xl">
                  {t("profile.manageAccount")}
                </h3>
                <div className="flex w-full flex-col items-start justify-between gap-8 rounded bg-[#E4E1D8] px-6 py-8 md:flex-row md:items-center">
                  <div className="flex flex-col items-start">
                    <h5 className="text-base font-bold">
                      {t("profile.deleteAccount")}
                    </h5>
                    <p className="w-72 text-sm">
                      {t("profile.byDeleting")}
                      <span className="underline">{t("profile.terms")}</span>
                    </p>
                  </div>
                  <DeleteModal />
                </div>
              </div>
              <div className="flex w-full flex-col gap-8">
                <LogoutModal />
              </div>
            </div>
            <div className=""></div>
            {isEditing && (
              <div className="fixed bottom-0 left-0 flex min-w-full flex-col justify-center gap-4 bg-[#E4E1D8] p-8 md:flex-row">
                <Button size={"lg"} type="submit" className="min-w-56">
                  {t("profile.saveChanges")}
                </Button>
                <Button
                  size="lg"
                  variant="underline"
                  type="reset"
                  className="min-w-56"
                  onClick={() => {
                    resetForm();
                    setFieldValue("phonenumber", "");
                    setIsEditing(false);
                  }}
                >
                  {t("profile.cancel")}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {updateSuccess && (
        <>
          <div className="fixed inset-0 z-20 flex h-full w-full items-center justify-center">
            <h3 className="w-[270px] text-center text-[32px] font-bold">
              {t("profile.changes")}
            </h3>
          </div>
          <div className="bg-background fixed inset-0 z-10 flex h-full w-full items-center justify-center opacity-40"></div>
        </>
      )}
    </div>
  );
}
