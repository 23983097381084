import config from "@/config/config";
import useCurrencyStore, { Currencies } from "@/store/currencyStore";
import { defaultCurrencies } from "@/utils/currency";
import { QueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";

type UserLocaleType = {
  localeCurrency: string;
  conversionRate?: number;
};

const getLocaleCurrency = async (): Promise<UserLocaleType> => {
  const {
    addCurrency,
    setConversionRate,
    setCurrency,
    conversionRate,
    currency: currentCurrency,
  } = useCurrencyStore.getState();
  try {
    const { ip } = (await axios.get("https://api.ipify.org/?format=json")).data;
    if (!ip) {
      throw Error("Couldn't retrieve client IP.");
    }
    const locale = await axios.get(`payment/ip/${ip}`, {
      baseURL: config.baseURL,
    });
    const { currency } = locale.data;
    const conversion_response = await axios.get(
      `payment/conversion-rate/${currency}`,
      {
        baseURL: config.baseURL,
      },
    );
    const { converted_amount } = conversion_response.data;
    if (converted_amount && currency) {
      addCurrency(currency);
      setConversionRate(Number(converted_amount));
      if (
        !conversionRate ||
        (!defaultCurrencies.includes(currentCurrency) &&
          currentCurrency !== currency)
      ) {
        setCurrency(currency);
      }
    }
    return {
      localeCurrency: currency,
      conversionRate: Number(converted_amount),
    };
  } catch (err) {
    setCurrency(Currencies.USD);
    setConversionRate(undefined);
    return {
      localeCurrency: Currencies.USD,
    };
  }
};

export const useGetLocaleCurrency = (options?: QueryOptions) => {
  return useQuery<unknown, Error, UserLocaleType>({
    queryKey: ["user-locale"],
    queryFn: getLocaleCurrency,
    ...options,
  });
};
