import PreviewIcon from "@/assets/icons/PreviewIcon";
import { Button } from "@/components/ui/button";
import useChallengeFormStore from "@/store/challengeStore";
import React from "react";

type PreviewButtonProps = {
  className?: string;
};

const PreviewButton: React.FC<PreviewButtonProps> = ({ className = "" }) => {
  const { setPreview } = useChallengeFormStore();
  return (
    <Button
      onClick={() => setPreview(true)}
      className={`rounded-full bg-transparent p-2 transition-colors hover:bg-gray-200 ${className}`}
      aria-label="Preview"
    >
      <PreviewIcon fill="hsla(240, 8%, 15%, 1)" />
    </Button>
  );
};

export default PreviewButton;
