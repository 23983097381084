import DatePicker from "@/components/Inputs/DatePicker";
import DropDown from "@/components/ui/dropdown";
import {
  ProgramDonationType,
  ProgramType,
  SuggestedAmountType,
} from "@/types/programs";
import { ChangeEvent, useCallback, useEffect } from "react";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { Button } from "@/components/ui/button";
import AmountInput from "@/components/Inputs/AmountInput";
import { cn } from "@/lib/utils";
import { t } from "i18next";
import { DedicationForm } from "./DedicationForm";
import {
  formatNumber,
  getCurrencySymbol,
  getMealPrice,
  getSuggestedAmount,
} from "@/utils/currency";

type SpecialProgramProps = {
  readonly program: ProgramType;
  readonly suggestedAmounts?: SuggestedAmountType[];
  readonly errors: Record<string, string>;
};
type AmountProps = {
  id: string;
  amount: number;
  currency: string;
};

type ThanksMessageProps = {
  amount: number | null;
  setInputAmount: (amount: number | null) => void;
  message: string;
  meal_price_egp: number;
  meal_price_usd: number;
};

const AmountButton = ({ id, amount, currency }: AmountProps) => {
  const { selectedAmount, setSelectedAmount, setInputAmount } = useFormStore();

  const handleAmountClick = useCallback(
    (amount: number) => {
      setSelectedAmount(selectedAmount === amount ? 0 : amount);
      setInputAmount(selectedAmount === amount ? null : amount);
    },
    [selectedAmount, setSelectedAmount, setInputAmount],
  );

  return (
    <button
      key={`${id}${amount}`}
      onClick={() => handleAmountClick(amount)}
      className={`flex-grow cursor-pointer border p-3 text-center font-bold ${
        selectedAmount === amount
          ? "bg-[#E2DDD7]"
          : "text-foreground border-[#CCC2BF]"
      } rounded`}
    >
      <span className="hidden md:block">
        {`${currency}${amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}`}
      </span>

      <span className="block md:hidden">
        {`${currency}${formatNumber(amount)}`}
      </span>
    </button>
  );
};

export const ThanksMessage = ({
  amount,
  message,
  meal_price_egp,
  meal_price_usd,
  setInputAmount,
}: Readonly<ThanksMessageProps>) => {
  if (!amount) {
    return null;
  }

  const currencySymbol = getCurrencySymbol();
  const mealPrice = getMealPrice(meal_price_egp, meal_price_usd);
  const mealCount = +(amount / mealPrice).toFixed(2);
  const roundedMealCount = Math.ceil(mealCount);
  const extraDonation = (roundedMealCount * mealPrice - amount).toFixed(2);

  if (!isFinite(roundedMealCount)) {
    return null;
  }

  const handleExtraDonation = () => {
    const newAmount = parseFloat(amount.toString()) + parseFloat(extraDonation);
    setInputAmount(newAmount);
  };

  return (
    <div className="my-2 flex items-start gap-5 rounded-lg bg-[#E2DDD7] p-6 shadow-sm">
      <img src="/icons/thanks.svg" alt="Thanks" className="h-6 w-6" />
      <div className="flex w-full flex-col items-start justify-start gap-4">
        <div className="flex items-center">
          <p className="text-foreground text-sm leading-[150%] md:text-base">
            {t("StepsContainer.provide")}{" "}
            {mealCount % 1 !== 0 && t("StepsContainer.nearly")}{" "}
            <span className="font-bold">
              {roundedMealCount} {message}
            </span>
            .
            {mealCount % 1 !== 0 && (
              <>
                {" "}
                {t("StepsContainer.for")} {currencySymbol}
                {extraDonation} {t("StepsContainer.more")} {roundedMealCount}{" "}
                {message}. {t("StepsContainer.round")} {currencySymbol}
                {formatNumber(amount + +extraDonation)}
                {t("StepsContainer.question")}
              </>
            )}
          </p>
        </div>
        {mealCount % 1 !== 0 && (
          <Button
            onClick={handleExtraDonation}
            className="text-foreground !h-auto !p-0 text-sm underline underline-offset-2 hover:bg-transparent md:text-base"
            variant={"ghost"}
          >
            {t("StepsContainer.add")} {currencySymbol}
            {extraDonation}
          </Button>
        )}
      </div>
    </div>
  );
};

export default function RegularProgramForm({
  program,
  suggestedAmounts,
  errors,
}: SpecialProgramProps) {
  const {
    activeTab,
    setActiveTab,
    setSelectedAmount,
    inputAmount,
    setInputAmount,
    finalMonth,
    setFinalMonth,
    donationType,
    setDonationType,
  } = useFormStore();

  useEffect(() => {
    if (suggestedAmounts?.[0]) {
      if (!inputAmount) {
        setSelectedAmount(getSuggestedAmount(suggestedAmounts[0]));
        setInputAmount(getSuggestedAmount(suggestedAmounts[0]));
      }
    }
  }, [suggestedAmounts, setSelectedAmount, setInputAmount]);

  const handleTabClick = useCallback(
    (tab: string) => {
      setActiveTab(tab);
    },
    [setActiveTab],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedAmount(0);
      const value = event.target.value;
      if (value === "") {
        setInputAmount(null);
      } else {
        const numberValue = parseFloat(value);
        if (!isNaN(numberValue)) {
          setInputAmount(numberValue);
        } else {
          setInputAmount(null);
        }
      }
    },
    [setSelectedAmount, setInputAmount],
  );

  return (
    <>
      <div className="mb-6 flex w-full rounded-md bg-[#E2DDD7] p-1 text-sm md:text-base">
        <button
          onClick={() => handleTabClick("once")}
          className={`w-1/2 cursor-pointer py-3 text-center ${
            activeTab === "once"
              ? "rounded-md bg-[#232329] font-bold text-[#EDEAE4]"
              : ""
          }`}
        >
          {t("donationFlow.once")}
        </button>
        <button
          onClick={() => handleTabClick("monthly")}
          className={`w-1/2 cursor-pointer py-3 text-center ${
            activeTab === "monthly"
              ? "rounded-md bg-[#232329] font-bold text-[#EDEAE4]"
              : ""
          }`}
        >
          {t("donationFlow.monthly")}
        </button>
      </div>
      <div className="mb-6 grid grid-cols-3 gap-4 text-sm sm:grid-cols-4 md:text-base">
        {suggestedAmounts?.map((amount) => (
          <AmountButton
            key={`amount-${amount.amount_usd}-${amount.amount_egp}`}
            id={amount.id}
            amount={getSuggestedAmount(amount)}
            currency={getCurrencySymbol()}
          />
        ))}
      </div>
      <div className="mb-6 ">
        <div className="w-full">
          <label
            htmlFor="price"
            className="text-foreground mb-2 block text-xs font-bold md:text-sm"
          >
            {t("donationFlow.customAmount")}
          </label>
          <AmountInput
            value={inputAmount !== null ? inputAmount.toString() : ""}
            onChange={handleInputChange}
            className={cn({
              "border-[#F04438]": errors.inputAmount,
            })}
            placeholder={t("donationFlow.placeholder")}
          />
          {errors.inputAmount && (
            <p className="mt-1 text-sm text-[#F04438]">{errors.inputAmount}</p>
          )}
        </div>
      </div>
      {activeTab === "monthly" && (
        <div className="mb-6">
          <label
            htmlFor="price"
            className="text-forground mb-1 block text-xs font-bold md:text-sm"
          >
            {t("donationFlow.stopDonation")}
          </label>
          <DatePicker
            selected={finalMonth}
            onChange={setFinalMonth}
            placeholderText={t("donationFlow.final")}
            className={cn({
              "border-[#F04438]": errors.finalMonth,
            })}
          />
          {errors.finalMonth && (
            <p className="mt-1 text-sm text-[#F04438]">{errors.finalMonth}</p>
          )}
        </div>
      )}
      <DropDown
        label={t("donationFlow.donation")}
        name="donation_type"
        options={program.donation_types.map(
          (donation_type: ProgramDonationType) => ({
            label: donation_type.title,
            value: donation_type.id,
          }),
        )}
        placeholder={t("donationFlow.donationtype")}
        onChange={(value) =>
          typeof value === "string" && setDonationType(value)
        }
        value={donationType}
      />

      <div className="my-6">
        <ThanksMessage
          amount={inputAmount}
          meal_price_egp={program.min_amount_egp}
          meal_price_usd={program.meal_price_usd}
          setInputAmount={setInputAmount}
          message={program.message}
        />
      </div>

      <div className="pb-6">
        <DedicationForm />
      </div>
    </>
  );
}
