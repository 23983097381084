import React from "react";
import { useField } from "formik";
import CommonSelectField from "./commondropdwon";

interface FormikCustomSelectFieldProps {
  name: string;
  options: { value: string; label: string }[];
  placeholder?: string;
  searchable?: boolean;
  searchPlaceholder?: string;
  label?: string;
  optional?: boolean;
  multiSelect?: boolean;
  handleInputChange?: (value: string | string[]) => void;
  error?: string;
}

const FormikCustomSelectField: React.FC<FormikCustomSelectFieldProps> = ({
  multiSelect = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const handleBlur = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLButtonElement>) => {
      field.onBlur(e);
    },
    [field],
  );

  const handleChange = React.useCallback(
    (value: string | string[]) => {
      helpers.setValue(value);
    },
    [helpers],
  );

  return (
    <CommonSelectField
      {...props}
      value={field.value}
      onChange={props.handleInputChange ?? handleChange}
      onBlur={handleBlur}
      error={
        props.error && props.error !== ""
          ? props.error
          : meta.touched && meta.error
            ? meta.error
            : undefined
      }
      multiSelect={multiSelect}
    />
  );
};

export default FormikCustomSelectField;
