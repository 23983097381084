import React, { useEffect, useState, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Header } from "../header";
import { useAboutInfo } from "@/api/information";
import StatisticsCard from "./sections/statisticscard";
import FoldedHands from "@/assets/icons/FoldedHands";
import { Calendar } from "@/assets/icons/Calendar";
import { formatNumber } from "@/utils/currency";
import { soupDark } from "@/assets/icons/soupDark";
import Card from "./sections/card";
import { Button } from "@/components/ui/button";
import { Link } from "@tanstack/react-router";
import { getLoginURL } from "@/utils/auth";
import CustomLink from "@/pages/home/sections/CustomLink";
import { CarouselComponent } from "@/pages/home/sections/CarouselComponent";
import { UpdateCard } from "@/pages/program/sections/update-card";
import { useUpdates } from "@/api/update";
import { UpdateType } from "@/types/programs";
import { MediaCarousel } from "./sections/MediaCarousel";
import { MediaItem } from "@/types/information";
import { AppShell } from "@/components/Navbar";
import clsx from "clsx";
import { Helmet } from "react-helmet-async";

export const AboutUsPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { data } = useAboutInfo({ lang: i18n.language });
  const { data: updatesData } = useUpdates({ lang: i18n.language });
  const [media, setMedia] = useState<MediaItem[]>([]);
  const [video, setVideo] = useState<MediaItem | null>(null);
  const [mentions, setMentions] = useState([]);
  const [awards, setAwards] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);

  const aboutInfo = data?.about?.[0];

  useEffect(() => {
    if (aboutInfo) {
      const filteredMedia = aboutInfo.documents.filter(
        (doc: { file_type: string }) => doc.file_type === "aboutus_logo",
      );
      setMedia(filteredMedia.slice(0, 2));

      const videoMedia = aboutInfo.documents.find(
        (doc: { file_type: string }) => doc.file_type === "video",
      );
      setVideo(videoMedia || null);

      const mentionsMedia = aboutInfo.documents.filter(
        (doc: { file_type: string }) => doc.file_type === "mentions_logo",
      );
      setMentions(mentionsMedia);

      const awardsMedia = aboutInfo.documents.filter(
        (doc: { file_type: string }) => doc.file_type === "awards_logo",
      );
      setAwards(awardsMedia);
    }
  }, [aboutInfo]);

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const rect = headerRef.current.getBoundingClientRect();
        if (rect.bottom <= 0) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const statsData = [
    {
      id: "1",
      iconSrc: Calendar,
      altText: "",
      statistic: `${t("aboutPage.in")} ${aboutInfo?.establishment || ""}`,
      description: t("aboutPage.establishment"),
      backgroundColor: "#E4E1D8",
      textColor: "#22222A",
    },
    {
      id: "2",
      iconSrc: FoldedHands,
      altText: "",
      statistic: formatNumber(aboutInfo?.volunteers || 0),
      description: t("aboutPage.volunteers"),
      backgroundColor: "#E4E1D8",
      textColor: "#22222A",
    },
    {
      id: "3",
      iconSrc: soupDark,
      altText: "",
      statistic: formatNumber(aboutInfo?.meals || 0),
      description: `${t("aboutPage.meals")} ${aboutInfo?.mealFrom || ""} ${t("aboutPage.to")} ${aboutInfo?.mealTo || ""}`,
      backgroundColor: "#E4E1D8",
      textColor: "#22222A",
    },
  ];

  const cardData = [
    {
      id: 1,
      title: t("aboutPage.transparency"),
      description: t("aboutPage.transparency_description"),
    },
    {
      id: 2,
      title: t("aboutPage.collaboration"),
      description: t("aboutPage.collaboration_description"),
    },
    {
      id: 3,
      title: t("aboutPage.sustainability"),
      description: t("aboutPage.sustainability_description"),
      hasBorder: false,
    },
    {
      id: 4,
      title: t("aboutPage.spirit"),
      description: t("aboutPage.spirit_description"),
    },
    {
      id: 5,
      title: t("aboutPage.passion"),
      description: t("aboutPage.passion_description"),
    },
    {
      id: 6,
      title: t("aboutPage.excellence"),
      description: t("aboutPage.excellence_description"),
      hasBorder: false,
    },
  ];

  const updates: UpdateType[] =
    updatesData?.filter((update: { is_aboutus: any }) => update.is_aboutus) ||
    [];

  const isRTL = i18n.language === "ar";

  return (
    <>
      <Helmet>
        <title>About Circle of Humanity | Our Mission & Values</title>
        <meta
          name="description"
          content="Learn about Circle of Humanity, an initiative by the Egyptian Food Bank dedicated to alleviating hunger and supporting Egyptians, refugees, and those in crisis. Discover our history, mission, and how we make a difference."
        />
        <meta
          name="keywords"
          content="About Circle of Humanity, Egyptian Food Bank, NGO, hunger relief, mission, values, history"
        />
        <link rel="canonical" href="https://coh.efb.eg/aboutUs" />
        <meta
          property="og:title"
          content="About Circle of Humanity | Learn Our Story"
        />
        <meta
          property="og:description"
          content="Dedicated to fighting hunger in Egypt and beyond, Circle of Humanity works tirelessly to provide nutritious food and support to those in need. Find out more about our mission and values."
        />
        <meta property="og:url" content="https://coh.efb.eg/aboutUs" />
        <meta property="og:site_name" content="Circle of Humanity" />
      </Helmet>
      <div ref={headerRef}>
        <Header
          title={
            <Trans
              i18nKey="aboutPage.title"
              components={{
                bold: <strong />,
              }}
            />
          }
          picture="aboutUs.jpg"
        />
      </div>

      <AppShell
        navbarClass={clsx(
          "fixed top-0 -z-10 w-full bg-[#CDCFC9] opacity-0 transition-opacity duration-300 ease-in-out",
          { "opacity-100 z-10": isScrolled },
        )}
      />

      <div className={`mx-6 my-16 md:mx-6 md:my-16 ${isRTL ? "rtl" : "ltr"}`}>
        <div
          className={`mb-8 flex flex-col md:mb-16 md:flex-row md:gap-6 ${isRTL ? "rtl" : "ltr"}`}
        >
          <div
            className="flex flex-1 items-center"
            style={{ marginBottom: "60px" }}
          >
            {media.map((item) => {
              let borderClass = "";

              if (item.id === media[0].id) {
                borderClass = isRTL
                  ? "border-l border-gray-300 pl-12"
                  : "border-r border-gray-300 pr-12";
              } else {
                borderClass = isRTL ? "pr-12" : "pl-12";
              }

              return (
                <div
                  key={item.id}
                  className={`flex justify-center ${borderClass} min-w-[150px] flex-shrink-0`}
                >
                  <img
                    src={item?.minio_url}
                    alt={`media-${item.id}`}
                    className="h-auto w-full max-w-[120px] rounded-lg object-contain md:max-h-[100px] md:min-w-[150px] md:max-w-[260px]"
                  />
                </div>
              );
            })}
          </div>
          <div className="flex-1">
            {aboutInfo?.title && (
              <h2 className="w-full break-words text-2xl font-bold md:text-5xl">
                {aboutInfo.title}
              </h2>
            )}
            {aboutInfo?.description && (
              <p className="font-400 mt-8 w-full break-words text-base md:mt-16 md:text-lg">
                {aboutInfo.description}
              </p>
            )}
          </div>
        </div>
        <div className="mb-8 flex justify-center md:mb-16">
          {video && (
            <video
              controls
              className="h-auto max-w-full rounded-lg object-contain"
            >
              <source src={video.minio_url} type="video/mp4" />
              <track
                kind="captions"
                srcLang="en"
                src="/path-to-captions.vtt"
                label="English captions"
                default
              />
            </video>
          )}
        </div>
        <div
          className={`mb-8 flex flex-col md:mb-16 md:flex-row md:gap-6 ${isRTL ? "rtl" : "ltr"}`}
        >
          <div className="mb-7 flex-1 md:mb-0">
            <h2 className="text-2xl font-bold md:text-5xl">
              {t("information.who")}
            </h2>
          </div>
          <div className="flex-1">
            {aboutInfo?.whoIsEFB && (
              <p className="font-400 text-base md:text-lg">
                {aboutInfo.whoIsEFB}
              </p>
            )}
          </div>
        </div>
        <div className="scrollbar-hide mb-8 flex gap-6 overflow-x-auto max-md:gap-7 md:mb-16 ">
          {statsData.map((stat) => (
            <div
              key={stat.id}
              className="w-[33%] flex-shrink-0 flex-col max-md:w-[80%]"
            >
              <StatisticsCard {...stat} />
            </div>
          ))}
        </div>
        <div className="mb-8 flex flex-col md:mb-16 md:flex-row md:items-center md:justify-between">
          <h2 className="mb-4 text-2xl font-bold md:mb-0 md:text-5xl">
            {t("aboutPage.annual")}
          </h2>
          <div className="flex flex-col justify-end">
            <CustomLink
              to="/aboutUs"
              mobileText={t("aboutPage.ourapproach")}
              desktopText={t("aboutPage.approach")}
            />
          </div>
        </div>
        <div className="w-full">
          <CarouselComponent imagesMd={1} imagesLg={2}>
            {updates.map((update: UpdateType) => (
              <div
                key={`my-updates-${update.id}`}
                className="aspect-9/16 flex min-w-[250px] max-w-[250px] items-stretch md:min-w-[350px] md:max-w-[350px]"
              >
                <UpdateCard
                  update={update}
                  bgColor="#E4E1D8"
                  showYearOnly={true}
                />
              </div>
            ))}
          </CarouselComponent>
        </div>
        <h2 className="mb-6 mt-8 text-2xl font-bold md:mt-16 md:text-5xl">
          {t("aboutPage.values")}
        </h2>
        <div className="mb-8 grid grid-cols-2 gap-6 md:mb-16 md:grid-cols-3">
          {cardData.map((card) => (
            <Card
              key={card.id}
              title={card.title}
              description={card.description}
            />
          ))}
        </div>
        <h2 className="mb-4 text-2xl font-bold md:text-5xl">
          {t("aboutPage.mentioned")}
        </h2>
        <div className="mb-8 md:mb-16">
          <MediaCarousel items={mentions} />
        </div>
        <h2 className="mb-4 text-2xl font-bold md:text-5xl">
          {t("aboutPage.awards")}
        </h2>
        <div className="mb-8 md:mb-16">
          <MediaCarousel items={awards} />
        </div>

        <div className="rounded-lg bg-[#E4E1D8] p-8 md:p-16">
          <h3 className="pb-8 text-xl font-bold md:text-[32px] md:leading-[38.4px]">
            {t("aboutPage.believe")}
          </h3>
          <h4 className="pb-4 text-lg md:text-2xl">
            {t("aboutPage.everyone")}
          </h4>
          <h4 className="pb-8 text-lg md:text-2xl">{t("aboutPage.already")}</h4>
          <Link
            className="text-foreground whitespace-nowrap font-medium underline underline-offset-2"
            to={getLoginURL("")}
          >
            <Button className="px-10 py-4 text-base font-bold">
              {t("aboutPage.join")}
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};
