import { GenericStepHeader } from "@/components/PaymentFlow/Steps/StepHeader";
import useChallengeFormStore from "@/store/challengeStore";
import { useLocation, useNavigate } from "@tanstack/react-router";

export function StepHeader() {
  const { currentStep, prevStep, resetForm } = useChallengeFormStore();

  const { search } = useLocation();
  const navigate = useNavigate();

  const handleModalClose = () => {
    resetForm();
    if (search.response_code) {
      navigate({ to: "/" });
    } else {
      navigate({ to: search.backUrl ?? "/" });
    }
  };

  const steps = ["Cause", "Goal", "Details"];

  return (
    <GenericStepHeader
      steps={steps}
      currentStep={currentStep}
      handleModalClose={handleModalClose}
      prevStep={prevStep}
    />
  );
}
