import ChallengeCheckout from "@/pages/challenge-checkout";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const checkoutSearchSchema = z.object({
  program_id: z.string().optional(),
  donation_id: z.string().optional(),
  currency: z.string().optional(),
  response_message: z.string().optional(),
});

export const Route = createFileRoute("/challenge-checkout/$challengeId")({
  component: ChallengeCheckout,
  validateSearch: (search) => checkoutSearchSchema.parse(search),
});
