export const VisaIcon = () => {
  return (
    <svg
      width="39"
      height="12"
      viewBox="0 0 39 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8483 11.792L15.8188 0.231984H18.9694L16.9984 11.792H13.8483ZM28.3672 0.485585C27.4631 0.158054 26.5079 -0.00629707 25.5463 0.00018449C22.4369 0.00018449 20.2464 1.56611 20.2275 3.81087C20.2104 5.46996 21.7916 6.39533 22.9854 6.94738C24.2104 7.51359 24.6221 7.87454 24.6162 8.38C24.6085 9.15381 23.6377 9.50709 22.7335 9.50709C21.4737 9.50709 20.8049 9.33018 19.771 8.9014L19.3658 8.71795L18.924 11.3024C19.6589 11.6245 21.0184 11.904 22.4292 11.9182C25.7374 11.9182 27.8848 10.3705 27.9096 7.97362C27.9214 6.66015 27.0839 5.66045 25.2673 4.8365C24.1673 4.30569 23.4938 3.94591 23.5008 3.40507C23.5008 2.92557 24.0712 2.41247 25.3033 2.41247C26.1105 2.3944 26.9127 2.54506 27.6583 2.85482L27.9402 2.9881L28.3672 0.485585ZM36.4616 0.231984H34.0375C33.2867 0.231984 32.7246 0.437243 32.3949 1.18628L27.7356 11.733H31.052C31.052 11.733 31.5828 10.3305 31.7049 10.0226L35.6784 10.0273C35.771 10.426 36.0558 11.733 36.0558 11.733H39.0001L36.4616 0.231984ZM32.6073 7.6634C32.8674 6.99811 33.8612 4.43603 33.8612 4.43603C33.8423 4.4667 34.1195 3.76721 34.2782 3.33371L34.4911 4.3293C34.4911 4.3293 35.0939 7.0854 35.2195 7.6634H32.6073ZM11.1747 0.231984L8.10072 8.08746L7.77336 6.4909C7.20126 4.65074 5.41831 2.65723 3.4248 1.6593L6.23578 11.733L9.55749 11.7294L14.5006 0.231984H11.1594"
        fill="url(#paint0_linear_5161_4570)"
      />
      <path
        d="M5.09346 0.231854H0.0406924L0 0.47189C3.94809 1.42795 6.56027 3.73759 7.6449 6.51199L6.5414 1.20383C6.35089 0.473079 5.79886 0.254853 5.11529 0.229492"
        fill="url(#paint1_linear_5161_4570)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5161_4570"
          x1="21.2124"
          y1="0"
          x2="21.2124"
          y2="11.9182"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5161_4570"
          x1="3.82245"
          y1="0.229492"
          x2="3.82245"
          y2="6.51199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
      </defs>
    </svg>
  );
};
