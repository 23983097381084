import api from "./api-client"
import { useQuery } from "@tanstack/react-query"

export const getHomepage = async (params?: Record<string, string>) => {
    const queryParams = { ...params };
    try {
        const response = await api.get('/homepage/',  {
            params: queryParams,
        });
        return response.data.results;
      } catch (error) {
        console.error('Failed to fetch homepage data:', error);
        throw error;
      }
}

const homepageKeys = {
    homepage: (params?: Record<string, string>) => ['homepage', params],
};

export const useHomepage = (params?: Record<string, string>,)=>{
    return useQuery({
        queryKey:homepageKeys.homepage(params),
        queryFn:() => getHomepage(params),
    })
};
