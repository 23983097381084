import { useField } from "formik";
import { Input } from "@/components/ui/input";
import LockIcon from "@/assets/icons/LockIcon";
import { ChangeEvent } from "react";
import { isRTLLanguage } from "@/utils/helpers";
import { useTranslation } from "react-i18next";

interface CustomInputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string | React.ReactNode;
  name: string;
  id?: string;
  placeholder?: string;
  optional?: boolean;
  readOnly?: boolean;
  isSecure?: boolean;
  onChangeInput?: (e: ChangeEvent<HTMLInputElement>) => void;
  format?: (
    _: ChangeEvent<HTMLInputElement>,
    set: (value: any) => void,
  ) => void;
  clean?: (_: string) => string;
  error?: string;
  disableFlip?: boolean;
}

const CustomInputField: React.FC<CustomInputFieldProps> = ({
  label,
  isSecure,
  format,
  clean,
  onChangeInput,
  error,
  disableFlip,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { i18n } = useTranslation();
  const errorClass =
    (error && error !== "") || (meta.touched && meta.error)
      ? "border-[#F04438]"
      : "";
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (format) {
      format(e, helpers.setValue);
    } else {
      field.onChange(e);
    }
  };

  return (
    <div
      dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
      className="flex flex-col"
    >
      <label
        htmlFor={props.id || props.name}
        className="text-foreground mb-2 block text-start text-xs font-bold md:text-sm"
      >
        {label}
        {props.optional && (
          <span className="text-foreground opacity-50"> (optional)</span>
        )}
      </label>
      <div className={`relative block w-full ${errorClass}`}>
        <Input
          className={`block w-full pr-10 ${errorClass} ${disableFlip ? "pr-4 rtl:text-right" : "rtl:pr-4"}`}
          {...field}
          {...{
            ...props,
            optional: props.optional?.toString(),
          }}
          readOnly={props.readOnly}
          onChange={onChangeInput ?? handleChange}
          clean={clean}
          dir={disableFlip ? "ltr" : ""}
        />
        {isSecure && (
          <LockIcon className="absolute right-3 top-1/2 -translate-y-1/2 transform rtl:left-3 rtl:right-auto" />
        )}
      </div>
      {(error && error !== "") || (meta.touched && meta.error) ? (
        <div className="mt-2 text-start text-sm text-[#F04438]">
          {error ?? meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default CustomInputField;
