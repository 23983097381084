import { useGetAuthenticatedUser } from "@/api/user";
import ProfileForm from "./modals/ProfileForm";
import ProfileSkeleton from "../../components/profile-skeleton";

export const programTitleFormatter = (title: string) => {
  const dots = title.split(" ").length > 3 ? "..." : "";
  return title.split(" ").splice(0, 3).join(" ") + dots;
};

export const Profile = () => {
  const { data: user, isLoading } = useGetAuthenticatedUser();
  return (
    <section className="flex flex-col p-4 md:p-6">
      <div className="flex w-full flex-col items-center justify-between md:flex-row">
        <div className="bg-form flex max-h-full w-full flex-col items-center justify-center">
          {isLoading ? <ProfileSkeleton /> : <ProfileForm user={user} />}
        </div>
        <div className="flex gap-16"></div>
      </div>
    </section>
  );
};
