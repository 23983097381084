import api from "./api-client";
import { useQuery } from "@tanstack/react-query";
import { paramsToKey } from "@/utils/helpers";
import { CategoryType } from "@/types/category";

export const categoryKeys = {
  all: (params?: Record<string, string>) =>
    ["categories", ...paramsToKey(params)] as const,
  program: (programId: string) => [...categoryKeys.all(), programId] as const,
};

const getCategories = async (params?: Record<string, string>) => {
  const queryParams = { ...params };
  const res = await api.get("/category/", { params: queryParams });
  return res.data.results as CategoryType[];
};

export const useGetCategories = (params?: Record<string, string>) => {
  return useQuery({
    queryKey: categoryKeys.all(params),
    queryFn: () => getCategories(params),
  });
};

export const useGetCategoriesOptions = (params?: Record<string, string>) => {
  const { data: categories } = useGetCategories(params);
  return (
    categories?.map((c) => ({
      value: c.title,
      label: c.title,
    })) ?? []
  );
};
