import { UpdateType } from "@/types/programs";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import { useTranslation } from "react-i18next";
import preParsePostFormat from "dayjs/plugin/preParsePostFormat";
import { UpdateCard } from "./update-card";

dayjs.extend(preParsePostFormat);
dayjs.extend(isYesterday);
dayjs.extend(isToday);

dayjs.locale("ar");
interface UpdateCardProps {
  bgColor: string;
  updates: UpdateType[];
}

const UpdatesSection = ({ bgColor, updates }: UpdateCardProps) => {
  const { t, i18n } = useTranslation();

  return (
    <section className="my-5 w-full pb-4 md:my-8 md:pb-0" id="updates">
      <h2 className="mx-[16px] mb-8 text-2xl font-bold md:mx-[24px] md:text-4xl">
        {t("header.updates")}
      </h2>
      <ul className="scrollbar-hide flex gap-4 overflow-x-auto md:gap-[24px]">
        {!updates?.length && (
          <li
            className={`col-span-4 mx-[16px] w-full space-y-16 font-normal md:mx-[24px]`}
          >
            <p className="text-2xl leading-tight sm:text-2xl">
              {t("update.no_updates")}
            </p>
          </li>
        )}
        {updates.map((update) => (
          <li
            key={update.id}
            className={`
              m-0 [&>div]:h-full ${i18n.language === "en" ? "first:pl-4 last:pr-4  md:first:pl-[24px] md:last:pr-[24px]" : "first:pr-4 last:pl-4 md:first:pr-[24px] md:last:pl-[24px]"}`}
          >
            <UpdateCard update={update} bgColor={bgColor} />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default UpdatesSection;
