import api from "@/api/api-client";
import i18n from "@/i18n";
import useUserStore from "@/store/userStore";
import { User } from "@/types/auth";
import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

export const userKeys = {
  logged: ["logged"] as const,
};

const getAuthenticatedUser = async () => {
  const res = await api.get<User>("/auth/logged/");
  const data = res.data;
  const lang = data.preferred_language;
  if (!localStorage.getItem("lang") && lang) {
    localStorage.setItem("lang", lang.toLowerCase());
    i18n.changeLanguage(lang.toLowerCase());
  }
  return data;
};

const logoutUser = async () => {
  const res = await api.post("/auth/logout/");
  return res.data;
};

export const useGetAuthenticatedUser = () => {
  const { token } = useUserStore();
  return useQuery({
    queryKey: userKeys.logged,
    queryFn: getAuthenticatedUser,
    enabled: !!token,
  });
};

export const useLogoutUser = (options?: UseMutationOptions<unknown, Error>) => {
  return useMutation({ mutationFn: logoutUser, ...options });
};
