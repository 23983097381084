import { SuggestedAmountType } from "@/types/programs";
import api from "./api-client";
import { queryOptions } from "@tanstack/react-query";
import { paramsToKey } from "@/utils/helpers";

export const suggestedAmountsKeys = {
  all: (params?: Record<string, string>) =>
    ["suggested_amounts", ...paramsToKey(params)] as const,
  program: (params?: Record<string, string>) =>
    [...suggestedAmountsKeys.all(), ...paramsToKey(params)] as const,
};

const getSuggestedAmounts = async (params?: Record<string, string>) => {
  const queryParams = { ...params };
  const res = await api.get("/suggestedamount/", { params: queryParams });
  return res.data.results as SuggestedAmountType[];
};

export const suggestedAmountsQueryOptions = (params?: Record<string, string>) =>
  queryOptions({
    queryKey: suggestedAmountsKeys.program(params),
    queryFn: () => getSuggestedAmounts(params),
  });
