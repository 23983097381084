import { Check } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { useMemo } from "react";

type SuccessPaymentProps = {
  readonly handleOnclickCreateAccount: () => void;
};
export default function SuccessPayment({
  handleOnclickCreateAccount,
}: SuccessPaymentProps) {
  const { t, i18n } = useTranslation();
  const createAccountReasons = useMemo(() => {
    return [
      t("paymentState.createAccount1"),
      t("paymentState.createAccount2"),
      t("paymentState.createAccount3"),
      t("paymentState.createAccount4"),
    ];
  }, [i18n.language]);

  return (
    <>
      <div className="flex flex-col gap-4">
        <p className="text-foreground font-medium">
          {t("paymentState.createAccountPrompt")}
        </p>
        <ul className="flex flex-col gap-2.5">
          {createAccountReasons.map((reason) => (
            <li
              key={reason}
              className="text-foreground flex items-center gap-2 font-medium"
            >
              <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-[#111111] ">
                <Check className="stroke-foreground h-3.5 w-3.5 stroke-2" />
              </div>
              <p>{reason}</p>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <Button
          className="px-10 py-6 font-bold"
          onClick={handleOnclickCreateAccount}
        >
          {t("paymentState.createAccountButton")}
        </Button>
      </div>
    </>
  );
}
