import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";
import { isRTLLanguage } from "@/utils/helpers";

type ModalProps = {
  child?: React.ReactNode;
  title: string;
  trigger?: string;
  button: React.ReactNode;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  buttonClass?: string;
  buttonVariant?:
    | "bold"
    | "link"
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | "custom"
    | "transparent"
    | "transparent_underline"
    | "black"
    | "underline";
  isTrigger?: boolean;
  onClose?: (event: Event) => void;
};

export default function ProfileModal({
  child,
  title,
  trigger,
  button,
  open,
  setOpen,
  buttonClass,
  buttonVariant,
  isTrigger,
  onClose,
}: Readonly<ModalProps>) {
  const { t, i18n } = useTranslation();
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {isTrigger && (
        <DialogTrigger>
          <Button
            variant={buttonVariant}
            size="lg"
            className={cn("w-full", buttonClass)}
            type="button"
          >
            {t(`profile.${trigger}`)}
          </Button>
        </DialogTrigger>
      )}
      <DialogContent
        dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
        className="mt-48 flex h-screen w-full flex-col items-start justify-between justify-items-end overflow-auto rounded-t-2xl p-8 pb-56 lg:mt-0 lg:h-auto lg:w-[1041px] lg:gap-32 lg:rounded-2xl lg:p-16 rtl:items-end"
        onCloseAutoFocus={onClose}
      >
        <div className="md:align-center flex h-full w-full flex-1 flex-col gap-4 text-start md:gap-8">
          <h3 className="text-[20px] font-bold md:text-3xl md:text-[32px]">
            {t(`profile.${title}`)}
          </h3>
          {child}
        </div>
        <div className="flex w-full flex-col justify-center gap-6 self-center md:flex-row-reverse">
          {button}
        </div>
      </DialogContent>
    </Dialog>
  );
}
