import { Card } from "@/components/ui/card";
import { Testimonial } from "@/types/programs";
import { useTranslation } from "react-i18next";
import { CarouselComponent } from "./CarouselComponent";
import clsx from "clsx";

type TestimonialsProps = {
  readonly testimonials: Testimonial[];
  readonly size: string;
  readonly dynamicColor: string;
  readonly isProgramDetail?: boolean;
};

const Testimonials = ({
  testimonials,
  size,
  dynamicColor,
  isProgramDetail,
}: TestimonialsProps) => {
  const { t } = useTranslation();

  const renderTestimonial = (testimonial: Testimonial) => (
    <div key={testimonial.name}>
      <Card
        className="flex h-[380px] w-[313px] flex-col items-start justify-start rounded-2xl border-0 p-4 md:h-[380px] md:w-[383px] md:p-6"
        style={{ backgroundColor: dynamicColor }}
      >
        <div>
          {testimonial?.picture?.[0] ? (
            <img
              src={testimonial.picture[0].minio_url}
              className="h-14 w-14 rounded-full object-cover"
              alt="testifier picture"
            />
          ) : (
            <div className="h-14 w-14 rounded-full bg-black"></div>
          )}
        </div>

        <div className="py-6">
          <p className="h-[170px] overflow-y-scroll text-base md:text-lg">
            "{testimonial.testimonial}"
          </p>
        </div>

        <div className="mt-auto w-full pt-4 text-xs md:text-sm">
          <h1 className="font-bold">{testimonial.name}</h1>
          <h1>
            {testimonial.description}
            {", "} {testimonial.location}
          </h1>
        </div>
      </Card>
    </div>
  );

  if (!testimonials.length) {
    return null;
  }

  return (
    <section
      className="my-2 flex w-full flex-col gap-4 md:my-6 md:gap-6"
      id="beneficiaries"
    >
      <div className="flex items-center justify-between">
        <h2
          className={clsx(
            `md:text-${size} text-2xl font-bold !leading-[120%]`,
            {
              "mx-[16px] md:mx-[24px]": isProgramDetail,
            },
          )}
        >
          {size === "5xl"
            ? t("testimonials.first_title")
            : t("testimonials.second_title")}
        </h2>
      </div>
      <CarouselComponent
        imagesMd={2}
        imagesLg={4}
        isProgramDetail={isProgramDetail}
      >
        {testimonials.map((testimonial) => renderTestimonial(testimonial))}
      </CarouselComponent>
    </section>
  );
};

export default Testimonials;
