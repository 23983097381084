import useUserStore from "@/store/userStore";
import api from "./api-client";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  ChallengeDonationFormType,
  ChallengeDonationType,
  ChallengeType,
} from "@/types/challenge";
import { paramsToKey } from "@/utils/helpers";

export const challengeKeys = {
  all: (params?: Record<string, string>) =>
    ["challenges", ...paramsToKey(params)] as const,
  challenge: (challengeId: string, params?: Record<string, string>) =>
    [...challengeKeys.all(), challengeId, ...paramsToKey(params)] as const,
};

export const createChallengeUpdate = async (
  challengeId: string,
  data: Record<string, any>,
) => {
  const response = await api.post(
    `/community/challenges/${challengeId}/updates/`,
    data,
  );
  return response.data;
};

export const useCreateChallengeUpdate = (
  options?: UseMutationOptions<any, Error, { challengeId: string; data: any }>,
) =>
  useMutation({
    mutationFn: ({ challengeId, data }) =>
      createChallengeUpdate(challengeId, data),
    ...options,
  });

export const createChallenge = async (data: Record<string, any>) => {
  const token = useUserStore.getState().token;
  const response = await api.post("/community/challenges/", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

export const updateChallenge = async (
  data: Record<string, any>,
  challengeId: string,
) => {
  const response = await api.patch(
    `/community/challenges/${challengeId}/`,
    data,
  );

  return response.data;
};

export const useSubmitChallengeForm = (
  options?: UseMutationOptions<any, Error, { data: any }>,
) =>
  useMutation({
    mutationFn: ({ data }) => createChallenge(data),
    ...options,
  });

export const useUpdateChallenge = (
  options?: UseMutationOptions<any, Error, { data: any; challengeId: string }>,
) =>
  useMutation({
    mutationFn: ({ data, challengeId }) => updateChallenge(data, challengeId),
    ...options,
  });

interface Challenges {
  my_challenges: ChallengeType[];
  other_challenges: ChallengeType[];
}

const fetchChallenge = async (
  challengeId?: string,
  params?: Record<string, string>,
): Promise<ChallengeType> => {
  const queryParams = { ...params };
  const response = await api.get(`/community/challenges/${challengeId}/`, {
    params: queryParams,
  });
  return response.data;
};

export const useChallenge = (
  challengeId: string,
  params?: Record<string, string>,
  options?: UseQueryOptions<ChallengeType, Error>,
) => {
  return useQuery({
    queryKey: challengeKeys.challenge(challengeId, params),
    queryFn: () => fetchChallenge(challengeId, params),
    ...options,
  });
};

const fetchChallenges = async (
  params?: Record<string, string>,
): Promise<Challenges> => {
  const queryParams = { ...params };
  const response = await api.get(`/community/challenges/`, {
    params: queryParams,
  });
  return response.data;
};

export const useChallenges = (
  params?: Record<string, string>,
  options?: UseQueryOptions<Challenges, Error>,
) => {
  return useQuery({
    queryKey: challengeKeys.all(params),
    queryFn: () => fetchChallenges(params),
    ...options,
  });
};

interface ChallengeCloseData {
  state?: string;
  end_date?: string;
}

export const useCloseChallenge = (
  options?: UseMutationOptions<
    any,
    Error,
    { challengeId: string; data: ChallengeCloseData }
  >,
) =>
  useMutation({
    mutationFn: ({ challengeId, data }) => updateChallenge(data, challengeId),
    ...options,
  });

const postChallengeDonation = async (data: ChallengeDonationFormType) => {
  const res = await api.post(
    `/community/challenges/${data.challenge}/donations/`,
    data,
  );
  return res.data;
};

export const usePostChallengeDonation = (
  options?: UseMutationOptions<
    ChallengeDonationType,
    Error,
    { data: ChallengeDonationFormType }
  >,
) =>
  useMutation({
    mutationFn: ({ data }) => postChallengeDonation(data),
    ...options,
  });
