import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { Link } from "@tanstack/react-router";

type NavLinksProps = {
  sections: { id: string; labelKey: string }[];
  activeSection: string;
  scrollToSection: (sectionId: string) => void;
  isChallenge: boolean;
};

const NavLinks: FC<NavLinksProps> = ({
  sections,
  activeSection,
  scrollToSection,
  isChallenge,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mx-1 flex gap-1 overflow-x-auto md:mx-4 md:gap-4 [&>a]:my-auto [&>a]:pe-2">
      {isChallenge && (
        <Link
          aria-label="Go to create challenge page"
          to="/donor-profile"
          search={{ page: "challenges" }}
        >
          <Button className="h-14 bg-[#E2DDD7] px-6 py-2.5 font-[650] hover:bg-[#E2DDD7]">
            <div>{t("challenge.backToChallenges")}</div>
          </Button>
        </Link>
      )}

      {sections.map(({ id, labelKey }) => (
        <a
          key={id}
          href={`#${id}`}
          className={activeSection === id ? "font-[650]" : "font-[350]"}
          onClick={(e) => {
            e.preventDefault();
            scrollToSection(id);
          }}
        >
          {t(labelKey)}
        </a>
      ))}
    </div>
  );
};

export default NavLinks;
