import { Skeleton } from "@/components/ui/skeleton";

export const ProgramCardSkeleton = () => {
  return (
    <div className="flex flex-row gap-8">
      {Array.from({ length: 6 }).map((_, i) => {
        const key = `category-skeleton-${i}`;
        return (
          <Skeleton
            key={key}
            className="aspect-9/16 min-w-[250px] max-w-[250px] items-stretch bg-[#c4c0bd] md:min-w-[350px] md:max-w-[350px]"
          />
        );
      })}
    </div>
  );
};
