import { ProgramsPage } from "@/pages/programs";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const programsSearchSchema = z.object({ category: z.string().optional() });

export const Route = createFileRoute("/programs")({
  component: ProgramsPage,
  validateSearch: (search) => programsSearchSchema.parse(search),
});
