import { DialogClose } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import ProfileModal from "@/components/Profile/Modal";
import DeleteReasonModal from "./DeleteReaonModal";
import { useDeleteProfile } from "@/api/profile";
import { Trans, useTranslation } from "react-i18next";
import useUserStore from "@/store/userStore";

export default function DeleteModal() {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openReasonModal, setOpenReasonModal] = useState<boolean>(false);
  const { mutate: deleteUser } = useDeleteProfile({
    onSuccess: () => {
      useUserStore.getState().clearToken();
      window.location.href = "/thankYou";
    },
  });
  const { t } = useTranslation();

  const handleDelete = () => {
    setOpenDeleteModal(false);
    setOpenReasonModal(true);
  };

  return (
    <>
      <ProfileModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        isTrigger={true}
        trigger="delete"
        buttonVariant="transparent"
        buttonClass="flex-1"
        title="deleteYourAccount"
        button={
          <>
            <Button
              size="lg"
              className="min-w-56"
              type="button"
              onClick={handleDelete}
            >
              {t("profile.confirm")}
            </Button>
            <DialogClose>
              <Button size="lg" variant="underline" className="min-w-56">
                {t("profile.cancel")}
              </Button>
            </DialogClose>
          </>
        }
        child={
          <div>
            <p className="max-w-[615px] text-base font-normal leading-7">
              {<Trans i18nKey="profile.deleteText" />}
            </p>
          </div>
        }
      />
      <DeleteReasonModal
        deleteUser={deleteUser}
        setOpen={setOpenReasonModal}
        open={openReasonModal}
      />
    </>
  );
}
