"use client";

import * as React from "react";
import { format, isToday } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import useChallengeFormStore from "@/store/challengeStore";
import { Trans, useTranslation } from "react-i18next";

type DatePickerWithRangeProps = {
  readonly start?: string;
};

export function DatePickerWithRange({
  className,
  start,
}: React.HTMLAttributes<HTMLDivElement> & DatePickerWithRangeProps) {
  const { date, setDate, setIsFullDateRangeSet } = useChallengeFormStore();
  const { t } = useTranslation();
  React.useEffect(() => {
    if (date?.from && date.to) {
      const currentDate = new Date();
      const startDate = new Date(start ?? "");
      if (
        start &&
        format(date.from, "yyyy-MM-dd") !== format(startDate, "yyyy-MM-dd")
      ) {
        date.from = startDate;
        setDate({ from: date.from, to: date.to });
      } else if (
        !start &&
        format(date.from, "yyyy-MM-dd") < format(currentDate, "yyyy-MM-dd")
      ) {
        date.from = currentDate;
        setDate({ from: date.from, to: date.to });
      }
      setIsFullDateRangeSet(true);
    } else {
      setIsFullDateRangeSet(false);
    }
  }, [date]);

  const getFormattedDate = (date: any) => {
    let firstDay;
    if (date.to) {
      if (isToday(date.from)) {
        firstDay = t("challenge.today");
      } else {
        firstDay = format(date.from, "dd LLL y");
      }
      return (
        <Trans
          i18nKey={"challenge.fromTo"}
          values={{ start: firstDay, end: format(date.to, "dd LLL y") }}
        />
      );
    }
    return format(date.from, "LLL dd, y");
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-full justify-start text-left font-normal",
              "text-base text-[#4F4754]",
              "border",
              "border-[#CCC2BF] h-[58px]",
              "hover:bg-transparent",
              className,
            )}
          >
            <div
              className={cn(
                !date,
                "flex items-center w-full text-[#4F4754] font-[350]",
              )}
            >
              <div
                className={cn(
                  "flex items-center justify-center bg-[#E2DDD7] py-2 px-6 rounded-md mr-2 rtl:ml-2 rtl:mr-0",
                  "stroke-foreground",
                )}
              >
                <CalendarIcon className="h-5 w-5 stroke-[#232329]" />
              </div>
              {date?.from ? (
                getFormattedDate(date)
              ) : (
                <span className="text-[#4F4754]">
                  {t("challenge.pickDate")}
                </span>
              )}
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
