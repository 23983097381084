import api from "./api-client";
import { queryOptions } from "@tanstack/react-query";
import { paramsToKey } from "@/utils/helpers";
import { DeliverySlotType } from "@/types/delivery";

export const deliverySlotsKeys = {
  all: (params?: Record<string, string>) =>
    ["delivery_slots", ...paramsToKey(params)] as const,
  delivery: (deliveryId: string, params?: Record<string, string>) =>
    [...deliverySlotsKeys.all(), deliveryId, ...paramsToKey(params)] as const,
};

const getDeliverySlots = async (
  deliveryId: string,
  params?: Record<string, string>,
) => {
  const queryParams = { ...params, delivery: deliveryId };
  const res = await api.get("/deliveryslot/", { params: queryParams });
  return res.data.results as DeliverySlotType[];
};

export const deliverySlotsQueryOptions = (
  deliveryId: string,
  params?: Record<string, string>,
) =>
  queryOptions({
    queryKey: deliverySlotsKeys.delivery(deliveryId, params),
    queryFn: () => getDeliverySlots(deliveryId, params),
  });
