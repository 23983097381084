import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { DateRange } from "react-day-picker";

interface FormState {
  currentStep: number;
  totalSteps: number;
  currentProgram: string;
  activeTab: string;
  selectedAmount: number;
  selectedOther: boolean;
  selectedAmountUsd: number;
  selectedAmountEgp: number;
  InputAmount: number;
  amountUsd: number;
  amountEgp: number;
  programMessage: string;
  programMealPriceUSD: number;
  programMealPriceEGP: number;
  date: DateRange | undefined;
  isFullDateRangeSet: boolean;
  challengeName: string;
  challengeDescription: string;
  coverImage: File | null;
  programName: string;
  programImage: string;
  challengeId: string;
  programId: string;
  preview: boolean;
  is_owner: boolean;
  is_supporter: boolean;
}

interface FormActions {
  setCurrentStep: (step: number) => void;
  nextStep: () => void;
  prevStep: () => void;
  resetForm: () => void;
  validateForm: () => boolean;
  setProgram: (program_id: string) => void;
  setActiveTab: (tab: string) => void;
  setSelectedAmount: (amount: number) => void;
  setSelectedAmountUsd: (amount: number) => void;
  setSelectedAmountEgp: (amount: number) => void;
  setInputAmount: (amount: number) => void;
  setAmountUsd: (amount: number) => void;
  setAmountEgp: (amount: number) => void;
  setProgramMessage: (message: string) => void;
  setProgramMealPriceUSD: (price: number) => void;
  setProgramMealPriceEGP: (price: number) => void;
  setSelectedOther: (isSelected: boolean) => void;
  setDate: (range: DateRange | undefined) => void;
  setIsFullDateRangeSet: (bool: boolean) => void;
  setChallengeName: (name: string) => void;
  setChallengeDescription: (description: string) => void;
  setCoverImage: (image: File | null) => void;
  removeCoverImage: () => void;
  setProgramName: (name: string) => void;
  setProgramImage: (image: string) => void;
  setChallengeId: (id: string) => void;
  setProgramId: (id: string) => void;
  setPreview: (bool: boolean) => void;
  setIsOwner: (bool: boolean) => void;
  setIsSupporter: (bool: boolean) => void;
}

type FormStore = FormState & FormActions;

const useChallengeFormStore = create<FormStore>()(
  persist(
    (set, get) => ({
      currentStep: 1,
      totalSteps: 4,
      currentProgram: "",
      activeTab: "",
      selectedAmount: 0,
      selectedAmountUsd: 0,
      selectedAmountEgp: 0,
      programMessage: "",
      programMealPriceUSD: 0,
      programMealPriceEGP: 0,
      programName: "",
      programImage: "",
      selectedOther: false,
      date: undefined,
      isFullDateRangeSet: false,
      InputAmount: 0,
      amountUsd: 0,
      amountEgp: 0,
      challengeName: "",
      challengeDescription: "",
      coverImage: null,
      challengeId: "",
      programId: "",
      preview: false,
      is_owner: false,
      is_supporter: true,

      setIsOwner: (bool: boolean) => set({ is_owner: bool }),
      setIsSupporter: (bool: boolean) => set({ is_supporter: bool }),
      setPreview: (bool: boolean) => set({ preview: bool }),
      setChallengeId: (id: string) => set({ challengeId: id }),
      setProgramId: (id: string) => set({ programId: id }),

      setProgramName: (name: string) => set({ programName: name }),
      setProgramImage: (image: string) => set({ programImage: image }),
      setCoverImage: (image: File | null) => set({ coverImage: image }),
      removeCoverImage: () => set({ coverImage: null }),
      setChallengeName: (name: string) => set({ challengeName: name }),
      setChallengeDescription: (description: string) =>
        set({ challengeDescription: description }),

      setIsFullDateRangeSet: (bool: boolean) =>
        set({ isFullDateRangeSet: bool }),
      setDate: (range) => set({ date: range }),
      setSelectedOther: (isSelected: boolean) =>
        set({ selectedOther: isSelected }),

      setProgramMessage: (message: string) => set({ programMessage: message }),
      setProgramMealPriceUSD: (price: number) =>
        set({ programMealPriceUSD: price }),
      setProgramMealPriceEGP: (price: number) =>
        set({ programMealPriceEGP: price }),

      setSelectedAmount: (amount: number) => set({ selectedAmount: amount }),
      setSelectedAmountUsd: (amount: number) =>
        set({ selectedAmountUsd: amount }),
      setSelectedAmountEgp: (amount: number) =>
        set({ selectedAmountEgp: amount }),
      setInputAmount: (amount: number) => set({ InputAmount: amount }),
      setAmountUsd: (amount: number) => set({ amountUsd: amount }),
      setAmountEgp: (amount: number) => set({ amountEgp: amount }),
      setActiveTab: (tab: string) => set({ activeTab: tab }),
      setProgram: (program_id: string) => set({ currentProgram: program_id }),
      setCurrentStep: (step: number) => set({ currentStep: step }),

      nextStep: () =>
        set((state) => ({
          currentStep: Math.min(state.currentStep + 1, state.totalSteps),
        })),

      prevStep: () =>
        set((state) => ({
          currentStep: Math.max(state.currentStep - 1, 1),
        })),

      resetForm: () =>
        set({
          currentStep: 1,
          totalSteps: 4,
          currentProgram: "",
          activeTab: "",
          selectedAmount: 0,
          programMessage: "",
          programMealPriceUSD: 0,
          programMealPriceEGP: 0,
          programName: "",
          programImage: "",
          selectedOther: false,
          date: undefined,
          isFullDateRangeSet: false,
          InputAmount: 0,
          challengeName: "",
          challengeDescription: "",
          coverImage: null,
          challengeId: "",
          programId: "",
          preview: false,
          is_owner: false,
          is_supporter: true,
          selectedAmountUsd: 0,
          selectedAmountEgp: 0,
          amountUsd: 0,
          amountEgp: 0,
        }),

      validateForm: () => {
        return true;
      },

      hasCompletedAllSteps: () => {
        const { currentStep, totalSteps, validateForm } = get();
        return currentStep === totalSteps - 1 && validateForm();
      },
    }),
    {
      name: "form-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export default useChallengeFormStore;
