import { AppShell } from "@/components/Navbar";
import { Button } from "@/components/ui/button";
import { bgOverlayStyle } from "@/utils/helpers";
import { Link } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

export const ThankYou = () => {
  const { t } = useTranslation();
  return (
    <div
      className="dark relative -mb-16 h-screen !bg-cover"
      style={{ background: bgOverlayStyle("/thank-you-page.jpeg") }}
    >
      <AppShell theme="dark" navbarClass="absolute w-full top-0" />
      <div className="flex h-full w-full flex-col items-center justify-center gap-16">
        <h2 className="text-2xl font-bold md:text-5xl">
          {t("profile.hopeToSeeYou")}
        </h2>
        <Link to={"/"} className="font-semibold last:font-light">
          <Button size={"lg"}>{t("profile.returnHomepage")}</Button>
        </Link>
      </div>
    </div>
  );
};
