import { PolicyPage } from "@/pages/information/policy/policy";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const policySchema = z.object({
  type: z.string(),
});

export const Route = createFileRoute("/policy")({
  component: PolicyPage,
  validateSearch: (search) => policySchema.parse(search),
});
