import { ImpactStatistics } from "@/types/impact_statistics";
import api from "./api-client";
import { useQuery } from "@tanstack/react-query";

export const getImpactStatistics = async (params?: Record<string, string>) => {
  const queryParams = { ...params };
  try {
    const response = await api.get("/impact-statistics/", {
      params: queryParams,
    });
    return response.data as ImpactStatistics;
  } catch (error) {
    console.error("Failed to fetch impact statistics:", error);
    throw error;
  }
};

const impactStatisticsKeys = {
  impactStatistics: (params?: Record<string, string>) => [
    "impactStatistics",
    params,
  ],
};

export const useImpactStatistics = (params?: Record<string, string>) => {
  return useQuery({
    queryKey: impactStatisticsKeys.impactStatistics(params),
    queryFn: () => getImpactStatistics(params),
  });
};
