import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Dispatch, SetStateAction, useState } from "react";
import Cropper, { Area } from "react-easy-crop";

import { cropImage } from "../../../../../utils/cropUtils";
import { useTranslation } from "react-i18next";
type CropperProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  image: string;
  onComplete: (croppedImage: Promise<string | undefined>) => void;
  containerStyle: string;
  [key: string]: any;
};

export default function ImageCropper({
  open,
  setOpen,
  image,
  onComplete,
  containerStyle,
  ...props
}: Readonly<CropperProps>) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
  const { t } = useTranslation();
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="mt-48 flex h-screen flex-col items-start justify-between overflow-auto rounded-t-2xl p-8 pb-56 md:mt-0 md:h-auto md:w-[520px] md:gap-32 md:rounded-2xl md:p-16">
        <div className={containerStyle}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={(_, croppedAreaPixels) => {
              setCroppedAreaPixels(croppedAreaPixels);
            }}
            onZoomChange={setZoom}
            {...props}
          />
        </div>
        <div className="flex w-full flex-col gap-2">
          <Button
            className="w-full"
            size="lg"
            onClick={() =>
              onComplete(
                cropImage(image, croppedAreaPixels, (err) =>
                  console.error(err),
                ),
              )
            }
          >
            {t("profile.finish")}
          </Button>
          <Button
            variant="outline"
            className="w-full"
            size="lg"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t("profile.cancel")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
