import { FC, ReactElement, JSXElementConstructor } from "react";
import useActiveSection from "@/hooks/useActiveSection";
import NavLinks from "@/components/Helpers/NavLinks";

type SectionNavigatorProps = {
  sections: { id: string; labelKey: string }[];
  isChallenge: boolean;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
};

const SectionNavigator: FC<SectionNavigatorProps> = ({
  sections,
  children,
  isChallenge,
}) => {
  const { activeSection, scrollToSection } = useActiveSection(
    sections.map((section) => section.id),
  );

  return (
    <section className="w-full">
      <div className="bg-background sticky inset-0 z-[5] flex w-full justify-between p-4 md:px-0 md:py-6">
        <NavLinks
          sections={sections}
          activeSection={activeSection}
          scrollToSection={scrollToSection}
          isChallenge={isChallenge}
        />
      </div>
      {children}
    </section>
  );
};

export default SectionNavigator;
