import { ProfileFormType, ProfileType, User } from "@/types/auth";
import api from "./api-client";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import useUserStore from "@/store/userStore";

const editProfile = async (data: ProfileFormType) => {
  const res = await api.patch(`/user/`, data);

  return res.data;
};

const editImage = async (data: FormData) => {
  const token = useUserStore.getState().token;
  if (!token) {
    throw new Error("No token found");
  }
  const res = await fetch(`${api.defaults.baseURL}/profile/image/`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  });

  if (!res.ok) {
    throw new Error("Error updating image");
  }

  const profile_data = await res.json();
  return profile_data.profile;
};

const deleteImage = async () => {
  const res = await api.delete(`/profile/remove_image/`);

  return res.data;
};

const deleteCreditCard = async (id: number) => {
  const res = await api.delete(`/payment/register-card/?id=${id}`);

  return res.data;
};

const deleteProfile = async () => {
  const res = await api.delete(`/user/`);

  return res.data;
};

export const useEditProfile = (
  options?: UseMutationOptions<User, Error, { data: ProfileFormType }>,
) =>
  useMutation({
    mutationFn: ({ data }) => editProfile(data),
    ...options,
  });

export const useEditImage = (
  options?: UseMutationOptions<ProfileType, Error, { data: FormData }>,
) =>
  useMutation({
    mutationFn: ({ data }) => editImage(data),
    ...options,
  });

export const useDeleteProfile = (options?: UseMutationOptions<User, Error>) =>
  useMutation({
    mutationFn: deleteProfile,
    ...options,
  });

export const useDeleteImage = (options?: UseMutationOptions<User, Error>) =>
  useMutation({
    mutationFn: deleteImage,
    ...options,
  });

export const useDeleteCreditCard = (
  options?: UseMutationOptions<any, Error, any>,
) =>
  useMutation({
    mutationFn: ({ id }) => deleteCreditCard(id),
    ...options,
  });
