import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { SupportersDisplay } from "./SupportersDisplay";
import { Progress } from "@/components/ui/progress";
import { ChallengeType, DonationWrapType } from "@/types/challenge";
import useCurrencyStore, { Currencies } from "@/store/currencyStore";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type DonationWrapProps = {
  readonly donation_wrap: DonationWrapType;
  readonly challenge: ChallengeType;
};
export const DonationWrap = ({
  donation_wrap,
  challenge,
}: DonationWrapProps) => {
  const { currency } = useCurrencyStore();
  const { t } = useTranslation();
  const raisedAmount = useMemo(() => {
    if (currency === Currencies.EGP) {
      return `EGP ${donation_wrap.total_donated_egp}`;
    }
    return `$ ${donation_wrap.total_donated_usd}`;
  }, [currency, donation_wrap]);
  const raisedPercentage = useMemo(() => {
    if (currency === Currencies.EGP) {
      return Math.round(
        (donation_wrap.total_donated_egp / challenge.goal_egp) * 100,
      );
    }
    return Math.round(
      (donation_wrap.total_donated_usd / challenge.goal_usd) * 100,
    );
  }, [currency, donation_wrap]);
  return (
    <Card className="flex h-[550px] w-[310px] shrink-0 flex-col rounded-2xl  border-0 bg-[#AC9CA0]">
      <CardHeader className="z-[4]">
        <h3 className="text-sm font-[350]">{t("challenge.donationWrap")}</h3>
        <h3 className="font-[650]">{t("challenge.lastWeek")}</h3>
      </CardHeader>
      <CardContent className="relative z-[4]">
        <div className="mb-2 md:mb-4">
          <div className=" flex flex-col items-start gap-2 pb-4 md:pb-6">
            <SupportersDisplay
              supporters={donation_wrap.supporters}
              count={donation_wrap.supporters_count}
              dark
            />
          </div>
          <Progress
            value={raisedPercentage}
            className="dark mb-4 h-[4px] w-full rounded-[2px] rtl:scale-x-[-1] rtl:transform"
            aria-label="Donation amount progress bar"
          />
          <p>{raisedAmount}</p>
        </div>
      </CardContent>
    </Card>
  );
};
