import { useState, ChangeEvent } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import useCurrencyStore from "@/store/currencyStore";
import { useTranslation } from "react-i18next";

interface AmountInputProps {
  readonly value: string;
  readonly onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  readonly className?: string;
  readonly placeholder?: string;
  readonly paymentCurrency?: string;
  readonly updatePaymentCurrency?: (_: string) => void;
  readonly currenciesChoice?: string[];
}

export default function AmountInput({
  value,
  onChange,
  className,
  placeholder,
  paymentCurrency,
  updatePaymentCurrency,
  currenciesChoice,
}: AmountInputProps) {
  const { currencies } = useCurrencyStore();

  const { currency, setCurrency } = useCurrencyStore();
  const [isFocused, setIsFocused] = useState(false);
  const { i18n } = useTranslation();

  const handleChange = (value: string) => {
    if (value === "") {
      return;
    }
    if (updatePaymentCurrency) {
      updatePaymentCurrency(value);
    } else {
      setCurrency(value);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const isRTL = i18n.language === "ar";

  return (
    <div className="flex w-full flex-col gap-2">
      <div
        className={cn(
          "border-[#CCC2BF] flex rounded-md border w-full p-2 h-[58px]",
          className,
        )}
      >
        <div className="flex items-center">
          <label>
            <Select
              onValueChange={handleChange}
              value={paymentCurrency ?? currency}
            >
              <SelectTrigger
                className={cn(
                  "text-foreground h-full rounded-md border-transparent bg-[#E2DDD7] text-sm font-bold md:text-base",
                  {
                    rtl: isRTL,
                  },
                )}
                dir={isRTL ? "rtl" : "ltr"}
              >
                <SelectValue>{paymentCurrency ?? currency}</SelectValue>
              </SelectTrigger>
              <SelectContent
                className={cn(
                  "left-0 top-0 min-w-[var(--radix-select-trigger-width)] translate-y-[-40%] transform",
                  {
                    "right-0 left-auto": isRTL,
                  },
                )}
                dir={isRTL ? "rtl" : "ltr"}
              >
                {(currenciesChoice ?? currencies).map((c) => (
                  <SelectItem key={`currency-${c}`} value={c}>
                    {c}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </label>
        </div>
        <input
          type="text"
          aria-label="price"
          name="price"
          id="price"
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={cn(
            "text-[#4F4754] bg-background md:!text-base !text-sm p-2 block w-full rounded-md border-opacity-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm",
            value !== "" && !isFocused ? "font-bold text-[#232329]" : "",
            value === ""
              ? "placeholder-[#4F4754] text-base focus:bg-transparent"
              : "",
          )}
          placeholder={placeholder}
          pattern="\d*"
        />
      </div>
    </div>
  );
}
