import { useGetLocaleCurrency } from "@/api/live-conversion";
import { Footer } from "@/components/Footer";
import { NotFound } from "@/pages/errorPages/notFound";
import { isRTLLanguage } from "@/utils/helpers";
import {
  Outlet,
  ScrollRestoration,
  createRootRoute,
} from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

export const Route = createRootRoute({
  component: RootComponent,
  notFoundComponent: NotFound,
});

function RootComponent() {
  const { i18n } = useTranslation();
  const { data } = useGetLocaleCurrency();
  return (
    <div
      dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
      data-currency={data?.localeCurrency}
    >
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
}
