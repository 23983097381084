import { useGetAuthenticatedUser } from "@/api/user";
import { StepFooter } from "./stepFooter";
import { StepHeader } from "./stepHeader";
import { useRef } from "react";
import useChallengeFormStore from "@/store/challengeStore";
import { Input } from "@/components/ui/input";
import { Upload, X } from "lucide-react";
import { Trans, useTranslation } from "react-i18next";

export const DetailsStep = () => {
  const { t } = useTranslation();
  const {
    nextStep,
    challengeName,
    challengeDescription,
    setChallengeDescription,
    setChallengeName,
    coverImage,
    setCoverImage,
    currentStep,
  } = useChallengeFormStore();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { data: user, isLoading } = useGetAuthenticatedUser();

  const nextStepWithAuthCheck = () => {
    if ((currentStep === 3 && user && !isLoading) || currentStep !== 3) {
      nextStep();
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file?.type.startsWith("image/")) {
      setCoverImage(file);
    } else {
      alert("Please select an image file");
    }
  };

  const removeImage = () => {
    setCoverImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <StepHeader />
      <div className="scrollbar-hide bg-background mt-2 flex h-screen w-full flex-col gap-16 overflow-y-auto pt-4 md:mb-[90px] md:pt-16 lg:max-w-[615px]">
        <div className="bg-background mb-8 flex h-full w-full flex-col gap-4 md:mt-0 md:gap-8 md:px-6">
          <h3 className="w-full py-2 text-xl font-[650] leading-[120%] sm:text-[32px] md:py-0">
            {t("challenge.describeChallenge")}
          </h3>
          <div className="flex flex-col gap-2">
            <label htmlFor="challengeName" className="text-sm font-[650]">
              {t("challenge.challengeName")}
            </label>
            <Input
              type="text"
              id="challengeName"
              value={challengeName}
              onChange={(e) => setChallengeName(e.target.value)}
              placeholder={t("challenge.namePlaceholder")}
              className="!placeholder:text-[#4F4754] px-4 font-[650] placeholder:font-[350]"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              htmlFor="challengeDescription"
              className="text-sm font-[650]"
            >
              {t("challenge.challengeDescription")}
            </label>
            <div className="bg-background flex w-full flex-col gap-4 rounded-lg border border-[#CCC2BF] px-4 py-2 text-base text-[#232329] md:gap-8">
              <textarea
                id="challengeDescription"
                value={challengeDescription}
                onChange={(e) => setChallengeDescription(e.target.value)}
                placeholder={t("challenge.describePlaceholder")}
                className="scrollbar-hide ring-offset-background placeholder:text-muted-foreground flex min-h-[70px] w-full resize-none bg-transparent font-[650] placeholder:font-[350] placeholder:text-[#4F4754] focus:text-[#232329] focus-visible:outline-none"
                rows={challengeDescription.length / 60}
              />
              <div className="text-right text-sm font-[350] text-[#AC9CA0]">
                <Trans
                  i18nKey={"challenge.characters"}
                  values={{ top: challengeDescription.length, bottom: 600 }}
                />
              </div>
            </div>
          </div>

          <div className="pb-12 md:pb-16">
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageUpload}
              accept="image/*"
              className="hidden"
            />
            {!coverImage?.size ? (
              <button
                onClick={() => fileInputRef.current?.click()}
                className="flex items-center gap-2 rounded-lg bg-gray-800 px-4 py-2 text-white"
              >
                {t("challenge.uploadCover")}
                <Upload height={20} width={20} color="#EDEAE4" />
              </button>
            ) : (
              <div className="relative h-[130px] w-full overflow-hidden rounded-lg bg-gray-200">
                <img
                  src={URL.createObjectURL(coverImage)}
                  alt="Cover"
                  className="h-full w-full object-cover"
                />
                <button
                  onClick={removeImage}
                  className="absolute left-1/2 top-1/2 flex h-10 -translate-x-1/2 -translate-y-1/2 items-center justify-between gap-[6.471px] rounded-lg bg-white px-6 py-[6.471px] text-gray-700 hover:bg-gray-100"
                >
                  <p className="font-[650]">{t("challenge.remove")}</p>
                  <X height={20} width={20} strokeWidth={2.2} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <StepFooter
        nextStep={nextStepWithAuthCheck}
        isDisabled={!challengeName.length || !challengeDescription.length}
      />
    </>
  );
};
