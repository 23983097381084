import { bgOverlayStyle } from "@/utils/helpers";
import { AppShell } from "@/components/Navbar";
import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  title?: string | ReactNode;
  picture?: string;
}

export const Header: React.FC<HeaderProps> = ({ title, picture }) => {
  const backgroundStyle = bgOverlayStyle(picture ?? "");
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta name="description" content={`${title} page`} />
      </Helmet>
      <div className="bg-foreground flex flex-row gap-1 px-4 py-4 text-white md:px-[24px]">
        {t("poweredBy.first_part")}{" "}
        <p className="font-bold text-white"> {t("poweredBy.second_part")}</p>
      </div>

      <AppShell theme="dark" navbarClass="top-14 absolute w-full" />
      <div
        className="dark relative h-[65vh] w-full bg-cover bg-center md:h-[calc(70vh-5rem)]"
        style={{
          backgroundImage: backgroundStyle,
        }}
      >
        <div className="flex h-full flex-shrink-0 flex-col items-start justify-end self-stretch p-4 md:p-6">
          <div className="w-5/7">
            <h1 className="text-40px md:text-96px font-bold leading-[40px] text-white md:leading-[96px]">
              {title}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};
