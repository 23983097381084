import api from "./api-client";
import { useQuery } from "@tanstack/react-query";
import { UpdateResponse, UpdateType } from "@/types/programs";

const fetchAllPaginatedData = async (
  url: string,
  params?: Record<string, string>,
) => {
  let results: UpdateType[] = [];
  let nextUrl: string | null = url;

  while (nextUrl) {
    const response = await api.get(nextUrl, { params });
    const data: UpdateResponse = response.data;
    results = results.concat(data.results);
    nextUrl = data.links.next;
  }

  return results;
};

export const getAllUpdates = async (params?: Record<string, string>) => {
  const url = "/settings/update/updates/";
  return await fetchAllPaginatedData(url, params);
};

const updatesKeys = {
  updates: () => ["updates"],
};

export const useUpdates = (params?: Record<string, string>) => {
  return useQuery<UpdateType[], Error>({
    queryKey: [...updatesKeys.updates(), params],
    queryFn: () => getAllUpdates(params),
  });
};
