import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import LangIcon from "@/assets/icons/lang";
import { Circle, CircleCheck } from "lucide-react";
import { useTranslation } from "react-i18next";
import useCurrencyStore from "@/store/currencyStore";

export const CombinedDropdown = () => {
  const { currencies } = useCurrencyStore();
  const { setCurrency, currency } = useCurrencyStore();
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();

  const formatLanguage = (lang: string) => {
    switch (lang) {
      case "en":
        return "English";
      case "ar":
        return "العربية";
      default:
        return new Intl.Locale(lang).language.toUpperCase();
    }
  };
  const setLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };
  const { t } = useTranslation();

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger className="focus:outline-none">
        <div
          className="text-foreground inline-flex items-start justify-start pt-2"
          aria-label="Open language and currency dropdown menu"
        >
          <LangIcon />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="dark z-10 rounded-lg border border-[#CCC2BF] bg-[#ECE9E3] px-4 py-2">
        <div
          className={`mb-2 text-sm font-bold text-[#4F4754] ${
            i18n.language === "ar" ? "text-right" : ""
          }`}
        >
          {t("langSelector.language")}
        </div>
        {i18n.languages.map((lang) => (
          <DropdownMenuItem
            key={`lang-${lang}`}
            className={`text-background cursor-pointer py-1 hover:outline-none ${
              i18n.language === lang ? "font-bold" : "font-light"
            }`}
            onSelect={() => setLanguage(lang)}
          >
            <div
              className={`flex items-center gap-2 ${
                i18n.language === "ar" ? "flex-row-reverse" : "flex-row"
              }`}
            >
              {i18n.language === lang ? (
                <CircleCheck className="h-5 w-5 fill-[#232329] stroke-[#ECE9E3]" />
              ) : (
                <Circle className="h-4 w-4 stroke-[#C2B4B3]" />
              )}
              <div
                className={`text-background w-[100px] ${
                  i18n.language === "ar" ? "text-right" : ""
                }`}
              >
                {formatLanguage(lang)}
              </div>
            </div>
          </DropdownMenuItem>
        ))}

        <div className="my-4"></div>

        <div
          className={`mb-2 text-sm font-bold text-[#4F4754] ${
            i18n.language === "ar" ? "text-right" : ""
          }`}
        >
          {t("langSelector.currency")}
        </div>
        {currencies.map((curr) => (
          <DropdownMenuItem
            key={`curr-${curr}`}
            className={`text-background cursor-pointer py-1 hover:outline-none ${
              currency === curr ? "font-bold" : "font-light"
            }`}
            onSelect={() => setCurrency(curr)}
          >
            <div
              className={`flex items-center gap-2 ${
                i18n.language === "ar" ? "flex-row-reverse" : "flex-row"
              }`}
            >
              {currency === curr ? (
                <CircleCheck className="h-5 w-5 fill-[#232329] stroke-[#ECE9E3]" />
              ) : (
                <Circle className="h-4 w-4 stroke-[#C2B4B3]" />
              )}
              <div
                className={`text-background w-[100px] ${
                  i18n.language === "ar" ? "text-right" : ""
                }`}
              >
                {curr}
              </div>
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
