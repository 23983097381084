import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

// Define the Currency enum
export enum Currencies {
  USD = "USD",
  EGP = "EGP",
}

interface CurrencyStoreState {
  currency: string;
  currencies: string[];
  conversionRate?: number;
}

interface CurrencyStoreActions {
  setCurrency: (currency: string) => void;
  addCurrency: (currency: string) => void;
  setConversionRate: (conversionRate: number | undefined) => void;
}

const useCurrencyStore = create<CurrencyStoreState & CurrencyStoreActions>()(
  persist(
    (set) => ({
      currency: Currencies.EGP,
      currencies: ["EGP", "USD"],
      setCurrency: (curr) => set({ currency: curr }),
      addCurrency: (currency: string) =>
        set((state) => ({
          currencies: Array.from(new Set([...state.currencies, currency])),
        })),
      setConversionRate(conversionRate) {
        set({ conversionRate });
      },
    }),
    {
      name: "currency-store",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["currencies"].includes(key),
          ),
        ),
    },
  ),
);

export default useCurrencyStore;
