import { cn } from "@/lib/utils";
import { formatCurrency } from "@/utils/currency";
import { useInView, useMotionValue, useSpring } from "framer-motion";
import { useEffect, useRef } from "react";

interface NumberTickerProps {
  initialValue: number;
  value: number;
  direction?: "up" | "down";
  delay?: number;
  className?: string;
}

const NumberTicker: React.FC<NumberTickerProps> = ({
  value,
  initialValue,
  direction = "up",
  delay = 0,
  className,
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const motionValue = useMotionValue(
    direction === "down" ? value : initialValue,
  );
  const springValue = useSpring(motionValue, {
    damping: 60,
    stiffness: 50,
  });
  const isInView = useInView(ref, { once: true, margin: "0px" });

  useEffect(() => {
    if (ref.current) {
      ref.current.textContent = `${formatCurrency(initialValue)}`;
    }
  }, [initialValue]);

  useEffect(() => {
    if (isInView) {
      setTimeout(() => {
        motionValue.set(direction === "down" ? initialValue : value);
      }, delay * 1000);
    }
  }, [motionValue, isInView, delay, value, direction]);

  useEffect(() => {
    const unsubscribe = springValue.on("change", (latest) => {
      if (ref.current) {
        ref.current.textContent = `${formatCurrency(latest)}`;
      }
    });
    return () => unsubscribe();
  }, [springValue]);
  return (
    <span
      className={cn(
        "inline-block text-light text-lg font-semibold md:text-xl",
        className,
      )}
      ref={ref}
    />
  );
};

export default NumberTicker;
