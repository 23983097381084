import { ChallengePaymentFlow } from "@/pages/challenge-payment";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const challengePaymentSearchSchema = z.object({
  backUrl: z.string().optional(),
});

export const Route = createFileRoute("/challenge-payment/$challengeId")({
  component: ChallengePaymentFlow,
  validateSearch: (search) => challengePaymentSearchSchema.parse(search),
});
