import { Button } from "@/components/ui/button";
import DeleteCheckModal from "./DeleteCheckModal";
import { Dispatch, SetStateAction, useState } from "react";
import ProfileModal from "@/components/Profile/Modal";
import { Link } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { UseMutateFunction } from "@tanstack/react-query";
import { User } from "@/types/auth";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  deleteUser: UseMutateFunction<User, Error, void, unknown>;
};
export default function DeleteReasonModal({
  open,
  setOpen,
  deleteUser,
}: Readonly<Props>) {
  const [openCheckModal, setOpenCheckModal] = useState(false);
  const { t } = useTranslation();

  const handleDelete = () => {
    setOpenCheckModal(true);
  };
  return (
    <>
      <ProfileModal
        onClose={() => deleteUser()}
        open={open}
        setOpen={setOpen}
        title="deleteSuccess"
        button={
          <>
            <Button
              size="lg"
              variant="bold"
              className="full min-w-56"
              type="submit"
              onClick={handleDelete}
            >
              {t("profile.doYouMind")}
            </Button>
            <Link to={"/"}>
              <Button size="lg" variant="underline" className="w-full min-w-56">
                {t("profile.dismiss")}
              </Button>
            </Link>
          </>
        }
      />
      <DeleteCheckModal
        deleteUser={deleteUser}
        open={openCheckModal}
        setOpen={setOpenCheckModal}
      />
    </>
  );
}
