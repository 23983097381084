import React from "react";
import { Button } from "@/components/ui/button";
import { ChevronRightIcon, X } from "lucide-react";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { useLocation, useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

interface GenericStepHeaderProps {
  steps: string[];
  currentStep: number;
  handleModalClose: () => void;
  prevStep: () => void;
}

export const GenericStepHeader = ({
  steps,
  currentStep,
  handleModalClose,
  prevStep,
}: GenericStepHeaderProps) => {
  const { t } = useTranslation();
  return (
    <div className="bg-background sticky top-0 z-10 flex w-full flex-col items-start justify-between py-2 md:pb-0">
      <div className="bg-background flex w-full items-center justify-between gap-4">
        <div className="flex items-center gap-2">
          {steps.map((step, index) => (
            <React.Fragment key={step}>
              <div
                role="button"
                className={`text-sm leading-[140%] ${index + 1 === currentStep ? "font-bold" : "text-foreground/50"}
                        ${index + 1 < currentStep ? "cursor-pointer hover:underline" : ""}`}
                onClick={() => index + 1 < currentStep && prevStep()}
              >
                {step}
              </div>
              {index < steps.length - 1 && (
                <ChevronRightIcon
                  className={`${index + 1 < currentStep ? "stroke-foreground" : "stroke-[#695B6A]"}`}
                  size={16}
                  strokeWidth={3}
                />
              )}
            </React.Fragment>
          ))}
        </div>
        <Button
          variant={"ghost"}
          aria-label={t("closeModal")}
          className="ring-offset-background data-[state=open]:bg-accent data-[state=open]:text-muted-foreground hover:bg-transparent focus:outline-none focus:ring-0 focus:ring-offset-2 disabled:pointer-events-none"
          onClick={handleModalClose}
        >
          <X className="stroke-foreground h-6 w-6" />
        </Button>
      </div>
    </div>
  );
};

export default function StepHeader() {
  const { t } = useTranslation();
  const { prevStep, currentStep, reset } = useFormStore();
  const { search } = useLocation();
  const navigate = useNavigate();

  const handleModalClose = () => {
    reset();
    if (search.response_code) {
      navigate({ to: "/" });
    } else {
      navigate({ to: search.backUrl ?? "/" });
    }
  };

  const steps = [
    t("StepsContainer.StepHeader.donation"),
    t("StepsContainer.StepHeader.payment"),
    t("StepsContainer.StepHeader.details"),
  ];

  return (
    <GenericStepHeader
      steps={steps}
      currentStep={currentStep}
      handleModalClose={handleModalClose}
      prevStep={prevStep}
    />
  );
}
