import React, { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useLocation, useNavigate } from "@tanstack/react-router";
import { useTranslation, Trans } from "react-i18next";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { Checkbox } from "@/components/ui/checkbox";
import { Form, Formik } from "formik";
import { useChallenge, usePostChallengeDonation } from "@/api/challenge";
import FailedPayment from "@/components/PaymentFlow/FailedPayment";
import { getLoginURL } from "@/utils/auth";
import { useGetAuthenticatedUser } from "@/api/user";
import { useGetSubscriptions } from "@/api/subscription";
import dayjs from "dayjs";

interface ChallengePreferenceProps {
  amount: string;
  email: string;
  readonly challengeId?: string;
}

const ChallengePreference: React.FC<ChallengePreferenceProps> = ({
  amount,
  email,
  challengeId,
}) => {
  const { t, i18n } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();
  const {
    cashSuccess,
    setDonationType,
    selectedChallenge,
    cashDonationId,
    setPreferenceSuccess,
    setInputAmount,
    setSelectedOption,
    setFinalMonth,
    setAgreementId,
    checkoutStep,
  } = useFormStore();
  const { refetch } = useChallenge(selectedChallenge?.id ?? "", {
    lang: i18n.language,
  });

  useEffect(() => {
    setDonationType(undefined);
  }, []);

  const { mutate } = usePostChallengeDonation({
    onSuccess: () => {
      refetch();
      setPreferenceSuccess(true);
    },
  });

  const { data: user } = useGetAuthenticatedUser();
  const { data: subscriptions } = useGetSubscriptions({
    agreement_id: search.agreement_id?.replaceAll("/", "") ?? "null",
  });

  const handleOnclickRetry = () => {
    const subscription = subscriptions?.results.at(0);
    if (search.agreement_id && !user) {
      window.location.href = getLoginURL("");
      return;
    }
    if (subscription) {
      setInputAmount(subscription.amount);
      setSelectedOption("Credit Card");
      setFinalMonth(dayjs(subscription.expiration_date).toDate());
      setAgreementId(subscription.agreement_id);
      checkoutStep();
    }
    navigate({
      to: `/challenge-payment/${challengeId}`,
      search: { backUrl: location.pathname },
    });
  };

  return (
    <div
      className={`${search.response_code === 14000 || cashSuccess ? "justify-start" : "mt-20 justify-start"} flex h-full w-full flex-col items-start gap-4 p-4 md:gap-8 md:pt-14`}
    >
      {search.response_code === 14000 || cashSuccess ? (
        <Formik
          initialValues={{
            message: "",
            hide_amount: false,
            hide_id: false,
            challenge: selectedChallenge?.id,
            donation: cashDonationId ?? search.donation_id,
          }}
          onSubmit={(values) => {
            mutate({
              data: values,
            });
          }}
        >
          {({ values, setFieldValue }) => (
            <Form className="flex flex-col gap-8">
              <div className="flex flex-col gap-4">
                <h1 className="text-foreground pt-20 text-xl font-[650] leading-[120%] md:pt-0 md:text-[32px]">
                  {t("paymentState.thankYou")}
                </h1>
                <p className="text-foreground font-[350]">
                  <Trans
                    i18nKey={
                      search.response_code === 14000
                        ? "paymentState.donationProcessed"
                        : "paymentState.cashDonationProcessed"
                    }
                    values={{ amount, email }}
                    components={{
                      bold: <span className="text-foreground font-bold" />,
                    }}
                  />
                </p>
              </div>
              <div className="flex flex-col gap-4">
                <h4 className="text-foreground text-lg font-[650] md:text-2xl">
                  {t("challenge.preferenceTitle")}
                </h4>
                <p className="text-foreground font-[350]">
                  {t("challenge.preferenceDescription")}
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="challengeDescription"
                  className="text-sm font-[650]"
                >
                  {t("challenge.thankYouMessage")}
                </label>
                <div className="bg-background flex w-full flex-col gap-4 rounded-lg border border-[#CCC2BF] px-4 py-2 text-base text-[#232329] md:gap-8">
                  <textarea
                    id="challengeDescription"
                    value={values.message}
                    onChange={(e) => {
                      setFieldValue("message", e.target.value);
                    }}
                    placeholder={t("challenge.writeMessage")}
                    className="scrollbar-hide ring-offset-background placeholder:text-muted-foreground flex min-h-[150px] w-full resize-none bg-transparent font-[650] placeholder:font-[350] placeholder:text-[#4F4754] focus:text-[#232329] focus-visible:outline-none"
                    rows={values.message.length / 50}
                  />
                  <div className="text-right text-sm font-[350] text-[#AC9CA0]">
                    <Trans
                      i18nKey={"challenge.characters"}
                      values={{ top: values.message.length, bottom: 600 }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start gap-2">
                <label className="flex items-center gap-4 font-[350]">
                  <Checkbox
                    checked={values.hide_amount}
                    onCheckedChange={() =>
                      setFieldValue("hide_amount", !values.hide_amount)
                    }
                    className="ltr:mr-2 rtl:ml-2"
                  />
                  {t("challenge.hideAmount")}
                </label>
                <label className="flex items-center gap-4 font-[350]">
                  <Checkbox
                    checked={values.hide_id}
                    onCheckedChange={() =>
                      setFieldValue("hide_id", !values.hide_id)
                    }
                    className="ltr:mr-2 rtl:ml-2"
                  />
                  {t("challenge.hideId")}
                </label>
              </div>
              <div className="bg-background flex w-full justify-end border-t border-[#CCC2BF] p-6">
                <Button
                  type="submit"
                  className="bg-primary text-foreground hover:bg-foreground hover:text-light h-full w-full px-10 py-4 font-[650] sm:w-[14rem]"
                >
                  {t("challenge.confirmPreference")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <FailedPayment
          responseMessage={search.response_message}
          handleOnclickRetry={handleOnclickRetry}
        />
      )}
    </div>
  );
};

export default ChallengePreference;
