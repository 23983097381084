import { useEffect, useState } from "react";
import { X } from "lucide-react";
import { Button } from "../ui/button";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: "accepted" | "dismissed"; platform: string }>;
}

const InstallButton = () => {
  const { t } = useTranslation();
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      const lastDismiss = localStorage.getItem("dismiss-pwa");
      if (lastDismiss && dayjs().diff(lastDismiss, "days") < 4) {
        return;
      }
      e.preventDefault();
      setDeferredPrompt(e);
      setIsVisible(true);
    };

    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPrompt as EventListener,
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt as EventListener,
      );
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      const promptEvent = deferredPrompt;
      promptEvent.prompt();
      setDeferredPrompt(null);
      setIsVisible(false);
    }
  };

  const handleCloseClick = () => {
    localStorage.setItem("dismiss-pwa", new Date().toISOString());
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className=" w-110 fixed bottom-7 z-[60] mx-4 flex flex-col items-center gap-4 rounded-lg border border-gray-300 bg-white p-2 px-6 shadow-lg md:right-8">
      <button
        onClick={handleCloseClick}
        className="mt-2 self-end "
        aria-label="Close install"
      >
        <X className="h-6 w-6 " color="black" aria-label="close" />
      </button>
      <div className="flex items-center gap-1">
        <img src="/light_logo.svg" alt="App Icon" className="h-10 w-10" />
        <p className="ml-6 text-center text-gray-800">{t("installText")}</p>
      </div>
      <Button
        className="bg-primary rounded-md px-10 py-2 text-black"
        onClick={handleInstallClick}
        aria-label="Install app"
      >
        {t("install")}
      </Button>
    </div>
  );
};

export default InstallButton;
