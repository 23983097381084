import { useLocation, useRouter } from "@tanstack/react-router";
import { programQueryOptions } from "@/api/program";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import PaymentFlowContainer from "@/components/PaymentFlow/paymentFlowContainer";
import { useFormStore } from "@/contexts/paymentFlowContext";
import PaymentState from "@/components/PaymentFlow/PaymentState";
import { Route } from "@/routes/gratitude.$programId";
import { AppShell } from "@/components/Navbar";
import useCurrencyStore from "@/store/currencyStore";
import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import {
  defaultCurrencies,
  getConversionApproximation,
} from "@/utils/currency";

export const Gratitude = () => {
  const { programId } = Route.useParams();
  const { currency } = useCurrencyStore();
  const { search } = useLocation();
  const { t, i18n } = useTranslation();
  const { email, inputAmount, isChallenge, selectedChallenge } = useFormStore();
  const [hideNavbar, setHideNavbar] = useState<boolean>(false);
  const amount = `${currency} ${inputAmount} ${!defaultCurrencies.includes(currency) ? getConversionApproximation(inputAmount) : ""}`;
  const { navigate } = useRouter();
  const { data: program } = useQuery(
    programQueryOptions(programId, {
      lang: i18n.language,
      shared: (search?.shared ?? "false").toString(),
    }),
  );

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (event.currentTarget.scrollTop > 10) {
      setHideNavbar(true);
    } else {
      setHideNavbar(false);
    }
  };

  useEffect(() => {
    if (isChallenge) {
      navigate({
        to: `/challenge-checkout/${selectedChallenge?.id}`,
        search: {
          response_code: search.response_code,
          donation_id: search.donation_id,
        },
      });
    }
  }, [isChallenge]);

  return program ? (
    <>
      <div className="bg-foreground fixed left-0 right-0 top-0 flex w-full flex-row gap-1 px-4 py-4 text-white md:px-[24px]">
        {t("poweredBy.first_part")}{" "}
        <p className="font-bold text-white"> {t("poweredBy.second_part")}</p>
      </div>
      <PaymentFlowContainer program={program}>
        <AppShell
          navbarClass={cn(
            "absolute md:top-12 top-12 transition ease-in-out duration-150 block w-full md:fixed bg-transparent",
            { "opacity-0": hideNavbar },
          )}
          hideLogo={true}
        />
        <div
          onScroll={handleScroll}
          className={`bg-background h-[70%] flex-1 overflow-auto rounded-xl p-4 pb-0 sm:h-full md:rounded-none lg:p-16 lg:pb-0 lg:pt-24`}
        >
          <PaymentState amount={amount} email={email} program={program} />
        </div>
      </PaymentFlowContainer>
    </>
  ) : null;
};
