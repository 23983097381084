import { Skeleton } from "@/components/ui/skeleton";
import { Card, CardContent, CardFooter } from "@/components/ui/card";

export const ProgramCardSkeleton = () => {
  return (
    <Card className="group cursor-pointer overflow-hidden transition-all duration-300 hover:scale-[1.02] hover:border-2">
      <Skeleton className="aspect-[16/9] w-full rounded-t-lg" />
      <CardContent>
        <div className="flex flex-col gap-4 pt-6">
          <Skeleton className="h-6 w-3/4" />
          <Skeleton className="h-4 w-1/2" />
          <Skeleton className="h-4 w-1/3" />
          <Skeleton className="h-4 w-1/4" />
        </div>
      </CardContent>
      <CardFooter className="flex gap-3">
        <Skeleton className="h-14 w-full flex-grow rounded-md px-10 py-4" />
        <div className={"flex h-full w-full gap-2"}>
          <Skeleton className="h-14 w-14" />
          <Skeleton className="h-14 w-14" />
        </div>
      </CardFooter>
    </Card>
  );
};
