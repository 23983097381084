export const MenuIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 14C10 13.4477 10.4477 13 11 13H29C29.5523 13 30 13.4477 30 14C30 14.5523 29.5523 15 29 15H11C10.4477 15 10 14.5523 10 14ZM10 20C10 19.4477 10.4477 19 11 19H29C29.5523 19 30 19.4477 30 20C30 20.5523 29.5523 21 29 21H11C10.4477 21 10 20.5523 10 20ZM10 26C10 25.4477 10.4477 25 11 25H29C29.5523 25 30 25.4477 30 26C30 26.5523 29.5523 27 29 27H11C10.4477 27 10 26.5523 10 26Z"
        fill="hsl(var(--foreground))"
      />
    </svg>
  );
};
