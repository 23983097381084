export const CrossIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2929 20.7071C20.6834 20.3166 21.3166 20.3166 21.7071 20.7071L34.435 33.435C34.8256 33.8256 34.8256 34.4587 34.435 34.8492C34.0445 35.2398 33.4113 35.2398 33.0208 34.8492L20.2929 22.1213C19.9024 21.7308 19.9024 21.0976 20.2929 20.7071Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M20.7071 34.7071C20.3166 34.3166 20.3166 33.6834 20.7071 33.2929L33.435 20.565C33.8256 20.1744 34.4587 20.1744 34.8492 20.565C35.2398 20.9555 35.2398 21.5887 34.8492 21.9792L22.1213 34.7071C21.7308 35.0976 21.0976 35.0976 20.7071 34.7071Z"
        fill="hsl(var(--foreground))"
      />
    </svg>
  );
};
