import { MyDonations } from "@/pages/donor-profile";
import { IsAuthenticated } from "@/utils/auth";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const donorProfileSchema = z.object({
  page: z.string(),
});

export const Route = createFileRoute("/donor-profile")({
  component: MyDonations,
  validateSearch: (search) => donorProfileSchema.parse(search),
  beforeLoad: async () => await IsAuthenticated(),
});
