import { DocumentType } from "@/types/document";
import { CarouselComponent } from "@/pages/home/sections/CarouselComponent";

type MediaGalleryProps = {
  readonly images?: DocumentType[];
};

export default function MediaGallery({ images }: MediaGalleryProps) {
  const renderImage = (image: DocumentType) => (
    <div
      key={image.id}
      className="flex h-full w-[341px] flex-col items-center justify-center md:w-[741px]"
    >
      <img
        src={image?.minio_url}
        alt="placeholder"
        className="rounded-m rounded-md object-contain"
      />
    </div>
  );

  if (!images?.length) {
    return null;
  }

  return (
    <section className="my-5 w-full md:my-8 md:px-[24px] px-[16px]">
      <CarouselComponent imagesMd={2} imagesLg={2}>
        {images.map((image) => renderImage(image))}
      </CarouselComponent>
    </section>
  );
}
