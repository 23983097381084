import { createFileRoute } from "@tanstack/react-router";
import { Gratitude } from "@/pages/gratitude";

import { z } from "zod";

const gratitudeSearchSchema = z.object({
  program_id: z.string().optional(),
  donation_id: z.string().optional(),
  currency: z.string().optional(),
  response_message: z.string().optional(),
});

export const Route = createFileRoute("/gratitude/$programId")({
  component: Gratitude,
  validateSearch: (search) => gratitudeSearchSchema.parse(search),
});
