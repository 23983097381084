export default {
  baseURL: import.meta.env.VITE_API_BASE_URL,
  customerDomain: import.meta.env.VITE_CUSTOMER_DOMAIN,
  paymentGateAPS: import.meta.env.VITE_PAYMENT_GATEWAY,
  merchantIdAPS: import.meta.env.VITE_APS_MERCHANT_ID,
  merchantAccessCodeAPS: import.meta.env.VITE_APS_MERCHANT_ACCESS_CODE,
  passSHAPass: import.meta.env.VITE_APS_SHA_PASS,
  prod: import.meta.env.PROD,
  socketURL: import.meta.env.VITE_WEBSOCKET_URL,
};

type FooterEntry = {
  key: string;
  link: string;
};

type Footer = {
  learn_more: FooterEntry[];
  legal: FooterEntry[];
};

export const footerConfig: Footer = {
  learn_more: [
    {
      key: "footer.about",
      link: "/aboutUs",
    },
    {
      key: "footer.faq",
      link: "/faqs",
    },
    {
      key: "footer.programs",
      link: "/programs",
    },
    {
      key: "footer.contact",
      link: "/contact",
    },
  ],
  legal: [
    {
      key: "footer.terms",
      link: "/policy?type=terms",
    },
    {
      key: "footer.privacy",
      link: "/policy?type=privacy",
    },
    {
      key: "footer.cookies",
      link: "/policy?type=cookies",
    },
  ],
};
