export const PlayButtonIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FAFAFA" />
      <path
        d="M32.5 23.134C33.1667 23.5189 33.1667 24.4811 32.5 24.866L20.5 31.7942C19.8333 32.1791 19 31.698 19 30.9282L19 17.0718C19 16.302 19.8333 15.8209 20.5 16.2058L32.5 23.134Z"
        fill="#22222A"
      />
    </svg>
  );
};
