import { ProgramType } from "@/types/programs";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { useLocation } from "@tanstack/react-router";
import { Route } from "@/routes/paymentFlow.$programId";
import { programQueryOptions } from "@/api/program";
import { suggestedAmountsQueryOptions } from "@/api/suggested-amounts";
import { useQuery } from "@tanstack/react-query";
import StepsContainer from "@/components/PaymentFlow/StepsContainer";
import { useTranslation } from "react-i18next";
import PaymentFlowContainer from "@/components/PaymentFlow/paymentFlowContainer";
import { useEffect } from "react";
import { livestockQueryOptions } from "@/api/livestock";
import i18n from "@/i18n";

type DonationStepperProps = {
  readonly program: ProgramType;
};

export function DonationStepper({ program }: DonationStepperProps) {
  const { currentStep, setIsSpecial, setDonationType, setSpecialType } =
    useFormStore();
  const { data: suggestedAmounts } = useQuery(
    suggestedAmountsQueryOptions({
      program_id: program.id,
    }),
  );
  const { data: livestock } = useQuery(
    livestockQueryOptions({ program_id: program.id, lang: i18n.language }),
  );

  useEffect(() => {
    setIsSpecial(program.is_special);
    if (program.is_special && program.donation_types.length > 0) {
      setDonationType(program.donation_types[0].id);
      program.special_type && setSpecialType(program.special_type);
    }
  }, [program]);

  return (
    <div className="flex h-full flex-col">
      <StepsContainer
        step={currentStep}
        program={program}
        suggestedAmounts={suggestedAmounts}
        livestock={livestock}
      />
    </div>
  );
}

export const PaymentFlow = () => {
  const { t } = useTranslation();
  const { programId } = Route.useParams();
  const { search } = useLocation();
  const { i18n } = useTranslation();
  const { setSelectedProgram } = useFormStore();

  const { data: program } = useQuery(
    programQueryOptions(programId, {
      lang: i18n.language,
      shared: (search?.shared ?? "false").toString(),
    }),
  );

  useEffect(() => {
    if (program) {
      setSelectedProgram(program);
    }
  }, [program, setSelectedProgram]);

  return program ? (
    <>
      <div className="bg-foreground fixed flex w-full flex-row gap-1 px-4 py-2 text-xs text-white md:px-[24px] md:py-4 md:text-sm">
        {t("poweredBy.first_part")}{" "}
        <p className="font-bold text-white"> {t("poweredBy.second_part")}</p>
      </div>

      <div className="fixed top-[32px] flex w-full flex-col overflow-hidden bg-[#CDCFC9] text-white md:hidden">
        <h2 className="block p-4 pb-8 text-lg font-bold md:hidden">
          {program.title}
        </h2>
      </div>
      <PaymentFlowContainer program={program}>
        <div className="bg-background h-screen w-full overflow-y-auto rounded-t-2xl p-4 pb-0 sm:h-full md:flex-1 md:rounded-none lg:p-6 lg:pb-0 lg:pt-6">
          <DonationStepper program={program} />
        </div>
      </PaymentFlowContainer>
    </>
  ) : null;
};
