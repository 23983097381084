export default function PackageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
    >
      <path
        d="M7.85878 16.4092V10.2701L2.48142 7.15569V13.2948L7.85878 16.4092ZM9.65123 16.4092L15.0286 13.2948V7.15569L9.65123 10.2701V16.4092ZM7.85878 18.4705L1.58519 14.8632C1.30139 14.6989 1.08106 14.4823 0.924224 14.2135C0.767385 13.9446 0.688965 13.6459 0.688965 13.3172V6.19224C0.688965 5.86363 0.767385 5.56489 0.924224 5.29602C1.08106 5.02715 1.30139 4.81056 1.58519 4.64625L7.85878 1.03894C8.14258 0.874635 8.44132 0.79248 8.755 0.79248C9.06868 0.79248 9.36742 0.874635 9.65123 1.03894L15.9248 4.64625C16.2086 4.81056 16.4289 5.02715 16.5858 5.29602C16.7426 5.56489 16.821 5.86363 16.821 6.19224V13.3172C16.821 13.6459 16.7426 13.9446 16.5858 14.2135C16.4289 14.4823 16.2086 14.6989 15.9248 14.8632L9.65123 18.4705C9.36742 18.6349 9.06868 18.717 8.755 18.717C8.44132 18.717 8.14258 18.6349 7.85878 18.4705ZM12.3399 6.64036L14.0651 5.65451L8.755 2.58493L7.00736 3.59319L12.3399 6.64036ZM8.755 8.72408L10.5026 7.71583L5.1925 4.64625L3.44486 5.65451L8.755 8.72408Z"
        fill="#FAFAFA"
      />
    </svg>
  );
}
