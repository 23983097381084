import React from "react";
import { useTranslation } from "react-i18next";
import { Header } from "../header";
import { useContactInfo } from "@/api/information";
import { MailIcon } from "@/assets/icons/MailIcon";
import { PhoneIcon } from "@/assets/icons/PhoneIcon";
import ContactCard from "../contactCard";
import { AppShell } from "@/components/Navbar";
import clsx from "clsx";
import { useScrollToShowAppShell } from "../hooks/useScrollToShowAppShell";
import { Helmet } from "react-helmet-async";

export const ContactPage: React.FC = () => {
  const { t } = useTranslation();
  const { data: contact } = useContactInfo();
  const { isScrolled, headerRef } = useScrollToShowAppShell();

  return (
    <>
      <Helmet>
        <title>Contact Us | Circle of Humanity - Get in Touch</title>
        <meta
          name="description"
          content="Need to get in touch with Circle of Humanity? Find all the contact details you need here, including phone and email address. We’re here to answer any questions and listen to your feedback."
        />
        <meta
          name="keywords"
          content="Contact Circle of Humanity, NGO contact, get in touch, help, support, customer service, Egyptian Food Bank"
        />
        <link rel="canonical" href="https://coh.efb.eg/contact" />
        <meta property="og:title" content="Contact Us | Circle of Humanity" />
        <meta
          property="og:description"
          content="Reach out to Circle of Humanity for inquiries, support, or feedback. Find all our contact information on this page and connect with us today."
        />
        <meta property="og:url" content="https://coh.efb.eg/contact" />
      </Helmet>
      <div ref={headerRef}>
        <Header title={t("getInTouchPage.title")} picture="contact.jpg" />
      </div>

      <AppShell
        navbarClass={clsx(
          "fixed top-0 -z-10 w-full bg-[#CDCFC9] opacity-0 transition-opacity duration-300 ease-in-out",
          { "opacity-100 z-10": isScrolled },
        )}
      />

      <div className="mx-4 my-8 flex flex-col justify-center gap-6 md:mx-0 md:my-0 md:mb-16 md:flex-row md:px-6 md:pt-16">
        <ContactCard
          IconComponent={MailIcon}
          headerText={t("information.chat")}
          contactInfo={contact?.email}
          linkType="mailto"
        />

        <ContactCard
          IconComponent={PhoneIcon}
          headerText={t("information.call")}
          contactInfo={contact?.number}
          linkType="tel"
        />
      </div>
    </>
  );
};
