import * as React from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { cn } from "@/lib/utils";
import { Check } from "lucide-react";

const RadioGroup = RadioGroupPrimitive.Root;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
    label?: string;
    error?: boolean;
    color?: string;
    border?: string;
  }
>(({ className, label, error, border, ...props }, ref) => (
  <div className={`${label ? "mb-4" : ""} flex items-center gap-4`}>
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        "cursor-pointer select-none relative p-2 rounded-full h-5 w-5 bg-background focus:outline-none focus:bg-light-hover",
        className,
        border,
        {
          "border-[#F04438]": error,
          "border-2 border-[#C2B4B3]": !props.checked && !border,
          "border-none": props.checked,
        },
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="absolute inset-0 flex h-5 w-5 items-center justify-center rounded-full bg-[#232329]">
        <Check color="#ECE9E3" className="h-3 w-3" strokeWidth={4} />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
    <label
      className={cn("select-none text-sm md:text-base font-[350]", className)}
    >
      {label}
    </label>
  </div>
));
RadioGroupItem.displayName = "RadioGroupItem";

export { RadioGroup, RadioGroupItem };
