export const ProfileIcon = ({
  height,
  width,
}: {
  height: number;
  width: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 34"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Icon (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3.57895C14.5206 3.57895 11.7 6.38306 11.7 9.8421C11.7 13.3011 14.5206 16.1053 18 16.1053C21.4794 16.1053 24.3 13.3011 24.3 9.8421C24.3 6.38306 21.4794 3.57895 18 3.57895ZM8.1 9.8421C8.1 4.40646 12.5324 0 18 0C23.4676 0 27.9 4.40646 27.9 9.8421C27.9 15.2777 23.4676 19.6842 18 19.6842C12.5324 19.6842 8.1 15.2777 8.1 9.8421ZM18 26.8421C12.2558 26.8421 7.00799 29.3276 3.10968 33.4381C2.42772 34.1572 1.28851 34.1905 0.5652 33.5125C-0.158115 32.8346 -0.191637 31.702 0.490327 30.9829C5.00086 26.2269 11.1694 23.2632 18 23.2632C24.8306 23.2632 30.9991 26.2269 35.5097 30.9829C36.1916 31.702 36.1581 32.8346 35.4348 33.5125C34.7115 34.1905 33.5723 34.1572 32.8903 33.4381C28.992 29.3276 23.7442 26.8421 18 26.8421Z"
        fill="#22222A"
      />
    </svg>
  );
};
