import { useFormStore } from "@/contexts/paymentFlowContext";
import CustomInputField from "./Steps/CustomInputFiled";
import { Trans, useTranslation } from "react-i18next";
import config from "@/config/config";
import {
  formatCreditCard,
  cleanCreditCard,
  formatExpiryDate,
  cleanExpiryDate,
} from "@/utils/creditCard";
import { useCallback, useEffect, useState } from "react";
import { useGetBinInfo } from "@/api/credit-card-bin";
import LockIcon from "@/assets/icons/LockIcon";
import { useGetAuthenticatedUser } from "@/api/user";
import useCurrencyStore, { Currencies } from "@/store/currencyStore";
import { CircleAlert } from "lucide-react";

const CreditCardForm = ({ values }: { values: any }) => {
  const [bin, setBin] = useState<string | null>(null);
  const { signatureRef, merchantId, setCardCountry, finalMonth, cardCountry } =
    useFormStore();
  const { currency } = useCurrencyStore();
  const { data: user } = useGetAuthenticatedUser();
  const { i18n } = useTranslation();
  const { data: countryCard } = useGetBinInfo(bin);

  useEffect(() => {
    setCardCountry(countryCard?.country ?? "");
  }, [countryCard]);

  const getBinInfo = useCallback(
    (e: string) => {
      const cleaned = cleanCreditCard(e);
      if (cleaned.length >= 6) {
        setBin(cleaned.substring(0, 6));
      }
      return cleaned;
    },
    [setBin, setCardCountry],
  );
  const { t } = useTranslation();

  return (
    <>
      <input
        type="hidden"
        name="merchant_identifier"
        value={config.merchantIdAPS}
      />
      <input type="hidden" name="card_holder_name" value={values?.fullName} />
      <input type="hidden" name="merchant_reference" value={merchantId} />
      <input
        type="hidden"
        name="access_code"
        value={config.merchantAccessCodeAPS}
      />
      <input type="hidden" name="language" value={i18n.language} />
      <input
        type="hidden"
        name="return_url"
        value={`${config.baseURL}/payment/handler/`}
      />
      <input type="hidden" name="service_command" value="TOKENIZATION" />
      <input
        disabled={!finalMonth || !user}
        type="hidden"
        name="merchant_extra"
        value={user?.id}
      />
      <input ref={signatureRef} type="hidden" name="signature" value="" />
      <CustomInputField
        label={t("donationFlow.cardNum")}
        name="card_number"
        placeholder={t("donationFlow.cardNumberPlaceholder")}
        format={formatCreditCard}
        clean={getBinInfo}
        pattern="\d*"
        isSecure
        disableFlip={true}
      />
      {cardCountry === "EGYPT" && currency !== Currencies.EGP && (
        <div className="my-2 flex items-start gap-5 rounded-lg bg-[#E2DDD7] p-6 shadow-sm">
          <CircleAlert />
          <p className="text-foreground text-sm leading-[150%] md:text-base">
            <Trans
              i18nKey="donationFlow.egyptianDonorsWarning"
              components={{ b: <b /> }}
            />
          </p>
        </div>
      )}
      <div>
        <div className="flex gap-4">
          <div className="w-1/2">
            <CustomInputField
              label={t("donationFlow.expired")}
              name="expiry_date"
              placeholder={t("donationFlow.my")}
              format={formatExpiryDate}
              clean={cleanExpiryDate}
              pattern="\d*"
              isSecure
              disableFlip={true}
            />
          </div>
          <div className="ml-4 w-1/2">
            <CustomInputField
              label="CVV"
              name="card_security_code"
              placeholder={t("donationFlow.entercvv")}
              pattern="\d*"
              isSecure
              disableFlip={true}
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <LockIcon />
          <p className="text-sm">{t("donationFlow.secured")}</p>
          <div className="flex items-center space-x-2">
            <img src={"/icons/visa.svg"} alt="Visa" className="h-12 w-12" />
            <img
              src={"/icons/mastercardIcon.svg"}
              alt="Mastercard"
              className="h-6 w-6"
            />
            <img src={"/icons/miza.png"} alt="JCB" className="h-6 w-6" />
            <img
              src={"/icons/amex.svg"}
              alt="American Express"
              className="h-6 w-6"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditCardForm;
