import { PaymentProcessing } from "@/pages/payment-processing";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const tokenSchema = z.object({
  token_name: z.string().optional(),
});

export const Route = createFileRoute("/payment-processing")({
  component: PaymentProcessing,
  validateSearch: (search) => tokenSchema.parse(search),
});
