import { useChallenge } from "@/api/challenge";
import { AppShell } from "@/components/Navbar";
import { Button } from "@/components/ui/button";
import { Route } from "@/routes/challenge.$challengeId";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import useChallengeFormStore from "@/store/challengeStore";
import SectionNavigator from "../home/sections/tabs";
import { DonorsMessages } from "./DonorsMessages";
import { DetailsComponent } from "./DetailsComponent";
import { UpdatesComponent } from "./UpdatesComponent";
import { HeroSection } from "./components/ChallengeHero";
import PreviewIcon from "@/assets/icons/PreviewIcon";
import { ChallengeType } from "@/types/challenge";

export const shareDataFormatter = (challenge: ChallengeType) => {
  return {
    title: `I'm supporting the ${challenge.name} cause that fights hunger`,
    text: "",
    url: `${window.location.origin}/challenge/${challenge.id}`,
  };
};

export const Challenge: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { challengeId } = Route.useParams();
  const {
    data: Challenge,
    isLoading,
    error,
    refetch,
  } = useChallenge(challengeId, {
    lang: i18n.language,
  });
  const { preview, setPreview, setIsOwner } = useChallengeFormStore();
  const challengeHeaderRef = useRef<HTMLDivElement>(null);

  const sections = [
    { id: "Details", labelKey: t("challenge.details") },
    { id: "Updates", labelKey: t("challenge.updates") },
    { id: "Supporters", labelKey: t("challenge.supporters") },
  ];

  useEffect(() => {
    if (Challenge) {
      if (Challenge?.is_owner) {
        setIsOwner(true);
      }
      setPreview(false);
    }
  }, []);

  useEffect(() => {
    if (preview) {
      refetch();
    }
  }, [preview, refetch]);

  if (!Challenge) {
    return null;
  }

  return (
    <>
      <div className="bg-foreground flex w-full flex-row gap-1 px-4 py-4 text-white md:px-[24px]">
        {t("poweredBy.first_part")}{" "}
        <p className="font-bold text-white"> {t("poweredBy.second_part")}</p>
      </div>

      <AppShell theme="dark" navbarClass="top-14 absolute w-full" />
      {Challenge && (
        <main className="-mb-4 w-full">
          <HeroSection
            challenge={Challenge}
            isLoading={isLoading}
            isError={error}
          />
          <div ref={challengeHeaderRef}>
            <SectionNavigator sections={sections} isChallenge={true}>
              <div className="flex w-full flex-col gap-8 overflow-hidden p-6 md:gap-16">
                <DetailsComponent challenge={Challenge} />
                <UpdatesComponent challenge={Challenge} />
                <DonorsMessages challenge={Challenge} />
              </div>
            </SectionNavigator>
          </div>
          {preview && (
            <div className="bg-foreground fixed bottom-0 z-[60] flex w-full flex-col items-start justify-between gap-4 rounded-t-2xl px-4 py-6 md:flex-row md:items-center md:px-6">
              <div className="flex flex-row items-start gap-6 pl-3 md:items-center md:pl-0">
                <PreviewIcon />
                <h4 className="font-[650] text-white">
                  {t("challenge.previewText")}
                </h4>
              </div>
              <Button
                onClick={() => setPreview(false)}
                className="w-full bg-white px-6 py-2.5 hover:text-white md:w-auto"
              >
                {t("challenge.exitPreview")}
              </Button>
            </div>
          )}
        </main>
      )}
    </>
  );
};
