import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";

type StepCardProps = {
  title: string;
  logo: string;
  step?: string;
};
export default function StepCard({
  title,
  logo,
  step,
}: Readonly<StepCardProps>) {
  return (
    <Card className="flex h-[480px] w-[295px] shrink-0 flex-col gap-2 rounded-2xl border-y-0 border-l-0 border-r border-[#D7D0CB] bg-transparent">
      <CardHeader className="z-[4]">
        {step && <p className="text-sm font-[250]">{step}</p>}
      </CardHeader>
      <CardContent className="relative z-[4] flex h-full flex-col items-start justify-center">
        <h1 className="text-2xl font-bold">{title}</h1>
      </CardContent>
      <CardFooter className="mt-auto flex flex-col !pt-0">
        <div className="flex w-full items-end  gap-2">
          <img src={logo} alt="other challenges empty state icon" />
        </div>
      </CardFooter>
    </Card>
  );
}
