import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import useChallengeFormStore from "@/store/challengeStore";
import useCurrencyStore from "@/store/currencyStore";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { ProfileIcon } from "@/assets/icons/Profile";
import { ChallengeDonationType, ChallengeType } from "@/types/challenge";
import { convertDjangoDateToReadable } from "@/utils/challenge";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";

export const DonorsMessages = ({ challenge }: { challenge: ChallengeType }) => {
  const { preview } = useChallengeFormStore();
  const [sortOption, setSortOption] = useState("");
  const { currency } = useCurrencyStore();
  const { t } = useTranslation();

  const getAmount = (item: ChallengeDonationType) => {
    if (item.hide_amount) {
      return t("challenge.hidden");
    } else if (currency === "USD") {
      return "USD" + item.donation.amount_usd.toFixed(2);
    }
    return "EGP" + item.donation.amount_egp.toFixed(2);
  };

  const sortSupporters = useCallback(
    (a: ChallengeDonationType, b: ChallengeDonationType) => {
      if (sortOption === "amount") {
        return b.donation.amount_usd - a.donation.amount_usd;
      }
      return b.donation.date > a.donation.date ? 1 : -1;
    },
    [sortOption],
  );

  const sortLabel = useMemo(() => {
    if (sortOption === "amount") {
      return t("challenge.amount");
    }
    if (sortOption === "date") {
      return t("challenge.date");
    }
    return t("challenge.sortBy");
  }, [sortOption, t]);

  const sortedDonations = useMemo(() => {
    return challenge.challenge_donations?.slice().sort(sortSupporters) || [];
  }, [challenge.challenge_donations, sortSupporters]);

  const editMode = useMemo(() => {
    if (challenge.is_owner && !preview && challenge.state !== "Closed") {
      return true;
    }
    return false;
  }, [challenge.is_owner, challenge.state, preview]);

  return (
    <section id="Supporters">
      {!challenge.challenge_donations ||
      challenge.challenge_donations?.length === 0 ? (
        <div className="mx-auto mb-8 flex w-full max-w-2xl flex-row justify-between">
          <h1 className="w-full py-2 text-xl font-[650] leading-[120%] sm:text-[32px] md:py-0">
            {t("challenge.noSupporters")}
          </h1>
        </div>
      ) : editMode ? (
        <>
          <div className="mx-auto mb-8 flex w-full max-w-2xl flex-row justify-between">
            <h1 className="w-full py-2 text-xl font-[650] leading-[120%] sm:text-[32px] md:py-0">
              {t("challenge.supporters")}
            </h1>
            <Select onValueChange={(val) => setSortOption(val)}>
              <SelectTrigger className="h-[44px] w-[180px] rounded-lg bg-[#E4E1D8] px-3 capitalize">
                <SelectValue placeholder={t("challenge.sortBy")}>
                  {sortLabel}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="date">{t("challenge.date")}</SelectItem>
                  <SelectItem value="amount">
                    {t("challenge.amount")}
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="scrollbar-hide mx-auto w-full max-w-2xl overflow-y-auto pb-6">
            <div className="flex w-full flex-col gap-4">
              {sortedDonations.map((item) => (
                <Card
                  key={item.id}
                  className={` flex flex-col rounded-lg border-0 border-solid bg-[#E2DDD7] px-6 py-2 `}
                >
                  <CardContent className="relative z-[4]  ">
                    <div className=" flex flex-row items-start gap-8 pt-4 md:pt-6">
                      <div className="flex h-full w-1/6 flex-col items-center justify-between gap-4">
                        <div className="flex h-20 w-20 items-center justify-center rounded-full bg-[#CCC2BF]">
                          <ProfileIcon height={40} width={39} />
                        </div>
                        <p className="font-[650]">{getAmount(item)}</p>
                      </div>
                      <div className="flex w-5/6 flex-col gap-1">
                        <p className="text-lg font-[650] md:text-2xl">
                          {item.hide_id
                            ? t("challenge.anonymous")
                            : item.donation.user_fullname}
                        </p>
                        <h3 className="text-sm font-[350]">
                          {convertDjangoDateToReadable(item.donation.date)}
                        </h3>
                        <p className="mt-1 inline w-full text-sm font-[350] md:text-lg md:font-[350]">
                          "{item.message}"
                        </p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mx-auto mb-8 flex w-full max-w-2xl flex-row justify-between">
            <h1 className="w-full py-2 text-xl font-[650] leading-[120%] sm:text-[32px] md:py-0">
              {t("challenge.beInspired")}
            </h1>
          </div>
          <div className="scrollbar-hide mx-auto w-full max-w-2xl  overflow-y-auto pb-6">
            <div className="flex flex-row  gap-2">
              {challenge.challenge_donations.map((item) => (
                <Card
                  key={item.id}
                  className={` flex w-[249px] flex-col rounded-lg border-0  border-solid bg-[#E2DDD7] md:w-[358px]`}
                >
                  <CardHeader className="z-[4]">
                    <h3 className="text-xs md:text-sm">
                      {t("challenge.supporterMessage")}
                    </h3>
                    <h3 className="text-sm font-[650] md:text-base">
                      {convertDjangoDateToReadable(item.donation.date)}
                    </h3>
                  </CardHeader>
                  <CardContent className="relative z-[4]  w-[249px] md:w-[358px]">
                    <div className="mb-2 md:mb-4">
                      <div className=" flex flex-col items-start gap-2 pb-4 md:pb-6">
                        <div className="flex h-20 w-20 items-center justify-center rounded-full bg-[#CCC2BF]">
                          <ProfileIcon height={40} width={39} />
                        </div>
                      </div>

                      <p className="inline w-[249px] text-sm md:w-[358px] md:text-base">
                        "{item.message}"
                      </p>
                    </div>
                  </CardContent>
                  <CardFooter className="mt-auto flex flex-col">
                    <div className="flex w-full items-end justify-end gap-2">
                      <button
                        className="flex h-12 w-8 items-center justify-center rounded-full"
                        aria-label="Share update"
                      >
                        <img src="/icons/share-icon.svg" alt="Share" />
                      </button>
                    </div>
                  </CardFooter>
                </Card>
              ))}
            </div>
          </div>
        </>
      )}
    </section>
  );
};
