import { LivestockType } from "@/types/programs";
import api from "./api-client";
import { queryOptions } from "@tanstack/react-query";
import { paramsToKey } from "@/utils/helpers";

export const livestockKeys = {
  all: (params?: Record<string, string>) =>
    ["livestock", ...paramsToKey(params)] as const,
  program: (params?: Record<string, string>) =>
    [...livestockKeys.all(), ...paramsToKey(params)] as const,
};

const getLivestock = async (params?: Record<string, string>) => {
  const queryParams = { ...params };
  const res = await api.get("/livestock/", { params: queryParams });
  return res.data.results as LivestockType[];
};

export const livestockQueryOptions = (params?: Record<string, string>) =>
  queryOptions({
    queryKey: livestockKeys.program(params),
    queryFn: () => getLivestock(params),
  });
