import { AppShell } from "@/components/Navbar";
import { useTranslation } from "react-i18next";
import ProgramListing from "./sections/listAll";
import { Helmet } from "react-helmet-async";

export enum ProgramSortOptions {
  RANK = "rank",
  LAUNCHED = "-created_at",
  RAISED = "-raised_percentage",
}

export const ProgramCategories = [
  "",
  "kids",
  "health",
  "orphans",
  "nursery children",
  "single mothers",
  "refugees",
  "disabled individuals",
  "assisted living",
];
export const donations = ["", "zakat", "sadaqah", "jariya", "aqeeqah"];

export const ProgramsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Support Our Causes | Circle of Humanity</title>
        <meta
          name="description"
          content="Explore and support the various causes at Circle of Humanity. Each contribution helps us fight hunger and provide relief in crisis-affected regions. Learn more about our projects and how you can help."
        />
        <meta
          name="keywords"
          content="support causes, humanitarian projects, hunger relief, crisis aid, Egypt NGO, donate"
        />
        <link rel="canonical" href="https://coh.efb.eg/programs" />
        <meta
          property="og:title"
          content="Explore Our Causes | Circle of Humanity"
        />
        <meta
          property="og:description"
          content="Your support is crucial. Explore the humanitarian causes championed by Circle of Humanity to alleviate hunger and crisis impacts. Get involved today."
        />
        <meta property="og:url" content="https://coh.efb.eg/programs" />
        <meta property="og:site_name" content="Circle of Humanity" />
      </Helmet>
      <div className="bg-foreground flex flex-row gap-1 px-4 py-4 text-white md:px-[24px]">
        {t("poweredBy.first_part")}{" "}
        <p className="font-bold text-white"> {t("poweredBy.second_part")}</p>
      </div>
      <AppShell
        theme="white"
        navbarClass="top-14 absolute w-full sticky bg-[#CDCFC9] inset-0"
      />
      <div className="m-4 md:m-6 md:mb-0">
        <h2 className="text-24px md:text-48px font-bold">
          {t("ourPrograms.title")}
        </h2>

        <div className="pt-6">
          <ProgramListing />
        </div>
      </div>
    </>
  );
};
