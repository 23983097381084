export enum SocialType {
  FACEBOOK = "Facebook",
  INSTAGRAM = "Instagram",
  X = "X",
  YOUTUBE = "Youtube",
  LINKEDIN = "Linkedin",
  TIKTOK = "Tiktok",
}

export type SocialMedia = {
  name: SocialType;
  link: string;
};

export interface MediaItem {
  id: string;
  minio_url?: string;
  file_type: string;
}
