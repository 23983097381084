import parse, { Element } from "html-react-parser";
type DescriptionSectionProps = {
  readonly description_title?: string | null;
  readonly description_details?: string | null;
};

export default function DescriptionSection({
  description_title,
  description_details,
}: DescriptionSectionProps) {
  return (
    <section
      className="my-1 flex flex-col gap-8 sm:gap-12 md:my-2 md:w-3/6 md:gap-16"
      id="overview"
    >
      <div className="text-2xl font-bold !leading-[120%] sm:text-4xl">
        <p>{description_details ? description_title : ""}</p>
      </div>
      <div className="flex flex-col gap-3 text-base font-normal !leading-[160%] sm:text-lg">
        {parse(description_details ?? "", {
          replace: (domNode) => {
            if (domNode instanceof Element && domNode.attribs?.style) {
              delete domNode.attribs.style;
              return domNode;
            }
          },
        })}
      </div>
    </section>
  );
}
