import config from "@/config/config";
import useUserStore from "@/store/userStore";
import axios from "axios";

const api = axios.create({
  baseURL: config.baseURL,
  headers: {
    "Content-type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = useUserStore.getState().token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    const clearToken = useUserStore.getState().clearToken;
    if (error.response.status === 401) {
      clearToken();
    }
  },
);

export default api;
