import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";

export const FAQsComponent = ({ data , isProgram}: {data:any, isProgram:boolean}) => {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  return (
    <Accordion type="multiple" className={cn(`w-full space-y-8 md:space-y-16`,  isProgram? `md:w-[65%]`: ``)}>
      {data?.map((faq: any, index: number) => (
        <div key={faq.id}>
          <AccordionItem value={`item-${faq.id}`}>
            <AccordionTrigger
              className={`break-words text-lg leading-[22.86px] md:text-2xl md:leading-[30.48px] ${isRTL ? "text-right" : "text-left"}`}
            >
              <strong className="w-3/4 break-words md:w-3/5">
                {faq.question}
              </strong>
            </AccordionTrigger>
            <AccordionContent
              className={`break-words text-base leading-[22.4px] md:text-lg md:leading-[25.2px] ${isRTL ? "text-right" : "text-left"}`}
            >
              {faq.answer}
            </AccordionContent>
          </AccordionItem>
          {index < data.length - 1 && (
            <div className="border-b border-stone-300"></div>
          )}
        </div>
      ))}
    </Accordion>
  );
};
