import * as React from "react";
import { cn } from "@/lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  clean?: (_: string) => string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, clean, ...props }, ref) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const [hasValue, setHasValue] = React.useState(
      props.value ? props.value.toString().length > 0 : false
    );

    const handleFocus = () => setIsFocused(true);
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      setHasValue(e.target.value.length > 0);
    };

    return (
      <>
        <input
          hidden
          type="text"
          value={
            clean && props.value ? clean(props.value.toString()) : props.value
          }
          name={props.name}
        />
        <input
          id={props.name}
          type={type}
          className={cn(
            "flex w-full rounded-md border bg-background p-2 md:text-base text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 border-[#CCC2BF]",
            "h-[58px]",
            className,
            isFocused
              ? "text-[#232329]"
              : hasValue
              ? "text-[#232329] font-bold" 
              : "placeholder:text-[#4F4754]" 
          )}
          ref={ref}
          {...{ ...props, name: undefined }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          readOnly={props.readOnly}
        />
      </>
    );
  }
);
Input.displayName = "Input";

export { Input };