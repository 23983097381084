import { useFormStore } from "@/contexts/paymentFlowContext";
import { useRef } from "react";
import StepHeader from "./StepHeader";
import CheckoutForm from "./CheckOutForm";
import StepFooter from "./stepFooter";
import { t } from "i18next";

const options = [
  {
    value: "Credit Card",
    label: "Credit Card",
    icon: "/icons/Mastercard.svg",
    formSchema: {
      email: true,
      fullName: true,
      phoneNumber: true,
      creditCardForm: true,
    },
  },
  {
    value: "Pay in person",
    label: "Pay in person",
    icon: "/icons/wallet.svg",
    formSchema: {
      email: true,
      fullName: true,
      phoneNumber: true,
      address: true,
      city: true,
      collection_date: true,
    },
  },
];

const DetailsStep = () => {
  const { selectedOption } = useFormStore();
  const formRef = useRef<any>(null);

  const handleNextStep = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const selectedOptionSchema = options.find(
    (option) => option.value === selectedOption,
  );
  return (
    <>
      <StepHeader />
      <div className="scrollbar-hide bg-background flex h-screen w-full flex-grow flex-col gap-16 overflow-y-auto lg:max-w-[43rem]">
        <div className="flex flex-col">
          <div className="mb-4 flex w-full flex-col items-start justify-start gap-4">
            <h3 className="pt-4 text-xl font-bold leading-[120%] sm:text-[32px] md:pt-16">
              {t("donationFlow.details")}{" "}
            </h3>
          </div>
          <div className="flex w-full flex-col gap-6">
            {selectedOption === "Pay in person" && (
              <span className="text-sm font-normal leading-[150%] md:text-base">
                {t("donationFlow.available")}
              </span>
            )}
            {(selectedOption === "Bank Installment" ||
              selectedOption === "Buy Now, Pay Later") && (
              <div className="h-96 border border-black"></div>
            )}
            {selectedOption && selectedOptionSchema?.formSchema && (
              <CheckoutForm
                ref={formRef}
                selectedOption={selectedOption}
                schema={selectedOptionSchema.formSchema}
              />
            )}
          </div>
        </div>
      </div>
      <StepFooter nextStep={handleNextStep} />
    </>
  );
};

export default DetailsStep;
