import { Progress } from "@/components/ui/progress";
import clsx from "clsx";
import { Currencies } from "@/store/currencyStore";
import { Button } from "@/components/ui/button";
import { useRouter } from "@tanstack/react-router";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { ChallengeType } from "@/types/challenge";
import { calculateDeadline } from "@/utils/challenge";
import PreviewButton from "./PreviewButton";
import useChallengeFormStore from "@/store/challengeStore";
import { useChallengeDonationData } from "@/pages/program/useDonationData";
import { formatNumber } from "@/utils/currency";

interface SmallChallengeDonationBoxProps {
  bgColor: string;
  className?: string;
  challenge: ChallengeType;
}

export const SmallChallengeDonationBox = ({
  bgColor,
  className,
  challenge,
}: SmallChallengeDonationBoxProps) => {
  const { t, currency, raisedAmount, raisedPercentage } =
    useChallengeDonationData(challenge);
  const { reset } = useFormStore();
  const { preview } = useChallengeFormStore();
  const { navigate } = useRouter();

  const handleDonateNow = () => {
    reset();
    if (challenge) {
      navigate({
        to: `/challenge-payment/${challenge.id}`,
        search: { backUrl: location.pathname },
      });
    }
  };

  return (
    <div
      className={clsx(
        "dark fixed bottom-0 z-[6] flex w-full flex-col gap-4 rounded-t-2xl p-7 md:hidden",
        className,
      )}
      style={{ backgroundColor: bgColor }}
    >
      <Progress
        value={raisedPercentage}
        className="h-1 w-full rounded-[2px] bg-[#D6D9DC]"
        aria-label="Donation amount progress bar"
      />
      <div className="flex w-full flex-col space-y-2">
        <div className="flex justify-between">
          <div className="flex flex-col items-start space-y-1">
            <p className="text-light text-sm font-[650]">
              {currency === Currencies.EGP ? "EGP " : "$ "}
              {formatNumber(raisedAmount)} ({raisedPercentage.toFixed(1)}%)
            </p>
            <p className="text-light text-xs font-[350]">
              {t("ProgramCard.raised_label")}
            </p>
          </div>
          <div className="flex flex-col items-start space-y-1">
            <div className="flex items-center space-x-3">
              <p className="text-light text-sm font-[650] capitalize">
                {calculateDeadline(
                  challenge.start_date as string,
                  challenge.end_date as string,
                )}
              </p>
            </div>
            <p className="text-light text-xs font-[350]">Deadline</p>
          </div>
        </div>
      </div>
      {challenge.state !== "Closed" && (
        <div className="flex w-full flex-row items-center space-x-4">
          {challenge.state === "Ongoing" && (
            <Button
              className="h-[54px] w-full px-5 py-4 font-bold md:min-w-[170px]"
              onClick={handleDonateNow}
            >
              {t("ProgramCard.donate_now")}
            </Button>
          )}
          {challenge.is_owner && !preview && <PreviewButton />}
          <button
            className="flex h-14 w-14 flex-grow items-center justify-center rounded-full"
            aria-label="Share program"
          >
            <img src="/icons/share-icon.svg" alt="Share" className="h-5 w-5" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SmallChallengeDonationBox;
