import { ProfileIcon } from "@/assets/icons/Profile";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { ChallengeType, PersonalDonationType } from "@/types/challenge";
import { convertDjangoDateToReadable } from "@/utils/challenge";
import { useRouter } from "@tanstack/react-router";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

type LastPersonalDonationProps = {
  readonly last_personal_donation: PersonalDonationType;
  readonly challenge: ChallengeType;
};
export const LastPersonalDonation = ({
  last_personal_donation,
  challenge,
}: LastPersonalDonationProps) => {
  const { t } = useTranslation();
  const [isMore, setIsMore] = useState(false);
  const { navigate } = useRouter();
  const { reset } = useFormStore();
  const raisedPercentage = useMemo(() => {
    if (last_personal_donation.currency === "EGP") {
      return Math.round(
        (last_personal_donation.amount / challenge.goal_egp) * 100,
      );
    }
    return Math.round(
      (last_personal_donation.amount / challenge.goal_usd) * 100,
    );
  }, [last_personal_donation, challenge]);

  const handleDonateNow = () => {
    reset();
    if (challenge) {
      navigate({
        to: `/challenge-payment/${challenge.id}`,
        search: { backUrl: location.pathname },
      });
    }
  };

  return (
    <Card className="flex h-[550px] w-[310px] shrink-0 flex-col rounded-2xl border-0 bg-[#50614E]">
      <CardHeader className="z-[4] w-full">
        <h3 className="text-sm font-[350] text-white">
          {t("challenge.personalDonation")}
        </h3>
        <h3 className="font-[650] text-white">
          {convertDjangoDateToReadable(last_personal_donation.date as string)}
        </h3>
      </CardHeader>
      <CardContent className="relative z-[4] w-full">
        <div className="mb-2 md:mb-4">
          <div className="flex w-full flex-col items-start gap-4">
            <div className="flex w-full flex-col items-start gap-2">
              <div className=" flex flex-col items-start gap-2">
                <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[#B4B4B4]">
                  <ProfileIcon height={20} width={19} />
                </div>
                <p className="text-white">
                  <Trans
                    i18nKey={"challenge.donatedBy"}
                    values={{ person: t("challenge.me") }}
                  />
                </p>
              </div>
              <Progress
                value={raisedPercentage}
                className="h-[4px] w-full rounded-[2px] rtl:scale-x-[-1] rtl:transform"
                aria-label="Donation amount progress bar"
              />
            </div>
            <p className="text-white">
              {" "}
              {last_personal_donation.currency}{" "}
              {Math.round(last_personal_donation.amount)}
            </p>
            <p className="inline text-xs text-white md:text-base">
              "{last_personal_donation.message}"
            </p>
          </div>
          {last_personal_donation.message.length > 150 && (
            <button
              onClick={() => setIsMore(true)}
              className={clsx(
                { hidden: isMore },
                "text-xs font-bold md:text-base",
              )}
            >
              <span className="font-normal">{"... "}</span>
              {t("update.more")}
            </button>
          )}
        </div>
      </CardContent>
      <CardFooter className="mt-auto flex flex-col">
        <div className="flex w-full items-center justify-start gap-2">
          <Button
            className="h-[54px] px-5 py-4 font-bold md:min-w-[170px]"
            onClick={handleDonateNow}
          >
            {t("challenge.donateAgain")}
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};
