interface StatisticsCardProps {
  iconSrc: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  altText: string;
  statistic: string;
  description: string;
  backgroundColor: string;
  textColor?: string;
}

export default function StatisticsCard({
  iconSrc: Icon,
  altText,
  statistic,
  description,
  backgroundColor,
  textColor = "text-neutral-50",
}: Readonly<StatisticsCardProps>) {
  return (
    <div
      className="flex h-[160px] flex-col items-start justify-between rounded-lg p-6 md:h-[263px]"
      style={{ backgroundColor }}
    >
      <div
        className={`mb-4 flex h-6 w-6 items-center justify-center ${textColor}`}
      >
        <Icon aria-label={altText} className="aspect-[0.88] w-4" />
      </div>
      <div className="flex flex-col items-start justify-end gap-2">
        <div
          className={`${textColor} text-xl font-bold leading-[24px] md:text-5xl md:leading-[57.60px]`}
        >
          {statistic}
        </div>
        <div
          className={`${textColor} text-base font-normal leading-[22.4px] md:text-lg md:leading-[25.2px]`}
        >
          {description}
        </div>
      </div>
    </div>
  );
}
