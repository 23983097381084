import { useChallenges, useSubmitChallengeForm } from "@/api/challenge";
import { useGetAuthenticatedUser } from "@/api/user";
import { Button } from "@/components/ui/button";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { cn } from "@/lib/utils";
import useChallengeFormStore from "@/store/challengeStore";
import useCurrencyStore from "@/store/currencyStore";
import { ChallengeType } from "@/types/challenge";
import { getLoginURL } from "@/utils/auth";
import { Link, useRouter } from "@tanstack/react-router";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const transformFormData = (formData: any) => {
  const {
    challengeName,
    challengeDescription,
    date,
    currentProgram,
    InputAmount,
    coverImage,
    currency,
  } = formData;

  const start = new Date(date.from);
  const startDate = new Date(
    start.getTime() - start.getTimezoneOffset() * 60000,
  )
    .toISOString()
    .split("T")[0];

  const end = new Date(date.to);
  const endDate = new Date(end.getTime() - end.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];

  const challengeData = new FormData();
  challengeData.append("name", challengeName);
  challengeData.append("description", challengeDescription);
  challengeData.append("start_date", startDate);
  challengeData.append("end_date", endDate);
  challengeData.append("program_id", currentProgram);
  if (currency === "EGP") {
    challengeData.append("goal_egp", InputAmount);
    challengeData.append("goal_usd", "0");
  } else {
    challengeData.append("goal_usd", InputAmount);
    challengeData.append("goal_egp", "0");
  }
  if (coverImage?.size) {
    challengeData.append("challenge_picture_file", coverImage);
  }

  return challengeData;
};

interface StepFooterProps {
  readonly nextStep: () => void;
  readonly isDisabled: boolean;
}

export const StepFooter = ({ nextStep, isDisabled }: StepFooterProps) => {
  const { t, i18n } = useTranslation();
  const { currency } = useCurrencyStore();
  const { data: user, isLoading } = useGetAuthenticatedUser();
  const { refetch } = useChallenges({ lang: i18n.language });
  const { reset } = useFormStore();
  const { navigate } = useRouter();
  const [loading, setLoading] = useState(false);

  const {
    currentStep,
    challengeName,
    challengeDescription,
    date,
    programId,
    InputAmount,
    setChallengeId,
    resetForm,
    coverImage,
    challengeId,
  } = useChallengeFormStore();

  const { mutate } = useSubmitChallengeForm({
    onSuccess: (data: ChallengeType) => {
      setLoading(false);
      setChallengeId(data.id);
      nextStep();
      refetch();
    },
    onError: () => {
      setLoading(false);
    },
  });

  const wrapNextStep = () => {
    const formData = transformFormData({
      challengeName,
      challengeDescription,
      date,
      currentProgram: programId,
      InputAmount,
      currency,
      coverImage,
    });
    setLoading(true);
    mutate({ data: formData });
  };

  const handleDonateNow = () => {
    reset();
    navigate({
      to: `/challenge-payment/${challengeId}`,
      search: { backUrl: location.pathname },
    });
  };

  const handleClick = () => {
    if (currentStep === 3 && user && !isLoading) {
      wrapNextStep();
    } else if (currentStep === 4) {
      handleDonateNow();
    } else {
      nextStep();
    }
  };

  return (
    <div
      className={cn(
        "bg-background sticky bottom-0 flex w-full gap-4 border-t border-[#D3D0CA] py-4  sm:flex-row sm:items-start sm:justify-between sm:gap-0 md:-mt-24 md:py-6",
        {
          "flex-col": currentStep !== 4,
          "flex-col-reverse items-center": currentStep === 4,
        },
      )}
    >
      {currentStep !== 4 ? (
        <div className="flex-grow py-2 text-sm font-[650] text-[#AC9CA0] sm:block md:py-0">
          {t("challenge.creation")}
        </div>
      ) : (
        <Link to="/donor-profile" search={{ page: "challenges" }}>
          <Button
            className="px-10 py-4 font-[350] hover:bg-[#22222A] hover:text-white sm:min-w-[180px] sm:max-w-[230px]"
            onClick={resetForm}
            variant="underline"
          >
            {t("challenge.goToProfile")}
          </Button>
        </Link>
      )}
      <Button
        onClick={handleClick}
        disabled={isDisabled || loading}
        className="bg-primary text-foreground hover:bg-foreground hover:text-light h-full w-full px-10 py-4 font-bold sm:min-w-[180px] sm:max-w-[230px]"
      >
        {currentStep === 3 && !user && (
          <Link to={getLoginURL("")}>{t("challenge.createLink")}</Link>
        )}
        {currentStep === 3 && user && !isLoading && t("challenge.createLink")}
        {currentStep === 4 && t("challenge.makeFirstDonation")}
        {(currentStep === 2 || currentStep === 1) && t("challenge.next")}
      </Button>
    </div>
  );
};
