import { ProfileIcon } from "@/assets/icons/Profile";
import { User } from "@/types/auth";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

type SupportersDisplayProps = {
  supporters: User[];
  count: number;
  dark?: boolean;
};

export const SupportersDisplay: React.FC<SupportersDisplayProps> = ({
  count,
  supporters,
  dark,
}) => {
  const { t } = useTranslation();
  const supportersToShow = useMemo(() => {
    if (supporters.length >= 4) {
      return 0;
    }
    return 4 - supporters.length;
  }, [supporters]);

  if (count === 0) {
    return (
      <div
        className={`text-sm ${dark ? "font-[650] text-[#232329]" : "font-[650] text-[#EDEAE4]"}`}
      >
        {t("challenge.noContribution")}
      </div>
    );
  }
  return (
    <div className="flex flex-col items-start gap-2">
      <div className="mr-2 flex -space-x-4">
        {supporters.slice(0, supportersToShow).map((supporter) => (
          <div
            key={supporter.id}
            className="flex h-10 w-10 items-center justify-center overflow-hidden rounded-full"
          >
            {supporter.profile.image && supporter.profile.image?.length > 0 ? (
              <img
                src={supporter.profile.image[0].minio_url}
                alt="Supporter"
                className="h-9 w-9 rounded-full object-cover"
              />
            ) : (
              <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[#B4B4B4] shadow">
                <ProfileIcon height={20} width={19} />
              </div>
            )}
          </div>
        ))}
        {Array.from({ length: count - supporters.length })
          .slice(0, supportersToShow)
          .map((_) => (
            <div
              key={uuidv4()}
              className="flex h-10 w-10 items-center justify-center overflow-hidden rounded-full"
            >
              <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[#B4B4B4] shadow">
                <ProfileIcon height={20} width={19} />
              </div>
            </div>
          ))}
      </div>
      <span
        className={`text-sm ${dark ? "font-[650] text-[#232329]" : "font-[650] text-[#EDEAE4]"}`}
      >
        <Trans
          i18nKey={
            count === 1
              ? "challenge.contributed"
              : "challenge.contributedPlural"
          }
          values={{ number: count }}
        />
      </span>
    </div>
  );
};
