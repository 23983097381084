import { useChallenges } from "@/api/challenge";
import { Button } from "@/components/ui/button";
import { Link } from "@tanstack/react-router";
import { useState } from "react";
import ReactGA from "react-ga4";
import useChallengeFormStore from "@/store/challengeStore";
import { PlusIcon } from "lucide-react";
import { NoneActiveChallengeCard } from "./cards/NonActiveCard";
import { ActiveChallengeCard } from "./cards/ActiveCard";
import StepCard from "./cards/StepCard";
import { ChallengeType } from "@/types/challenge";
import { useTranslation } from "react-i18next";
import { isRTLLanguage } from "@/utils/helpers";
import { t } from "i18next";

const handleSuccess = () => {
  ReactGA.event({
    category: "Social Media Share",
    action: "social_media_share",
    label: "Mobile",
  });
};

const ChallengeCard = ({ challenge }: { challenge: ChallengeType }) => {
  if (!challenge) {
    return null;
  }
  return challenge.state === "Ongoing" ? (
    <ActiveChallengeCard challenge={challenge} handleSuccess={handleSuccess} />
  ) : (
    <NoneActiveChallengeCard
      challenge={challenge}
      handleSuccess={handleSuccess}
    />
  );
};

const MyChallenges = ({ challenges }: { challenges: ChallengeType[] }) => {
  return !challenges?.length ? (
    <div className="flex gap-6 overflow-x-auto overflow-y-hidden">
      <StepCard title={t("challenge.step1")} logo="Number=1.svg" step="1/3" />
      <StepCard title={t("challenge.step2")} logo="Number=2.svg" step="2/3" />
      <StepCard title={t("challenge.step3")} logo="Number=3.svg" step="3/3" />
    </div>
  ) : (
    <div className="scrollbar-hide w-full overflow-x-auto overflow-y-hidden">
      <div className="flex h-full shrink-0 flex-row gap-2">
        {challenges?.map((item) => (
          <ChallengeCard challenge={item} key={item.id} />
        ))}
      </div>
    </div>
  );
};

const OtherChallenges = ({ challenges }: { challenges: ChallengeType[] }) => {
  return !challenges?.length ? (
    <StepCard title={t("challenge.step4")} logo="Number=4.svg" />
  ) : (
    <div className="scrollbar-hide w-full overflow-x-auto">
      <div className="flex h-full flex-row gap-2">
        {challenges?.map((item) => (
          <ChallengeCard challenge={item} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export const ChallengesTab = () => {
  const { t, i18n } = useTranslation();
  const { data: challengesData } = useChallenges({ lang: i18n.language });
  const { resetForm } = useChallengeFormStore();
  const [activeTab, setActiveTab] = useState<string>("My Challenges");

  if (
    !Array.isArray(challengesData?.my_challenges) ||
    !Array.isArray(challengesData?.other_challenges)
  ) {
    return;
  }

  return (
    <div
      dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
      className="flex flex-col gap-12 p-6"
    >
      <h3 className="text-xl font-bold leading-[120%] sm:text-5xl">
        {t("challenge.manageChallenges")}
      </h3>
      <p className="-mt-10 md:-mt-8 md:w-4/6 ">
        {t("challenge.description")}{" "}
        <Link to="/faqs" className="underline">
          {t("challenge.more")}
        </Link>
      </p>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col items-end justify-between gap-8 md:flex-row md:flex-row-reverse md:items-center">
          <Link
            aria-label="Go to create challenge page"
            to="/community-challenge"
            search={{ tab: "" }}
          >
            <Button
              variant="black"
              className="border-foreground flex h-12 gap-2.5 border bg-transparent px-4 py-2 hover:border-2 md:h-14 md:px-6 md:py-2.5"
              onClick={() => resetForm()}
            >
              <p className="font-[650]">{t("challenge.createChallenge")}</p>
              <PlusIcon className="fill-[#232329]" />
            </Button>
          </Link>
          <div className="flex w-full rounded-lg bg-[#E2DDD7] p-1 md:w-[604px]">
            <button
              onClick={() => setActiveTab("My Challenges")}
              className={`h-12 flex-1 rounded-lg pl-4 pr-3 text-sm md:text-base ${
                activeTab === "My Challenges"
                  ? "bg-[#232329] font-[650] text-[#E2DDD7]"
                  : "font-[350] text-[#4F4754]"
              }`}
            >
              {t("challenge.myChallenges")}
            </button>
            <button
              onClick={() => setActiveTab("Other Challenges")}
              className={`h-12 flex-1 rounded-lg pl-4 pr-3 text-sm md:text-base ${
                activeTab === "Other Challenges"
                  ? "bg-[#232329] font-[650] text-[#E2DDD7]"
                  : "font-[350] text-[#4F4754]"
              }`}
            >
              {t("challenge.otherChallenges")}
            </button>
          </div>
        </div>
        {activeTab === "My Challenges" ? (
          <MyChallenges challenges={challengesData?.my_challenges} />
        ) : (
          <OtherChallenges challenges={challengesData?.other_challenges} />
        )}
      </div>
    </div>
  );
};
