import { useChallenges } from "@/api/challenge";
import { FamiliesIcon } from "@/assets/icons/FamiliesIcon";
import { ProfileIcon } from "@/assets/icons/Profile";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogClose } from "@/components/ui/dialog";
import { Progress } from "@/components/ui/progress";
import { ImpactCard } from "@/pages/donor-profile/sections/my-updates";
import { useChallengeDonationData } from "@/pages/program/useDonationData";
import { Currencies } from "@/store/currencyStore";
import { ChallengeType } from "@/types/challenge";
import { isRTLLanguage } from "@/utils/helpers";
import { formatNumber } from "@/utils/currency";
import { useRouter } from "@tanstack/react-router";
import { SoupIcon } from "lucide-react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

type ChallengeClosedModalProps = {
  challenge: ChallengeType;
  open: boolean;
  setOpen: (open: boolean) => void;
};
export default function ChallengeClosedModal({
  challenge,
  open,
  setOpen,
}: Readonly<ChallengeClosedModalProps>) {
  const [message, setMessage] = useState("");
  const { i18n } = useTranslation();
  const { t, currency, raisedAmount, raisedPercentage } =
    useChallengeDonationData(challenge);
  const { navigate } = useRouter();
  const { refetch } = useChallenges({ lang: i18n.language });

  const handleClosed = () => {
    refetch();
    navigate({
      to: "/donor-profile",
      search: { page: "challenges" },
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        dir={isRTLLanguage(i18n.language) ? "rtl" : "ltr"}
        className="mt-12 flex h-screen w-full flex-col items-start justify-between overflow-hidden rounded-t-2xl p-6 pb-16 md:mt-0 md:h-auto md:w-[788px] md:gap-8 md:rounded-2xl md:px-16 md:py-8 rtl:items-end"
      >
        <div className="md:align-center flex h-full w-full flex-1 flex-col gap-4 overflow-y-auto text-start md:gap-8">
          <h3 className="pr-12 text-[20px] font-bold md:text-3xl md:text-[32px]">
            {t("challenge.challengeClosed")}
          </h3>
          <div className="flex flex-col gap-4 md:gap-8">
            <p>{t("challenge.challengeClosedText")}</p>
            <div className="flex flex-row items-center gap-6">
              {challenge.supporters.map((supporter) => (
                <div
                  key={supporter.id}
                  className="flex h-20 w-20 items-center justify-center rounded-full bg-[#B4B4B4]"
                >
                  <ProfileIcon height={40} width={39} />
                </div>
              ))}
            </div>
            <div className="scrollbar-hide flex max-w-full gap-4 overflow-x-auto">
              <ImpactCard
                icon={<FamiliesIcon />}
                title={t("myUpdates.familiesHelped")}
                value={400}
                loading={false}
              />
              <ImpactCard
                icon={<SoupIcon width={24} height={24} fill="#22222A" />}
                title={t("myUpdates.totalMeals")}
                value={parseFloat("20000")}
                loading={false}
              />
            </div>
            <div className="x-full flex flex-col gap-4">
              <Progress
                value={raisedPercentage}
                className="dark h-1 rounded-[2px] rtl:scale-x-[-1] rtl:transform"
                aria-label="Donation amount progress bar"
              />
              <div className="flex items-center space-x-3">
                <p className="text-sm font-[350]">
                  {t("ProgramCard.raised_label")}
                </p>
                <p className="text-center font-[650]">
                  {currency === Currencies.EGP ? "EGP " : "$ "}
                  {formatNumber(raisedAmount)}
                  {"  "}({Math.round((raisedAmount / challenge.goal_usd) * 100)}
                  %)
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label
                htmlFor="challengeDescription"
                className="text-sm font-[650]"
              >
                <Trans
                  i18nKey="challenge.sendCloseMessage"
                  values={{ count: challenge.supporters_count }}
                />
              </label>
              <div className="bg-background flex w-full flex-col gap-4 rounded-lg border border-[#CCC2BF] px-4 py-2 text-base text-[#232329] md:gap-8">
                <textarea
                  id="challengeDescription"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder={t("challenge.senClosePlcaeholder")}
                  className="scrollbar-hide ring-offset-background placeholder:text-muted-foreground flex min-h-[70px] w-full resize-none bg-transparent font-[650] placeholder:font-[350] placeholder:text-[#4F4754] focus:text-[#232329] focus-visible:outline-none"
                  rows={message.length / 60}
                />
                <div className="text-right text-sm font-[350] text-[#AC9CA0]">
                  <Trans
                    i18nKey={"challenge.characters"}
                    values={{ top: message.length, bottom: 600 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col justify-center gap-6 self-center md:flex-row-reverse">
          <Button
            size="lg"
            className="min-w-56 font-[650]"
            type="button"
            onClick={handleClosed}
          >
            {t("challenge.sendMessage")}
          </Button>
          <DialogClose>
            <Button
              size="lg"
              variant="ghost"
              className="min-w-56 font-[650]"
              onClick={handleClosed}
            >
              {t("challenge.backToProfile")}
            </Button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
}
