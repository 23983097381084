import { useState, useEffect } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { bgOverlayStyle } from "@/utils/helpers";
import { useNavigate } from "@tanstack/react-router";
import { DonationBox } from "./DonationBox";
import clsx from "clsx";
import { ProgramType } from "@/types/programs";
import useCurrencyStore from "@/store/currencyStore";

export const HeroSection = ({
  program,
  isLoading,
  isError,
}: {
  program?: ProgramType;
  isLoading: boolean;
  isError: boolean;
}) => {
  const { currency } = useCurrencyStore();
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (program?.main_image?.large_url) {
      const img = new Image();
      img.src = program.main_image.large_url;
      img.onload = () => {
        setFadeOut(true);
        setImageLoaded(true);
      };
    }
  }, [program]);

  if (isLoading) {
    return <Skeleton className="animated-skeleton h-[60vh] pt-36" />;
  }

  if (isError) {
    navigate({ to: "/" });
  }

  return program ? (
    <section
      className="dark relative h-[60vh] !bg-cover pt-36  md:min-h-[550px]"
      style={{
        background: bgOverlayStyle(program.main_image?.large_url),
        backgroundPosition: "center center",
      }}
    >
      {!imageLoaded && (
        <div
          className={`absolute inset-0 !bg-cover blur-md transition-opacity duration-1000 ${
            fadeOut ? "opacity-0" : "opacity-100"
          }`}
          style={{
            background: bgOverlayStyle(program.main_image?.thumbnail_url),
            backgroundPosition: "center center",
          }}
        />
      )}
      <div
        className={clsx("flex h-full px-4 md:px-[24px]", {
          "flex-col justify-end": !program.is_special,
          "justify-between items-end": program.is_special,
        })}
      >
        <div
          className={clsx("w-5/6 lg:w-4/6", {
            "my-12": !program.is_special,
            "my-6": program.is_special,
          })}
        >
          <h1 className="text-foreground  text-5xl font-bold md:text-[4rem] lg:text-8xl 2xl:text-[8rem]">
            {program.title}
          </h1>
        </div>

        <DonationBox
          key={`${program.id}-${currency}`}
          bgColor={program.color_third}
          specialHeader={program.is_special}
          className={clsx("mb-5 rounded-lg", {
            "p-6": !program.is_special,
            "lg:p-6": program.is_special,
          })}
          program={program}
        />
      </div>
    </section>
  ) : (
    <></>
  );
};
