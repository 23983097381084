import { useGetBinInfo } from "@/api/credit-card-bin";
import { useDeleteCreditCard } from "@/api/profile";
import { useGetAuthenticatedUser, userKeys } from "@/api/user";
import { ChipIcon } from "@/assets/icons/ChipIcon";
import { CreditCardBackground } from "@/assets/icons/CreditCardBackground";
import { MastercardIcon } from "@/assets/icons/MastercardIcon";
import { VisaIcon } from "@/assets/icons/VisaIcon";
import CustomInputField from "@/components/PaymentFlow/Steps/CustomInputFiled";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter } from "@/components/ui/dialog";
import config from "@/config/config";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { CreditCardType } from "@/types/auth";
import {
  cleanCreditCard,
  cleanExpiryDate,
  formatCreditCard,
  formatExpiryDate,
  luhnCheck,
} from "@/utils/creditCard";
import { generateSHASignature } from "@/utils/payment";
import { InvalidateQueryFilters, useQueryClient } from "@tanstack/react-query";
import { isFuture, isValid, parse } from "date-fns";
import { Form, Formik } from "formik";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const cardPallette = [
  ["#232328", "#37343F"],
  ["#695B6A", "#AC9CA0"],
  ["#C2B4B3", "#D7D0CB"],
];

const initialValues = {
  card_holder_name: "",
  card_number: "",
  card_security_code: "",
  expiry_date: "",
};

export const CreditCards = () => {
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { merchantId } = useFormStore();
  const [bin, setBin] = useState<string | null>(null);
  const { data: cardCountry } = useGetBinInfo(bin);
  const { data: user } = useGetAuthenticatedUser();
  const { mutate: deleteCreditCard } = useDeleteCreditCard({
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.logged as InvalidateQueryFilters);
      setDialogOpen(false);
      setSelectedCard(null);
    },
  });
  const formRef = useRef<HTMLFormElement | null>(null);
  const signatureRef = useRef<HTMLInputElement | null>(null);
  const [selectedCard, setSelectedCard] = useState<CreditCardType | null>(null);

  const getBinInfo = useCallback(
    (e: string) => {
      const cleaned = cleanCreditCard(e);
      if (cleaned.length >= 6) {
        setBin(cleaned.substring(0, 6));
      }
      return cleaned;
    },
    [setBin],
  );

  const validationSchema = Yup.object().shape({
    card_holder_name: Yup.string()
      .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, t("yup.fullNameAlpha"))
      .min(2, t("yup.fullNameMin"))
      .max(50, t("yup.fullNameMax"))
      .required(t("yup.fullNameRequired")),
    card_number: Yup.string()
      .required(t("yup.cardNumberRequired"))
      .matches(/^(\d{4} ?){4}$/, t("yup.cardNumberInvalid"))
      .test(
        "is-valid-bin",
        t("yup.cardNumberInvalid"),
        (value) => !!cardCountry && luhnCheck(value),
      ),
    expiry_date: Yup.string()
      .transform(function (_, originalValue: string) {
        return originalValue.split("/").reverse().join("");
      })
      .required(t("yup.expiryDateRequired"))
      .test("is-future-date", t("yup.expiryDateInvalid"), (value) => {
        const year = value.substring(0, 2);
        const month = value.substring(2, 4);
        const expiryDate = parse(
          `20${year}-${month}-01`,
          "yyyy-MM-dd",
          new Date(),
        );

        return isValid(expiryDate) && isFuture(expiryDate);
      }),
    card_security_code: Yup.string()
      .matches(/^\d{3,4}$/, t("yup.cardNumberInvalid"))
      .required(t("yup.cvvRequired")),
  });

  const handleSubmit = (_: typeof initialValues) => {
    if (formRef.current && signatureRef.current) {
      const signature = generateSHASignature(new FormData(formRef.current));
      signatureRef.current.value = signature;
      formRef.current.submit();
    }
  };

  const openEditModal = (card: CreditCardType) => {
    setSelectedCard(card);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setSelectedCard(null);
    setDialogOpen(false);
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex justify-between align-baseline">
        <h3 className="self-start text-xl font-bold md:text-3xl rtl:self-end">
          {t("profile.paymentMethods")}
        </h3>
      </div>
      <div className="scrollbar-hide flex max-w-full gap-6 overflow-auto">
        {user?.profile.credit_cards.map((card, i) => (
          <div
            key={card.id}
            className="dark relative flex h-32 w-60 flex-shrink-0 cursor-pointer rounded-xl"
            style={{ backgroundColor: cardPallette[i % 3][0] }}
            onClick={() => openEditModal(card)}
          >
            <div
              className="flex-grow rounded-es-xl rounded-ss-xl"
              style={{ backgroundColor: cardPallette[i % 3][1] }}
            >
              <CreditCardBackground className="absolute" />
              <div className="relative flex h-full flex-col justify-end p-2">
                <h4 className="text-lg">{card.card_number.substring(8)}</h4>
                <p className="text-base">{card.card_holder_name}</p>
              </div>
            </div>
            <div className="h-100 flex flex-col justify-between p-4">
              {card.payment_option === "VISA" && <VisaIcon />}
              {card.payment_option === "MASTERCARD" && <MastercardIcon />}

              <ChipIcon />
            </div>
          </div>
        ))}
      </div>
      <Dialog open={dialogOpen} onOpenChange={closeDialog}>
        <DialogContent className="flex w-screen flex-col gap-8 md:h-5/6 md:w-4/6 md:px-16 md:py-8">
          <h3 className="text-3xl">
            {selectedCard ? "Edit Payment Method" : "Add Payment Method."}
          </h3>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <Form
              ref={formRef}
              className="flex flex-grow flex-col justify-between"
              method="post"
              action={config.paymentGateAPS}
            >
              <div className="flex flex-col gap-4">
                <input
                  type="hidden"
                  name="merchant_identifier"
                  value={config.merchantIdAPS}
                />
                <input
                  type="hidden"
                  name="merchant_reference"
                  value={merchantId}
                />
                <input
                  type="hidden"
                  name="access_code"
                  value={config.merchantAccessCodeAPS}
                />
                <input type="hidden" name="language" value={i18n.language} />
                <input
                  type="hidden"
                  name="return_url"
                  value={`${config.baseURL}/payment/register-card/`}
                />
                <input
                  type="hidden"
                  name="service_command"
                  value="TOKENIZATION"
                />
                <input
                  type="hidden"
                  name="merchant_extra"
                  value={`${user?.id}_${selectedCard?.id ?? ""}`}
                />
                <input
                  ref={signatureRef}
                  type="hidden"
                  name="signature"
                  value=""
                />
                <CustomInputField
                  label={t("donationFlow.name")}
                  name="card_holder_name"
                  placeholder={t("donationFlow.fullname")}
                  pattern="[a-zA-Z\s]*"
                  isSecure
                  disableFlip={true}
                />
                <CustomInputField
                  label={t("donationFlow.cardNum")}
                  name="card_number"
                  placeholder={t("donationFlow.cardNumberPlaceholder")}
                  format={formatCreditCard}
                  clean={getBinInfo}
                  isSecure
                  disableFlip={true}
                />
                <div className="flex gap-4">
                  <div className="w-1/2">
                    <CustomInputField
                      label={t("donationFlow.expired")}
                      name="expiry_date"
                      placeholder={t("donationFlow.my")}
                      format={formatExpiryDate}
                      clean={cleanExpiryDate}
                      isSecure
                      disableFlip={true}
                    />
                  </div>
                  <div className="ml-4 w-1/2">
                    <CustomInputField
                      label="CVV"
                      name="card_security_code"
                      placeholder={t("donationFlow.entercvv")}
                      isSecure
                      disableFlip={true}
                    />
                  </div>
                </div>
              </div>
              <DialogFooter className="flex">
                {selectedCard && (
                  <Button
                    type="button"
                    onClick={() => deleteCreditCard({ id: selectedCard.id })}
                    variant="ghost"
                    className="flex-grow"
                  >
                    Delete Card
                  </Button>
                )}
                <Button type="submit" className="max-w-[50%] flex-grow">
                  Submit
                </Button>
              </DialogFooter>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};
