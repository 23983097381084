import { useTranslation } from "react-i18next";
import useCurrencyStore, { Currencies } from "@/store/currencyStore";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { ProgramType } from "@/types/programs";
import { getProgramGoal, getProgramRaisedAmount } from "@/utils/currency";
import { convertCurrency } from "@/utils/helpers";
import { ChallengeType } from "@/types/challenge";

const useDonationData = (program: ProgramType) => {
  const { t } = useTranslation();
  const { currency } = useCurrencyStore();
  const totalDonations = useFormStore((state) => state.totalDonations);

  const raisedAmount = getProgramRaisedAmount(program) + totalDonations;

  const goalAmount = getProgramGoal(program);

  const raisedPercentage = (raisedAmount / goalAmount) * 100;

  return {
    t,
    currency,
    raisedAmount,
    goalAmount,
    raisedPercentage,
  };
};

export default useDonationData;

export const useChallengeDonationData = (challenge: ChallengeType) => {
  const { t } = useTranslation();
  const { currency } = useCurrencyStore();

  const exchangeRate = challenge.program.currency_exchange_rate;
  const raisedAmount =
    convertCurrency(
      challenge.raised_amount_usd,
      Currencies.USD,
      currency,
      exchangeRate,
    ) +
    convertCurrency(
      challenge.raised_amount_egp,
      Currencies.EGP,
      currency,
      exchangeRate,
    );

  const goalAmount =
    currency === Currencies.USD ? challenge.goal_usd : challenge.goal_egp;

  const raisedPercentage = Math.round((raisedAmount / goalAmount) * 100);

  return {
    t,
    currency,
    raisedAmount,
    goalAmount,
    raisedPercentage,
  };
};
