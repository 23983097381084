export const CreditCardBackground = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="135"
      height="93"
      viewBox="0 0 135 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M87.4924 -2.57742L76.0791 -1.29105L85.1078 78.5786L96.521 77.2923L87.4924 -2.57742Z"
          fill="#695B6A"
        />
        <path
          d="M63.8671 28.0469L6.95763 84.8918L15.0807 93L71.9902 36.155L63.8671 28.0469Z"
          fill="#695B6A"
        />
        <path
          d="M-46.7157 27.8246L-48 39.2214L31.9914 48.2088L33.2757 36.812L-46.7157 27.8246Z"
          fill="#695B6A"
        />
        <path
          d="M-35.4927 -49.6526L-45.2172 -43.5497L-2.38213 24.502L7.34235 18.3991L-35.4927 -49.6526Z"
          fill="#695B6A"
        />
        <path
          d="M21.3417 -93L-5.237 -17.131L5.60456 -13.3442L32.1832 -89.2132L21.3417 -93Z"
          fill="#695B6A"
        />
        <path
          d="M66.8397 -38.8199L60.7295 -29.1088L128.89 13.6509L135 3.93977L66.8397 -38.8199Z"
          fill="#695B6A"
        />
        <path
          d="M87.5034 -2.5754L76.0899 -1.29105L85.1046 78.5802L96.5181 77.2958L87.5034 -2.5754Z"
          fill="#695B6A"
        />
        <path
          d="M63.8561 28.0533L6.93669 84.8883L15.0583 92.9979L71.9778 36.1629L63.8561 28.0533Z"
          fill="#695B6A"
        />
      </g>
    </svg>
  );
};
