import ProgramCard from "@/components/Program/ProgramCard";
import { ProgramType } from "@/types/programs";
import React from "react";

interface ProgramRecommendationProps {
  readonly program: ProgramType;
}

const ProgramRecommendation: React.FC<ProgramRecommendationProps> = ({
  program,
}) => {
  return (
    <section className="flex flex-col gap-8">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        {program.similar_programs?.map((program: ProgramType) => (
          <ProgramCard key={program.id} program={program} />
        ))}
      </div>
    </section>
  );
};

export default ProgramRecommendation;
