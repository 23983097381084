import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen";
import "./i18n";
import "./main.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./api/query-client";
import InstallButton from "./components/PWA/installButton";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConsentButton from "./components/Cookies/consentButton";

const router = createRouter({
  routeTree,
  defaultPreload: "intent",
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const rootElement = document.getElementById("root")!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ConsentButton />
        <InstallButton />
        <ToastContainer />
      </QueryClientProvider>
    </HelmetProvider>,
  );
}
