import api from "./api-client";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { paramsToKey } from "@/utils/helpers";
import { City, Country } from "@/types/geography";

export const cityKeys = {
  all: (params?: Record<string, string>) =>
    ["city", ...paramsToKey(params)] as const,
  country: (countryId: string, params?: Record<string, string>) =>
    [...cityKeys.all(), countryId, ...paramsToKey(params)] as const,
  countryName: (countryName: string, params?: Record<string, string>) =>
    [...cityKeys.all(), "byName", countryName, ...paramsToKey(params)] as const,
};

export const countryKeys = {
  all: (params?: Record<string, string>) =>
    ["country", ...paramsToKey(params)] as const,
};

const getCities = async (
  countryId: string,
  params?: Record<string, string>,
) => {
  if (countryId === "") {
    return [];
  }
  const queryParams = { ...params };
  const res = await api.get<City[]>(
    `/geography/countries/${countryId}/cities`,
    {
      params: queryParams,
    },
  );
  return res.data;
};

const getCitiesByCountryName = async (
  countryName: string,
  params?: Record<string, string>,
) => {
  if (countryName === "") {
    return [];
  }
  const queryParams = { name: countryName, ...params };
  const res = await api.get<City[]>(`/geography/countries/cities_by_name/`, {
    params: queryParams,
  });
  return res.data;
};

const getCountries = async (params?: Record<string, string>) => {
  const queryParams = { ...params };
  const res = await api.get("/geography/countries", {
    params: queryParams,
  });
  return res.data.results as Country[];
};

export const cityQueryOptions = (
  countryId: string,
  params?: Record<string, string>,
) =>
  queryOptions({
    queryKey: cityKeys.country(countryId, params),
    queryFn: () => getCities(countryId, params),
  });

export const cityByCountryNameQueryOptions = (
  countryName: string,
  params?: Record<string, string>,
) =>
  queryOptions({
    queryKey: cityKeys.countryName(countryName, params),
    queryFn: () => getCitiesByCountryName(countryName, params),
  });

export const useGetCountries = (params?: Record<string, string>) => {
  return useQuery({
    queryKey: countryKeys.all(params),
    queryFn: () => getCountries(params),
  });
};

export const useGetCitiesByCountryName = (
  countryName: string,
  params?: Record<string, string>,
) => {
  return useQuery({
    ...cityByCountryNameQueryOptions(countryName, params),
    enabled: !!countryName,
  });
};
