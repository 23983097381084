import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "@tanstack/react-router";

interface CustomLinkProps {
  to: string;
  mobileText: string;
  desktopText: string;
}

const CustomLink: React.FC<CustomLinkProps> = ({
  to,
  mobileText,
  desktopText,
}) => {
  const { i18n } = useTranslation();

  return (
    <Link
      to={to}
      className="flex flex-row items-center gap-2 text-xl !leading-[120%] md:text-2xl"
    >
      <span className="block sm:hidden">{mobileText}</span>
      <span className="hidden sm:block">{desktopText}</span>
      {i18n.language === "en" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.29289 0.959397C7.68342 0.568873 8.31658 0.568873 8.70711 0.959397L15.7071 7.9594C16.0976 8.34992 16.0976 8.98309 15.7071 9.37361L8.70711 16.3736C8.31658 16.7641 7.68342 16.7641 7.29289 16.3736C6.90237 15.9831 6.90237 15.3499 7.29289 14.9594L12.5858 9.6665H1C0.447715 9.6665 0 9.21879 0 8.6665C0 8.11422 0.447715 7.6665 1 7.6665H12.5858L7.29289 2.37361C6.90237 1.98309 6.90237 1.34992 7.29289 0.959397Z"
            fill="#22222A"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.70711 16.3751C8.31658 16.7656 7.68342 16.7656 7.29289 16.3751L0.292893 9.37508C-0.0976315 8.98455 -0.0976315 8.35139 0.292893 7.96086L7.29289 0.960862C7.68342 0.570337 8.31658 0.570337 8.70711 0.960862C9.09763 1.35139 9.09763 1.98455 8.70711 2.37508L3.41421 7.66797L15 7.66797C15.5523 7.66797 16 8.11568 16 8.66797C16 9.22025 15.5523 9.66797 15 9.66797L3.41421 9.66797L8.70711 14.9609C9.09763 15.3514 9.09763 15.9846 8.70711 16.3751Z"
            fill="#22222A"
          />
        </svg>
      )}
    </Link>
  );
};

export default CustomLink;
