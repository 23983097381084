import { useFormStore } from "@/contexts/paymentFlowContext";
import { Route } from "@/routes/challenge-payment.$challengeId";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import ChallengePaymentContainer from "./ChallengePaymentContainer";
import { useChallenge } from "@/api/challenge";
import { DonationStepper } from "../paymentFlow";

export const ChallengePaymentFlow = () => {
  const { t, i18n } = useTranslation();
  const { challengeId } = Route.useParams();
  const { setSelectedProgram, setSelectedChallenge, setIsChallenge } =
    useFormStore();

  const { data: challenge } = useChallenge(challengeId, {
    lang: i18n.language,
  });

  useEffect(() => {
    if (challenge) {
      setSelectedChallenge(challenge);
      setIsChallenge(true);
      setSelectedProgram(challenge.program);
    }
  }, [challenge]);

  return challenge ? (
    <>
      <div className="bg-foreground fixed left-0 right-0 top-0 flex w-full flex-row gap-1 px-4 py-2 text-xs text-white md:px-[24px] md:py-4 md:text-sm">
        {t("poweredBy.first_part")}{" "}
        <p className="font-bold text-white"> {t("poweredBy.second_part")}</p>
      </div>
      <div className="fixed top-[32px] flex w-full flex-col overflow-hidden bg-[#CDCFC9] text-white md:hidden">
        <h2 className="block p-4 pb-8 text-lg font-bold md:hidden">
          {challenge.name}
        </h2>
      </div>
      <ChallengePaymentContainer challenge={challenge}>
        <div className="bg-background h-screen w-full overflow-y-auto rounded-2xl p-4 pb-0 md:h-full md:flex-1 md:rounded-none lg:p-6 lg:pb-0 lg:pt-6">
          <DonationStepper program={challenge.program} />
        </div>
      </ChallengePaymentContainer>
    </>
  ) : null;
};
