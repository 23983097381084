// src/stores/themeStore.ts
import { create } from "zustand";
import Cookies from "js-cookie";
import { InvalidateQueryFilters } from "@tanstack/react-query";
import { userKeys } from "@/api/user";
import { queryClient } from "@/api/query-client";
import { getCookieDomain } from "@/utils/helpers";
import config from "@/config/config";

interface UserStore {
  token?: string;
}
interface UserStoreActions {
  clearToken: () => void;
}

const useUserStore = create<UserStore & UserStoreActions>((set) => {
  const token = Cookies.get("token");
  return {
    token: token,
    clearToken: () => {
      Cookies.remove("token", {
        domain: getCookieDomain(),
        path: "/",
        secure: config.prod,
      });
      set({ token: undefined });
      if (
        window.location.pathname !== "/" &&
        !window.location.pathname.startsWith("/gratitude")
      ) {
        window.location.href = "/";
      }
      queryClient.invalidateQueries(userKeys.logged as InvalidateQueryFilters);
    },
  };
});

export default useUserStore;
