import { DialogClose } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import ProfileModal from "@/components/Profile/Modal";
import { useTranslation } from "react-i18next";
import useUserStore from "@/store/userStore";
import { useLogoutUser } from "@/api/user";

export default function LogoutModal() {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const { mutate: logoutUser } = useLogoutUser({
    onSuccess: () => {
      window.localStorage.clear();
      useUserStore.getState().clearToken();
      window.location.href = "/";
    },
  });
  return (
    <ProfileModal
      open={openDeleteModal}
      isTrigger={true}
      buttonClass="min-w-56"
      setOpen={setOpenDeleteModal}
      trigger="logout"
      buttonVariant="black"
      title="areYouSureLogout"
      button={
        <>
          <Button
            size="lg"
            className="min-w-56"
            type="button"
            onClick={() => logoutUser()}
          >
            {t("profile.confirmLogout")}
          </Button>
          <DialogClose>
            <Button size="lg" variant="underline" className="min-w-56">
              {t("profile.dismiss")}
            </Button>
          </DialogClose>
        </>
      }
    />
  );
}
